import React from 'react'
import { BrowserRouter, Routes ,Route } from 'react-router-dom'

import Main from '../_layout/Main'
import MainAdmin from '../_layout/MainAdmin'
import LayoutLila from '../_layout/LayoutLila'
import PreClinic from '../_layout/PreClinic'
import PreClinic2 from '../_layout/PreClinic2'

// views
import About from '../views/About'

import Login from '../views/login/Login'
import Signup from '../views/login/Signup'

// Main Admin Views

import Admin from '../views/Admin'
import Dashboard from '../views/Dashboard'
import Panel from '../views/Panel'
import Ticket from '../views/Ticket'

import Certificado1 from '../views/Certificado1'
import CertificadoList from '../views/CertificadoList'

import Globalx from '../views/globalx/'
import CambiarClave from '../views/CambiarClave'
import CambiarClaveA from '../views/CambiarClaveA'
import ProductoList from '../views/producto_crud/ProductoList'
import Producto from '../views/producto_crud/Producto'

import Settings from '../views/Settings'


// admin Views
import Home from '../views/Home'

import List_crud from '../views/List_crud'
import CrudModal from '../views/CrudModal'
import Crud_ok from '../views/Crud_ok'

import Citas from '../views/cita_all/Citas'
import Agenda from '../views/cita_all/Agenda'

//-----------------CLINICA-------------
import CitasTabla from '../views/cita_all/CitasTabla'
import CitasHoy from 'views/cita_all/CitasHoy'

//------------------ PACIENTE_ALL --------------------
import Pacientes from '../views/paciente_crud/Pacientes'
import Paciente_show from '../views/paciente_crud/Paciente_show'

import HistoriaList from '../views/paciente_all/HistoriaList'
import Historia from '../views/paciente_all/Historia'

import Imagenologia from '../views/paciente_all/Imagenologia'
import ImagenZoom from '../views/paciente_all/ImagenZoom'

import CitasMi from '../views/paciente_all/CitasMi'

import TratamientoList from '../views/paciente_all/TratamientoList'
import Tratamiento from '../views/paciente_all/Tratamiento'

import RecaudacionList from '../views/paciente_all/RecaudacionList'

import RecetaList from '../views/paciente_all/RecetaList'
import Receta from '../views/paciente_all/Receta'

import PagoList from '../views/paciente_all/PagoList'

import MedicosCrud from '../views/medicos_crud/Index'
import Cita_horaList from '../views/cita_hora_crud/Cita_horaList'


import UsuariosCrud from 'views/usuarios_crud'

import Consultas from 'views/consultas/Consultas'

import Menu_rep from 'views/reportes_all/Menu_rep'  

//--pacientes



// eslint-disable-next-line import/no-anonymous-default-export
export default () => {

  /*<Route path="/items/:itemId" element={<ItemsDetail />} />
  <Route path='/certificado1/:id/:ope' exact element={ <Certificado1 />} /> 
  <Route path='/certificado1/:id/:ope' exact element={ <Certificado1 {...props} />} /> */
  return (
    <>
      <Routes>
        <Route path='/login' element={ <Login />} />
        <Route path='/signup' element={ <Signup />} />
    
        <Route element={<MainAdmin />}>
          <Route path='/admin'  element={<Admin />} />    
          <Route path='/admin/settings' element={ <Settings />} />
          
        </Route>

        <Route element={<PreClinic />}>
          <Route path='/' exact element={ <Dashboard/>} />
          <Route path='/dashboard' exact element={ <Dashboard />}  />  
          <Route path='/panel' exact element={ <Panel />}  />
          <Route path='/ticket' exact element={ <Ticket />} />  
          
          <Route path='/certificado1/:id/:ope' exact element={ <Certificado1 />} /> 
          <Route path='/certificado_list' exact element={ <CertificadoList />} /> 

          <Route path='/globalx' exact element={ <Globalx />} /> 
          <Route path='/cambiarclave' exact element={ <CambiarClave />} /> 
          <Route path='/cambiarclaveA' exact element={ <CambiarClaveA />} /> 
          <Route path='/productolist' exact element={ <ProductoList />} /> 
          <Route path='/producto/:ope/:idd' exact element={ <Producto />} /> 


          <Route path='/settings' exact element={ <Settings />} /> 
          

          <Route path='/citas' exact element={ <Citas />} />         
          <Route path='/agenda' exact element={ <Agenda />} /> 

          <Route path='/citas_tabla' exact element={ <CitasTabla />} /> 
          <Route path='/citas_hoy' exact element={ <CitasHoy />} /> 

          <Route path='/medicos' exact element={ <MedicosCrud />} /> 
          <Route path='/cita_horalist' exact element={ <Cita_horaList />} /> 

          <Route path='/usuarios' exact element={ <UsuariosCrud />} /> 
          
          <Route path='/pacientes' exact element={ <Pacientes />} /> 
          <Route path='/paciente_new/:ope/:idd' exact element={ <Paciente_show />} /> 

          <Route path='/menu_rep' exact element={ <Menu_rep />} /> 

        </Route>
    
        <Route element={<PreClinic2 />}>

          
          <Route path='/paciente_show/:ope/:idd' exact element={ <Paciente_show />} /> 

          <Route path='/historialist/:id' exact element={ <HistoriaList />} /> 
          <Route path='/historia/:ope/:idd' exact element={ <Historia />} /> 
          
          <Route path='/imagenologia/:id' exact element={ <Imagenologia />} /> 
          <Route path='/imagenzoom/:filename' exact element={ <ImagenZoom />} /> 

          <Route path='/citasmi' exact element={ <CitasMi />} /> 
          
          <Route path='/tratamientolist/:id' exact element={ <TratamientoList />} /> 
          <Route path='/tratamiento/:ope/:idd' exact element={ <Tratamiento />} /> 

          <Route path='/recaudacionlist' exact element={ <RecaudacionList />} /> 

          <Route path='/recetalist' exact element={ <RecetaList />} /> 
          <Route path='/receta/:ope/:idd' exact element={ <Receta />} /> 

          <Route path='/pagolist' exact element={ <PagoList />} /> 

          <Route path='/consultas' exact element={ <Consultas/>} />
          


          
          <Route path='/home' exact element={ <Home/>} />  
          <Route path='/about' element={ <About />} />
          <Route path='/list_crud' element={ <List_crud />} />
          <Route path='/crud_modal' element={ <CrudModal />} />
          <Route path='/crud_ok' element={ <Crud_ok />} />
        </Route>

        <Route path="*" element={<NoMatch />} /> 
      </Routes>

      

    </>
  )

};
const NoMatch = () => {
  return (<p>There's nothing here: 404!</p>);
};
