import React from 'react'
const miselect={
    /*fontSize: "14pt",*/
    /*lineHeight: "1.5",*/
    border: "1px solid #ced4da",
    borderRadius: ".25rem",
    display: "block",
    width: "100%",
    padding: ".275rem .75rem",
}

const NuDropdown = (props) => {

  //-------------agregamos el item con su informacion para propositos futuros
  const onChangeOption = (e) => {
    // 👇️ filter with 1 condition
    //console.log(e.target.value);
    //---buscamos en el array, para que lo retornemos el row
    const filtered = props.options.filter(item => {
      return item.value == e.target.value;
    });
    //console.log(filtered[0]); //sino hay retorna undefined
    //---lo anexamos para ser retornado
    e.target.item = filtered[0];
    props.onChange(e);
  }
  //----------------------------------------------------------------
  return (
    <div className="">
      {/*<label>{props.labelName} :</label>*/}
      <select className={props.className} style={miselect} name={props.name} value={props.value} onChange={(e)=> onChangeOption(e) }  >
        <option key={0} value={"0"} defaultValue  >-- Elija una opción --</option>
        {
          props.options.map(item => (
            <option key={item.value} value={item.value} >{item.label}</option>
          ))
        }
      </select>
    </div>
  )
}
export default NuDropdown;
