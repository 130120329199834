
import React, { useState, useEffect } from 'react';

import axios2 from '../services/axios2';
import { ToastContainer, toast } from 'react-toastify';
import Chart from "react-apexcharts";

const initialFormState= {
  cn_atenciones: 0,
  cn_doc: 0,
  cn_paci: 0,
  cn_pendientes: 0,
  //----chart
  
}

// eslint-disable-next-line import/no-anonymous-default-export
const Dashboard =()=>{
 
  //--state
  const [form00, setForm00] = useState(initialFormState);
  const [options, setOptions]  = useState({ 
        labels: ["No confirmado", "Confirmado", "Hora cancelada", "Atendido"],
        colors: [ // this array contains different color code for each data
        "#008ffb",
        "#feb019",
        "#ff4560",
        "#00e396",
        "#A5978B",
        "#2b908f",
        "#f9a3a4",
        "#90ee7e",
        "#f48024",
        "#69d2e7"
      ],
      });
  const [series, setSeries] = useState( [4, 5, 6, 1] ); //our data
  //--------cargamos los valores desde el server -----------
  //---equivalente a component did mount
  useEffect(() => {
    console.log("I have been mounted")
    cargarDatos();
  }, []);
  //======================================================
  const cargarDatos = () => {
    
    const param = {
    };
    //console.log(config.API_URL + "register");
    axios2.post( process.env.REACT_APP_API_URL  + "dashboard_get_tot",param, {
              headers: {
                "Content-Type": "application/json",
              }
          })
          .then(res => {
            let response = res.data;
            //console.log(response)
            
            setForm00(response);

            toast.info("Cargado con éxito");

            /*toast.error("Error Notification !", {
              position: toast.POSITION.TOP_LEFT
            });*/
            
          })
          .catch(err => {
            console.log("err->");
            console.log(err)
          });  
      
  };
  //----------------------------------------------------
  //--------------------------------------------------------
  return(
    <div className="content">
      <ToastContainer />
      <div className="row">
        <div style={{ textAlign: "center"}}>
          <h2>Resumen</h2>
          Desde 1-6-2022 hasta 22-6-2022
        </div>

        <div className="col-md-3 col-sm-3 col-lg-3 col-xl-3">
          <div className="dash-widget">
            <span className="dash-widget-bg5"><i className="fa fa-stethoscope" aria-hidden="true"></i></span>
            <div className="dash-widget-info text-right">
              <h3>{form00.cn_doc} </h3>
              <span className="widget-title5">Citas anuladas </span>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-3 col-lg-3 col-xl-3">
          <div className="dash-widget">
            <span className="dash-widget-bg2"><i className="fa fa-user-o"></i></span>
            <div className="dash-widget-info text-right">
              <h3>{form00.cn_paci}</h3>
              <span className="widget-title2">Pacientes nuevos</span>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-3 col-lg-3 col-xl-3">
          <div className="dash-widget">
            <span className="dash-widget-bg3"><i className="fa fa-user-md" aria-hidden="true"></i></span>
            <div className="dash-widget-info text-right">
              <h3>{form00.cn_atenciones}</h3>
              <span className="widget-title3">Attenciones realizadas </span>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-3 col-lg-3 col-xl-3">
          <div className="dash-widget">
            <span className="dash-widget-bg4"><i className="fa fa-heartbeat" aria-hidden="true"></i></span>
            <div className="dash-widget-info text-right">
              <h3>{form00.cn_pendientes}</h3>
              <span className="widget-title4">Presupuestos<i className="fa fa-check" aria-hidden="true"></i></span>
            </div>
          </div>
        </div>
      </div>

      <div className='col-6'>
      <div className="card" style={{  }}>
  
        <div className="card-body">
          <h5 className="card-title">Resumen de atenciones</h5>
          
          
          <div className="donut">
            <Chart options={ options} series={ series} type="pie" width="380" />
          </div>

        </div>
      </div>
      </div>

      {/* /.col */}
    </div>
  );

}

export default Dashboard;