import React from "react";
import {Link,hashHistory} from 'react-router-dom';
import axios2 from 'services/axios2';
import { withRouterJim } from "router/withRouterJim";
import AuthService from "services/auth.service";
import confirmService from 'components/confirmService'; 

import { NotificationContainer, NotificationManager } from 'react-notifications';
import {
  Table,
  Button,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  ModalFooter,
} from "reactstrap";

//type:'numeric'
const columnas=[
    {  field:'id', title: 'Cod. Cert.', type:'numeric'},
    {  field:'cliente', title: 'Cliente'},
    {  field:'nombre', title: 'Nombre'},
    {  field:'lote', title: 'Lote'},
    {  field:'elem', title: 'Elementos'},
]

const data = [
  { id_certi: 1, id_cliente:0, nombre_cli: "Naruto", nombre: "AMSG", fecha_cert:'V-415'},
  { id_certi: 2, id_cliente:0, nombre_cli: "Goku", nombre: "EMIa", fecha_cert:'V-415'},
 
];



class TratamientoList extends React.Component {
  state = {
    data: data,
    data2: [],
    id_paciente:'', /*id_paciente */
   
    form: {
      idd: "",
      ope: "",
      
    },
  };
  
  componentDidMount() {
    //--limpiamos el array de prueba
    this.setState({data:[]});

    let id = this.props.params.id;
    console.log("id_paciente:" + id);
    this.setState({id_paciente: id});

    this.fetchData(id);
  }

  
  //----cargamos los datos desde la base de datos
  fetchData = ( id ) => {
    let param = { 
      id_paciente: id,
    }
    
    axios2.post("tratamiento_get_list", param).then(res => {
        if(res.data.activos.length>0 || res.data.finalizados.length>0){ 
            //NotificationManager.success("Correcto");
            
            this.setState({
              data: res.data.activos,
              data2: res.data.finalizados,
            });
        }else{
            NotificationManager.error("No existen registros");
        }

    }).catch(error => {
        NotificationManager.error("Bad Request");
        //this.setState({ errors: error })
        console.log(error);
    });
  };
 
  //------------------------------------------------------------------
  handleChange = (e) => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      },
    });
  };

  nuevo = () => {
    const user = AuthService.getCurrentUser();
    let id_u = user.id_u;

    let param = {
      id_paciente: this.props.store.paciente.id_paciente,
      id_medico: id_u,
    }
    //---creamos el registro, luego lo redireccionamos
    axios2.post("tratamiento_store_new", param).then(res => {
      const response = res.data;
      if (response.exito == 1) {
        //tenemos que redireccionar
        this.props.navigate('/tratamiento/e/' + response.id_tra);
      } else {
        NotificationManager.error(response.mensaje);
      }

    }).catch(error => {
      alert("error axios");
      console.log(error);
    });
  }
  //---------------------------------------
  anular = (item) =>{
    console.log("anular");
    //--------------------------------
    confirmService.show({
      message: 'Esta seguro de anular?',
      title: 'Confirmar',
      icon: 'fa fa-question-circle',
      classHeader: 'bg-default1 ',
      classButton: 'btn btn-danger',
      accept: () => {
        this.update_estado(item.id_tra,3);  //3 anulado
      },
      reject: () => {
          console.log('cancel');
      },
      acceptLabel: 'Aceptar',
      rejectLabel: 'Cancelar'
    })

  }
  //----------------------------------------
  update_estado = (id_tra, estado) => {

    let param = {
      id_tra: id_tra,
      estado: estado,
    }
    //---creamos el registro, luego lo redireccionamos
    axios2.post("tratamiento_update_estado", param).then(res => {
      const response = res.data;
      if (response.exito == 1) {
        //tenemos que actualizar
        this.fetchData(this.state.id_paciente);

      } else {
        NotificationManager.error(response.mensaje);
      }

    }).catch(error => {
      alert("error axios");
      console.log(error);
    });
  }
  //--------------------------------------------------------------------
  render() {
    
    return (
      <>
        <Container style={{ marginTop: "50px", minHeight: "700px" }}>

          <NotificationContainer />
          <br />



          <div className="row">

            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">


              {/*  Authentication card end ---------------------------------------------  */}
              <div className="card">
                <div className="card-header">
                  <h3>Tratamientos</h3>
                  <div className="card-header-right">
                    <Button className="btn btn-success" onClick={ this.nuevo }> Nuevo </Button>
                  </div>
                </div>

                <div className="card-body">

                  <Table>
                    <thead>
                      <tr>
                        <td colSpan={5} style={{fontSize: "12pt",color: "#01987a" }}> Tratamientos activos </td>
                      </tr>
                      <tr style={{backgroundColor: "#d6e3e0"}}>
                        <th>Nro.Tra</th>
                        <th>Nombre</th>
                        <th>Estado</th>
                        <th>Ultima cita</th>
                        <th>Saldo</th>
                        <th>Accion</th>
                      </tr>
                    </thead>

                    <tbody>
                      {this.state.data.map((item) => (
                        <tr key={item.id_tra }>
                          <td>{ item.id_tra }</td>
                          <td>{ item.nombre }</td>
                          <td>{ item.estado==1? 'Activo':'Finalizado'}</td>
                          <td></td>
                          <td>{item.saldo}</td>
                          <td>
                            <Link className="btn btn-warning" to={`/tratamiento/e/${item.id_tra}`}> <i className="fa fa-edit"></i></Link>

                            <Button color="danger" onClick={ () => this.anular(item) }><i className="fa fa-trash"></i></Button>
                            
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <br />

                  <Table>
                    <thead>
                      <tr>
                        <td colSpan={5} style={{fontSize: "12pt",color: "#b37f6e" }}> Tratamientos Terminados </td>
                      </tr>
                      <tr style={{backgroundColor: "#f3dacb"}}>
                        <th>Nro.Tra</th>
                        <th>Detalle</th>
                        <th>Estado</th>
                        <th>Ultima cita</th>
                        <th>Saldo</th>
                        <th>Accion</th>
                      </tr>
                    </thead>

                    <tbody>
                      {this.state.data2.map((item) => (
                        <tr key={item.id_tra}>
                          <td>{item.id_tra}</td>
                          <td>{item.detalle}</td>
                          <td>{item.estado==1? 'Activo':'Finalizado'}</td>
                          <td></td>
                          <td>{item.saldo}</td>
                          <td>
                            <Link className="btn btn-warning" to={`/tratamiento/e/${item.id_tra}`}> <i className="fa fa-edit"></i></Link>

                            <Button color="info" onClick={ () => this.update_estado(item.id_tra, 1) }>Activar</Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>

                <div className="card-footer">
                  <div className="text-center">

                    

                  </div>
                </div>
              </div>

           
            </div>{/*  end    */}

          </div>

        </Container>

      </>
    );
  }
}

export default withRouterJim(TratamientoList);