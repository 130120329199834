import React from "react";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { withRouterJim } from "router/withRouterJim";

import axios2 from 'services/axios2';
import ImageUploadSingle from "components/nubox/ImageUploadSingle";
import Gallery from "components/Gallery";


import {
  TabContent, TabPane, Nav,
  NavItem, NavLink, Row, Col
} from 'reactstrap';
import classnames from 'classnames'; /*para los tab */


const data = [
  { id_image: 1,  filename: "file001",  detalle: "00000023"},
];

const form_reset={
  idd: 0,
  ope: 'n',
  id_image:0,
  id_paciente:0,
  fecha_reg:'',
  filename:'',
  detalle:'',
};

class Imagenologia extends React.Component {
  state = {
    id_param:0, /*id que viene del parametro */
    data: data,/* lista de imagenes */
    id_store:0,
    /*modalInsertar: false,
    modalEliminar: false,*/
    form: form_reset,
   
  }
  //---------------
  componentDidMount() {
    
    //const [ store, dispatch ] = this.context;
    //const {user, paciente} = store;
    //let id = this.props.store.paciente.id_paciente;
    let id = this.props.params.id;
    console.log("id_paciente:" + id);
    this.setState({id_store: id});

    this.cargar_imagenes(id);
  }
  //--------------- handle all input [target:name]------------------------------
  handleChangeForm = (e) => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  //-------------------
  /************************************************ */
  okImage = (filename_) =>{    
    this.setState({ form:{...this.state.form, filename: filename_}}); 
  }
  cancelImage = () =>{
    //---ocultamos la ventana
    this.setState({ form:{...this.state.form, filename: '' }}); 
  }
  //-----------------------------------------
  cargar_imagenes = (idd) => {
    //--------------- context api
    //let id = this.props.store.paciente.id_paciente;

    let param = {
      id_paciente: idd,
    };
    console.log(param);

    axios2.post("imagenologia_get_list", param).then(res => {
      console.log(res.data);
      if (res.data.length !== 0 ) {
        //--cargar los datos recibidos al data
        this.setState({data: res.data})        
      } else {
        this.setState({data: []})     
        NotificationManager.error("no existen datos");
      }

    }).catch(error => {
      alert("Bad Request");
      console.log(error);
    });
  }
  limpiar=()=>{
    this.setState({form: form_reset});
  }
  //-----------------------------------------
  nuevo_click=() =>{
    this.limpiar();
  }
  onclick_nuevo_click=() =>{
    this.limpiar();
  }
  //---- 
  onClickItemGallery=(filename)=>{
    this.props.navigate('/imagenzoom/'+ filename);
  }
  //-----------------------------
  guardar = () => {
    /* context API */
    let id_paciente = this.state.id_store;

    //let id_u = AuthService.getCurrentIdUser();
    let id_u = 0;

    let param = {
      idd: this.state.form.idd,
      ope: this.state.form.ope,
      id_paciente: id_paciente,
      filename: this.state.form.filename,
      detalle: this.state.form.detalle,
    } ;

    //return;
    let ruta = "imagenologia_store";
    axios2.post(ruta, param).then(res => {
      if (res.data.exito == 1) {
        NotificationManager.success(res.data.mensaje);

        //---- recargamos la lista de imagenes
        
        this.cargar_imagenes(id_paciente);
        //--mostramos ventana del certificado
        /*let fileURL =  process.env.REACT_APP_API_URL + "plantilla2_reciboPDF/"+ res.data.id;
        let  windowFeatures = 'width=650,height= 700, left=100,top=100 ';
        window.open(fileURL,'',windowFeatures);*/

      } else {
        NotificationManager.error(res.data.mensaje);
      }

    }).catch(error => {
      alert("Bad Request");
      console.log(error);
    });
  }
  //---------------------------------
  render() {
    const { form } = this.state;

    return (
      <div className="container p-3">

        { 
          //JSON.stringify(this.state.data)
          //JSON.stringify(this.props.store.paciente) 
        }
        <NotificationContainer />

        <section className="content-header">
          <h3>
            Imágenes 
          </h3>
        </section>


        <section className="content">
          
            <div className="row">
              <div className="col-md-9">

                {/*  Profile Image */}
                <div className="box box-primary">
                  <div className="box-body box-profile" style={{ textAlign: "center" }}>

                    <h4>Lista de imagenes</h4>

                  </div>                  

                  <div className="container mt-5">
                    <div className="lightBoxGallery" >
                      <div id="mi_galeria" className="row">
                        {/*  aqui lo dinamico*/}
                        <Gallery  
                          data={ this.state.data }
                          pathURL = { process.env.REACT_APP_AFILES } 
                          onClick={ this.onClickItemGallery }
                        />
                      </div>
                    </div>
                  </div>



                </div>
                {/*  /.box-body */}
              </div>
              {/*   */}

              <div className="col-md-3">

                {/*  Profile Image */}
                <div className="box box-primary">
                  <div className="box-header">
                    {this.state.form.ope==='n'?
                      <h4>Agregar </h4>
                      :
                      <h4>Editar </h4>
                    }
                  </div>
                  <div className="box-body box-profile" style={{ textAlign: "center" }}>
                    
                    <ImageUploadSingle  
                          okImage={ this.okImage}
                          cancelImage={ this.cancelImage}
                          imageURL={ process.env.REACT_APP_AFILES }
                          fileName={ this.state.form.filename }
                    />

                    <ul className="list-group list-group-unbordered">
                      <li className="list-group-item">

                        <div className="form-group">
                          <div className="input-group">

                            <span className="text-danger">Máximo tañano 2 MB </span>
                            <br />
                          </div>

                        </div>
                        {/*  <b>Following</b> <a className="pull-right">543</a> */}
                      </li>

                    </ul>

                    <div className="form-group">
                      <label className="control-label">Descripción</label>
                      <input type="text"
                        className="form-control"
                        name="detalle"
                        value={ form.detalle }
                        onChange={ this.handleChangeForm}  />
                    </div>

                  </div>
                  {/*  /.box-body */}


                  <div className="box-footer">
                    <div className="form-group">
                      <div className="text-center">
                        <button type="button" className="btn btn-success"
                          onClick={ this.guardar }>
                          <i className="fa fa-floppy-o"></i>
                          Guardar
                        </button>

                      </div>
                    </div>
                  </div>
                  {/*  */}
                  <div id='divres'>.</div>
                  {/*  */}
                </div>
                {/*  /.box */}

                {/*  /.box */}
              </div>
            </div>
         
        </section>

      </div >
    );
  }
}
export default withRouterJim(Imagenologia);