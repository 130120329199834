import React, { useRef, useState } from "react";
import AuthService from "../../services/auth.service";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import NuAlert2 from "../../components/NuAlert2";

const Signup = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password_confirmation, setPassword_confirmation] = useState("");
  
  const nuRef = useRef(null);

  const navigate = useNavigate();

  //----------------------------------------------------
  const handleSubmit = (e) => {
    e.preventDefault();
    const param = {
      name: name,
      email: email,
      password: password,
      password_confirmation:password_confirmation,

    };
    //console.log(config.API_URL + "register");
    axios.post( process.env.REACT_APP_API_URL  + "register",param, {
              headers: {
                "Content-Type": "application/json",
              }
          })
          .then(res => {
            let response = res.data;
            //console.log("res2->");
            //console.log(response)
            
            if(response.exito===0){
              
              nuRef.current.show({'type':'info',"text":response.mensaje});
            }else{
             //--correcto el registro  
             navigate("/home");
            }
          })
          .catch(err => {
            console.log("err->");
            console.log(err)
          });  
      
  };
  //----------------------------------------------------
  const handleSignup = async (e) => {
    e.preventDefault();
    try {
      await AuthService.signup(name, email, password, password_confirmation).then(
        (response) => {
          // check for token and user already exists with 200
          console.log("Sign up successfully", response);
          
          if(response.exito===0){
              
            nuRef.current.show({'type':'info',"text":response.mensaje});
          }else{
           //--correcto el registro  
           navigate("/home");
          }
          
          
          //window.location.reload();
        },
        (error) => {
          console.log(error);
        }
      );
    } catch (err) {
      console.log("err ajax");
      console.log(err);
    }
  };
  
  //----------------------------------------------------
  const ulStyle = {  color: '#fff',  background: '#63738a'}

  return (
    <div style={ulStyle}>

      <div className="signup-form">

        <form onSubmit={handleSignup}>
          <h2>Registro</h2>
          <p className="hint-text">Crear nueva cuenta.</p>
          <div className="form-group">
            <div className="row">
              <div className="col">
              <input
                type="text"
                placeholder="Nombre"
                className="form-control"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              </div>
            </div>
          </div>

          <div className="form-group">
            <input
                type="email"
                placeholder="Email"
                className="form-control"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
          </div>

          <div className="form-group">
            <input
              type="password"
              placeholder="Clave"
              className="form-control"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <div className="form-group">
            <input
                type="password"
                placeholder="Confirmar clave"
                className="form-control"
                value={password_confirmation}
                onChange={(e) => setPassword_confirmation(e.target.value)}
              />
          </div>
          
          <div className="form-group">
            <button type="submit" className="btn btn-success btn-lg btn-block">registrar</button>
          </div>

        </form>
        <div className="text-center">Already have an account? <a href="#">Sign in</a></div>
        <NuAlert2 ref={nuRef} />
      </div>

    </div>
  );
};

export default Signup;