import React from "react";

import axios2 from 'services/axios2';

import {useParams} from "react-router-dom";
import {Link,hashHistory} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { NuDropdown } from "../../components/nubox/NuDropdown";
import ImageUploadSingle from "components/nubox/ImageUploadSingle"; 

import confirmService from 'components/confirmService';
import ModalMedico from "views/medicos_crud/ModalMedico"



import user from 'assets/img/user.jpg';

import { NotificationContainer, NotificationManager } from 'react-notifications';
import {
  Table,
  Button,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  ModalFooter,
  ToastHeader,
} from "reactstrap";



/*$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$*/
class Menu_rep extends React.Component {  

  modalRef = React.createRef();//create ref
  
  state = {
    modalInsertar: false,
    form: {},
    data: [],/* lista  */
    
  };
  
  //------------------------------------------------------
  componentDidMount() {
    //--limpiamos el array de prueba
    this.setState({data:[]});

    const date = new Date();
    const defaultValue = date.toLocaleDateString('en-CA');
  
    //this.setState({fechai: defaultValue}); 
    this.setState({ form:{...this.state.form, fecha: defaultValue,}}); 
  }

  //----------------especial, actualiza valores del form -------------------------------
  handleChangeForm = (e) => {
    //requerimos la prop name="?" en cada control
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      },
    });
  };
  
  //====================================================== 

 
  //-------------------------------------
  render() {
    
    return (
      <div>
        <ToastContainer />
        <div className="content">

          {/*<!--- ------------------------------->*/}
          <div className="row">
            <div className="col-lg-6">

              <div className="main-card mb-3 card">
                <div className="card-header">ToDo List</div>
                <ul className="todo-list-wrapper list-group list-group-flush">
                  <li className="list-group-item">
                    <div className="todo-indicator bg-warning"></div>
                    <div className="widget-content p-0">
                      Pacientes atendidos por fecha
                    </div>
                  </li>
                  <li className="list-group-item">
                    <div className="todo-indicator bg-focus"></div>
                    <div className="widget-content p-0">
                      Pacientes con deuda
                    </div>
                  </li>
                  <li className="list-group-item">
                    <div className="todo-indicator bg-primary"></div>
                    <div className="widget-content p-0">
                      Recaudacion por fecha
                    </div>
                  </li>
                  <li className="list-group-item">
                    <div className="todo-indicator bg-info"></div>
                    <div className="widget-content p-0">
                      Listado de pacientes
                    </div>
                  </li>
                  
                </ul>
                <div className="d-block text-right card-footer">
                 
                </div>
              </div>
            </div>
            <div>

            </div>
          </div>

          {/*<!-- -------------------------------------------------->*/}

        </div>

      </div>
    );
  }
}
  
export default Menu_rep;