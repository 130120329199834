import React, { Component } from 'react';
import axios from "axios";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const url="https://localhost:44302/api/empresas/";

class Crud_ok extends  React.Component {
state={
  data:[],
  modalInsertar: false,
  modalEliminar: false,
  form:{
    idd:0,
    ope: 'n',
    id: '',
    nombre: '',
    pais: '',
    capital_bursatil: '',
  }
}
//---mostrar el modal
form_show=()=>{
  this.setState({modalInsertar: !this.state.modalInsertar});
}
form_close=()=>{
  this.setState({modalInsertar: false});
}

form_limpiar=()=>{
  this.setState({form: null, ope: 'n'})
}

edit_row=(empresa)=>{
  this.setState({
    tipoModal: 'actualizar',
    form: {
      id: empresa.id,
      nombre: empresa.nombre,
      pais: empresa.pais,
      capital_bursatil: empresa.capital_bursatil
    }
  })
}

modalInsertar=()=>{
  this.setState({modalInsertar: !this.state.modalInsertar});
}



render(){
  
  const {form}=this.state;

return (
  <div className="App">
  <br /><br /><br />

  <button className="btn btn-success" onClick={()=>{this.form_limpiar(); this.form_show()}}>Agregar</button>
  
  <br /><br />

  <table className="table ">
    <thead>
      <tr>
        <th>ID</th>
        <th>Nombre</th>
        <th>País</th>
        <th>Capital Bursatil (en millones de USD)</th>
        <th>Acciones</th>
      </tr>
    </thead>
    <tbody>
      {this.state.data.map(empresa=>{
        return(
          <tr>
        <td>{empresa.id}</td>
        <td>{empresa.nombre}</td>
        <td>{empresa.pais}</td>
        <td>{new Intl.NumberFormat("en-EN").format(empresa.capital_bursatil)}</td>
        <td>
              <button className="btn btn-primary" onClick={()=>{this.edit_row(empresa); this.form_show()}}><FontAwesomeIcon icon={faEdit}/></button>
              {"   "}
              <button className="btn btn-danger" onClick={()=>{this.edit_row(empresa); this.setState({modalEliminar: true})}}><FontAwesomeIcon icon={faTrashAlt}/></button>
              </td>
        </tr>
        )
      })}
    </tbody>
  </table>



  <Modal isOpen={this.state.modalInsertar}>
              <ModalHeader style={{display: 'block'}}>
                <span style={{float: 'right'}} onClick={()=>this.modalInsertar()}>x</span>
              </ModalHeader>
              <ModalBody>
                <div className="form-group">
                  <label htmlFor="id">ID</label>
                  <input className="form-control" type="text" name="id" id="id" readOnly onChange={this.handleChange} value={form?form.id: this.state.data.length+1}/>
                  <br />
                  <label htmlFor="nombre">Nombre</label>
                  <input className="form-control" type="text" name="nombre" id="nombre" onChange={this.handleChange} value={form?form.nombre: ''}/>
                  <br />
                  <label htmlFor="nombre">País</label>
                  <input className="form-control" type="text" name="pais" id="pais" onChange={this.handleChange} value={form?form.pais: ''}/>
                  <br />
                  <label htmlFor="capital_bursatil">Capital Bursatil</label>
                  <input className="form-control" type="text" name="capital_bursatil" id="capital_bursatil" onChange={this.handleChange} value={form?form.capital_bursatil:''}/>
                </div>
              </ModalBody>

              <ModalFooter>
                
                  <button className="btn btn-success" onClick={()=>this.form_save()}>
                  {this.state.ope=='insertar'? <div>Insertar</div> : <div>Actualizar</div>
                  }
                  </button>
                  <button className="btn btn-danger" onClick={()=>this.form_close()}>Cancelar</button>
              </ModalFooter>
        </Modal>


        <Modal isOpen={this.state.modalEliminar}>
          <ModalBody>
             Estás seguro que deseas eliminar a la empresa {form && form.nombre}
          </ModalBody>
          <ModalFooter>
            <button className="btn btn-danger" onClick={()=>this.peticionDelete()}>Sí</button>
            <button className="btn btn-secundary" onClick={()=>this.setState({modalEliminar: false})}>No</button>
          </ModalFooter>
        </Modal>
</div>



);
}
}
export default Crud_ok;