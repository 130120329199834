import React from "react";
import {Link,hashHistory} from 'react-router-dom';
import axios2 from 'services/axios2';
import { withRouterJim } from "router/withRouterJim";
import AuthService from "services/auth.service";

import { NotificationContainer, NotificationManager } from 'react-notifications';
import {
  Table,
  Button,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  ModalFooter,
} from "reactstrap";



const data = [
  { "id_tra": 1,  "fecha": "2023-02-26",  "estado": 1,  "mstotal": 0,  "mdesc": 0,  "mtotal": 0,  "pagado": null,  "0": "saldo",  "1": 0},
 
];



class PagoList extends React.Component {
  state = {
    data: data,
    id_tra :0,
    id_paciente:'', /*id_paciente */
   
    form: {
      idd: "",
      ope: "",
      
    },
  };
  
  componentDidMount() {
    //--limpiamos el array de prueba
    this.setState({data:[]});

    const date = new Date();
    const defaultValue = date.toLocaleDateString('en-CA');
  
    //this.setState({fechai: defaultValue}); 

    let id = this.props.store.paciente.id_paciente;
    
    this.setState({id_paciente: id});

    this.fetchData(id);
  }

  
  //----cargamos los datos desde la base de datos
  fetchData = ( id ) => {
    let param = { 
      id_paciente: id,
    }
    
    axios2.post("pago_get_list", param).then(res => {

        if(res.data.length>0 ){             
            this.setState({
              data: res.data,
            });
        }else{
            NotificationManager.error("No existen registros");
        }

    }).catch(error => {
        NotificationManager.error("Bad Request");
        //this.setState({ errors: error })
        console.log(error);
    });
  };
 
  //------------------------------------------------------------------
  handleChange = (e) => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      },
    });
  };

 
  //--------------------------------------------------------------------
  render() {
    
    return (
      <>
        <Container style={{ marginTop: "50px", minHeight: "700px" }}>

          <NotificationContainer />
          <br />



          <div className="row">

            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">


              {/*  Authentication card end ---------------------------------------------  */}
              <div className="card">
                <div className="card-header">
                  <h3>Pagos</h3>
                </div>

                <div className="card-body">

                <div className="alert alert-primary" role="alert">
                  <b>Nota:</b> 
                </div>
                  <Table>
                    <thead>
                      
                      <tr style={{backgroundColor: "#e3e3e3"}}>
                        <th>Nro.Pago</th>
                        <th>Tratamiento</th>
                        <th>Concepto</th>
                        <th>Fecha pago</th>
                        <th>Monto</th>
                        <th>Usuario</th>
                      </tr>
                    </thead>

                    <tbody>
                      {this.state.data.map((item) => (
                        <tr key={item.id_tra}>
                          <td>{item.id_tra}</td>
                          <td>{item.nombre}</td>
                          <td>{item.concepto}</td>
                          <td>{item.fecha}</td>
                          <td>{item.monto}</td>
                          <td>
                            {/*<Button color="info" onClick={() => this.pagar(item.id_tra)}>Pagar</Button>*/}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                 
                </div>

                <div className="card-footer">
                  <div className="text-center">

                    

                  </div>
                </div>
              </div>

           
            </div>{/*  end    */}

          </div>

        </Container>

      </>
    );
  }
}

export default withRouterJim(PagoList);