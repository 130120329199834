import React, {useState} from 'react';

import {  Link } from "react-router-dom";
import logo from '../assets/logo.png';

const Settings =()=>{
    const initialFormState= {
        idd:0,
        ope: 'n',
        id: "",
        lote: "",
        elem1: "",
        elem2: "",
        elem3: "",
      }

    //--state
    const [editing, setEditing] = useState(false);
    const [form00, setForm00] = useState(initialFormState);

    /*const editRow = user =>{
        setEditing(true)
        setCurrentUser({id: user.id, name: user.name, username: user.username })
    }

    const updateUser = (id, updateUser) =>{
        setEditing(false)
        setUsers(users.map(user=>(user.id === id? updateUser: user)))
    }*/
    
    //----------------------------------------
    const handleCellChange =(index, columnId, value) =>{

        /*const newData = [...data];
        newData[index][columnId] = value;
        
        console.log(data);
        //setData({  data: newData    });
        setData({ newData });*/
        
    }
    //--------------------------------------------
    const handleSubmit  = (data, e) => {
        console.log(data)
    
        //data.id = props.currentUser.id;
    
        //props.udpateUser(props.currentUser.id, data);
        //setUser(initialFormState);
        //limpiar campos
        e.target.reset();
    }
    //--------------------------------------------

    return(
        <div className="content">
            <div className="row">
                <div className="col-lg-8 offset-lg-2">

                    <form onSubmit={handleSubmit}>
                        <h3 className="page-title">Company Settings</h3>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>Company Name <span className="text-danger">*</span></label>
                                    <input className="form-control" type="text" value="" />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>Contact Person</label>
                                    <input className="form-control " value="Daniel Porter" type="text" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label>Address</label>
                                    <input className="form-control " value="3864 Quiet Valley Lane, Sherman Oaks, CA, 91403" type="text" />
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-3">
                                <div className="form-group">
                                    <label>Country</label>
                                    <select className="form-control select select2-hidden-accessible"  aria-hidden="true">
                                        <option>USA</option>
                                        <option>United Kingdom</option>
                                    </select><span className="select2 select2-container select2-container--default select2-container--below" dir="ltr" 
                                        style={{ Width:"100%" }}>
                                        <span className="selection"><span className="select2-selection select2-selection--single" role="combobox" aria-haspopup="true" aria-expanded="false" aria-labelledby="select2-yptg-container"><span className="select2-selection__rendered" id="select2-yptg-container" title="USA">USA</span><span className="select2-selection__arrow" role="presentation"><b role="presentation"></b></span></span></span><span className="dropdown-wrapper" aria-hidden="true"></span></span>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-3">
                                <div className="form-group">
                                    <label>City</label>
                                    <input className="form-control" value="Sherman Oaks" type="text" />
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-3">
                                <div className="form-group">
                                    <label>State/Province</label>
                                    <select className="form-control select select2-hidden-accessible" aria-hidden="true">
                                        <option>California</option>
                                        <option>Alaska</option>
                                        <option>Alabama</option>
                                    </select>
                                    <span className="select2 select2-container select2-container--default select2-container--below" dir="ltr" 
                                        style={{ width: "100%" }} ><span className="selection"><span className="select2-selection select2-selection--single" role="combobox" aria-haspopup="true" aria-expanded="false" aria-labelledby="select2-ylqa-container"><span className="select2-selection__rendered" id="select2-ylqa-container" title="California">California</span><span className="select2-selection__arrow" role="presentation"><b role="presentation"></b></span></span></span><span className="dropdown-wrapper" aria-hidden="true"></span></span>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-3">
                                <div className="form-group">
                                    <label>Postal Code</label>
                                    <input className="form-control" value="91403" type="text" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>Email</label>
                                    <input className="form-control" value="danielporter@example.com" type="email" />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>Phone Number</label>
                                    <input className="form-control" value="818-978-7102" type="text" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>Mobile Number</label>
                                    <input className="form-control" value="818-635-5579" type="text" />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>Fax</label>
                                    <input className="form-control" value="818-978-7102" type="text" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label>Website Url</label>
                                    <input className="form-control" value="https://www.example.com" type="text" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 text-center m-t-20">
                                <button type="submit" className="btn btn-primary submit-btn">Save</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );

}

export default Settings;