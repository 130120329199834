import React, { useRef, useEffect, useState } from "react";

import NuLoading from 'components/nubox/NuLoading';
import axios2 from 'services/axios2';

import './styles.css'
//import './nuCheckBox.css'
import {
  Table,
  Button,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  ModalFooter,
  ToastHeader,
} from "reactstrap";


const ImageUploadSingle = ({ id, label, checked,withImage="auto", ...props }) => {
  // 
  const inputFileRef = React.useRef();
  const [isloaging, setIsloaging] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [fileName, setFileName] = useState("");
  const [selectedFile, setSelectedFile] = useState('');
  
  //--------- cada vez que cambie el props.fileName
  useEffect(() => {
    setImageUrl(props.imageURL + props.fileName);
    setFileName(props.fileName);
  }, [props.fileName]);

  function handleChange(event) {
    console.log(event.target.value);
  }
  //
  const chooseFile = (e) => {
    e.preventDefault();

    console.log("chooseFile");
    //uploadImage_Click();
    inputFileRef.current.click();
    //---------------
  }

  async function uploadImage_Change(event) {
    //---------------------
    const URL = "image_uploadPhoto";
    const data = new FormData();
    data.append("name", "my-picture");
    data.append("imageFile", event.target.files[0]);
    const config = {
        header: {
            "Content-Type": "image/png",
        },
    };

    
    setIsloaging(true);

    axios2.post(URL, data, config)
    .then(function (response) {
        console.log(response.data);
        //this.availabilityMessage = response.data.message;
        setIsloaging(false);

        if(response.data.exito===0){
          setMensaje( response.data.mensaje );
        }else{
          //------ correcto ---
          props.okImage(response.data.filename);
        }
        //let aux = process.env.REACT_APP_AFILES + response.data.filename;
        //setImageUrl(aux);
        //setFileName( response.data.filename);
        

    }).catch(function (error) {
        // this.availabilityMessage = false;
        console.log(error);
    });
  }
  //-------------------------------------------
  
  function cancelar_click(e) {
    e.preventDefault();

    props.cancelImage();
  }

  
  return (
    <>
      <div className="">
        {/* imageUrl */}
        
        <div className=" previewBlock file-img img-responsive " style={{ width: withImage }}>
        { fileName!==''?
          <img  data-dz-thumbnail="" 
                alt={fileName}
                src={`${imageUrl}`}
                style={{ width:"100%"}}
          />
          : <div className="box1" style={{ height:"90px"}} >Sin archivo</div>
        }
        </div>
              
        <br/>
        {/*   botones  */}
        <div>
          <input
            className="form-control form-control-lg"
            type="file"
            ref={inputFileRef}
            value={selectedFile}
            onChange={(e) => uploadImage_Change(e)}
            style={{display: "none" }}
          />

          <div className="form-group">
            <div className="bootstrap-filestyle input-group">

                <input 
                  value={fileName}
                  onChange={chooseFile} 
                  type="text" 
                  className="form-control " 
                  placeholder="" 
                  readOnly/>

                <span className="input-group-btn" tabIndex="0">
                  <button 
                    className="btn btn-default btn-flat"
                    onClick={ chooseFile }>...</button>
                </span>

                <div className="btn-group">
                  <Button  onClick={ cancelar_click } 
                    className="btn btn-danger" >
                    <i className="fa fa-trash"></i>
                  </Button>      
                </div>
                
            </div>
          </div>
         
         {/*<!-- -->*/}
         <NuLoading text="Cargando...." show= { isloaging }/>
          {/*<!-- -->*/}
          <span>{ mensaje }</span>

        </div>
      </div>
    </>
  );
}
  
export default ImageUploadSingle;


