import React from "react";
import axios from 'axios';
import axios2 from 'services/axios2';

import {useParams} from "react-router-dom";
import {Link,hashHistory} from 'react-router-dom';
import { useNavigate } from "react-router-dom";

import { ToastContainer, toast } from 'react-toastify';
import { NuDropdown } from "../../components/nubox/NuDropdown";
import  NuAutocomplete  from "../../components/nubox/NuAutocomplete";
import  Autocomplete  from "../../components/nubox/Autocomplete";

import { withRouterJim } from "router/withRouterJim";

//---cutil
import cUtilFecha from "helpers/cUtilFecha"
//--- CONTEXT API -----------
import { useStore, useDispatch } from "store/StoreProvider";
import { types } from "store/StoreReducer";


import { NotificationContainer, NotificationManager } from 'react-notifications';
import {
  Table,
  Button,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  ModalFooter,
  ToastHeader,
} from "reactstrap";


//type:'numeric'
const columnas=[
    {  field:'id', title: 'Cod. Cert.', type:'numeric'},
    {  field:'cliente', title: 'Cliente'},
    {  field:'nombre', title: 'Nombre'},
    {  field:'lote', title: 'Lote'},
    {  field:'elem', title: 'Elementos'},
];
const cbo=[
  {  value:'1', label: 'label1'},
  {  value:'2', label: 'Clss'},
  {  value:'3', label: 'Nod'},
  {  value:'4', label: 'Lod'},
  {  value:'5', label: 'Elggg'},
]

const data = [
  { id_paciente: 1,  nombre: "ALEX LIZARRAGA",  nro_doc: "00000023",  fecha_nac: "2022-01-13",  foto_path1: "1665435807.jpg",  dir: "Cerro Bola #319",  cel: "0123456789"},
  { id_paciente: 2,  nombre: "MARIANELA",  nro_doc: "00000023",  fecha_nac: "2022-01-13",  foto_path1: "1665435807.jpg",  dir: "Cerro Bola #319",  cel: "0123456789"},
 
];

//$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
class Pacientes extends React.Component {
  state = {

    modalActualizar: false,
    modalInsertar: false,
    form: {
      name: '',
    },
    data: data,/* lista de citas */
    
  };
  
  //------------------------------------------------------
  componentDidMount() {
    console.log("componentDidMount");
    //--limpiamos el array de prueba
    this.setState({data:[]});

    const date = new Date();
    const defaultValue = date.toLocaleDateString('en-CA');
  
    //this.setState({fechai: defaultValue}); 
    this.setState({ form:{...this.state.form, fecha: defaultValue,}}); 

    this.cargar_pacientes();
    
    //this.cargar_citas();
    //this.fetchData(this.state.fechai);
  }

  //------------ cambiamos la fecha
  /*handleChange = event =>{
    this.setState({ name: event.target.value});
  }*/
  //----------------especial, actualiza valores del form -------------------------------
  handleChangeForm = (e) => {
    //requerimos la prop name="?" en cada control
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      },
    });
  };
  
  //====================================================== 
  //----cargamos los datos desde la base de datos
  /*{
    "id_paciente": 1,
    "nombre": "ALEX LIZARRAGA",
    "nro_doc": "00000023",
    "fecha_nac": "2022-01-13",
    "foto_path1": "1665435807.jpg",
    "dir": "Cerro Bola #319",
    "cel": "0123456789"
  },*/
  cargar_pacientes = () => {
    //console.log("cargar_pacientes");
    let param = { 
      param1: this.state.form.name
    }
    

    axios2.post("pacientecrud_lista", param)
    .then(res => {
        if(res.data.length>0){ 
            //NotificationManager.success("Correcto");
            console.log(res.data);
            this.setState({data: res.data});
            //console.log(this.state.medicos);
        }else{
            NotificationManager.error("No existen datos");
        }

    }).catch(error => {
        alert("---- Existio un error ---");
        console.log(error);
        
    });
  };
  //--------------------------------------
  imprimir =(id_certi)=>{
    let fileURL = process.env.REACT_APP_API_URL  + "certificado_reciboPDF/"+ id_certi;
    let  windowFeatures = 'width=650,height= 700, left=100,top=100 ';
    window.open(fileURL,'',windowFeatures);
  }
  //--------------------------------------
  
  /* === This is where the magic happens ==== */
  handleSubmit = event => {
    event.preventDefault();
    this.cargar_pacientes();
  }
  //-------------
  onLinkClick = (e,item) => {
    e.preventDefault();
    //--------primero actualizamos datos del paciente en el 
    //--- context api
    let obj = { "id_paciente": item.id_paciente,
                "nombre": item.nombre,
                "foto_path1": item.foto_path1,
                "fecha_nac": item.fecha_nac,
              };
    this.props.dispatch({type: types.setPaciente, payload: obj }); 
    //---do your stuff---
    
    this.props.navigate('/paciente_show/e/'+ item.id_paciente);
    
  };
  /*handleChange = event =>{
    this.setState({ name: event.target.value});
  }*/
  //-------------------------------------
  render() {
    
    return (
      <div>
        <ToastContainer />
        <div className="content">

          {/*<!--- ------------------------------->*/}
          <div className="row">
            <div className="col-md-12">
              <h3> Lista de pacientes

                <div className="btn-group pull-right">

                  <Link to= { "/paciente_new/n/0" } className="create-modal btn btn-info " >
                    <i className="fa fa-plus"></i> Nuevo paciente
                  </Link>
                </div>
              </h3>


              <div className="box box-info">
                {/*<!-- /.box-header -->*/}
                <div className="box-body pad">

                  <form onSubmit = { this.handleSubmit }>
                    
                    <div className="row">

                      <div className="col-md-4"> 
                        <div className="form-group"> 
                          <label className="control-label">Busqueda por nombre:</label>
                          <input type="text" name = "name" onChange= {this.handleChangeForm} className="form-control input-sm" />
                        </div> 
                      </div> 

                      <div className="col-md-2">
                        <button type="submit" style={{ marginTop: "24px" }} className="btn btn-success ">
                          <i className="fa fa-binoculars"></i> Buscar</button>

                      </div>

                      <div id="divres1"></div>
                    </div>
                  </form>

                </div>
                {/*<!-- /.box-body -->*/}
              </div>


              {/*<!-- box table -->*/}
              <div className="box">
                <div className="box-header">
                  <br/>
                    <div className="col-md-12">


                      <div className="table-responsive">
                        <table className="table tabla_azul0 table-striped table-hover ">
                          <thead>
                            <tr>

                              <th>Cod.P</th>
                              <th>Nombre</th>
                              <th>C.I.</th>
                              <th>Edad</th>
                              <th>Dirección</th>
                              <th>Cel</th>


                              <th className="text-center" style={{ width:"150px" }}>

                              </th>
                            </tr>
                          </thead>
                          <tbody>
                        
                            {this.state.data.map((item,index) => (
                              <tr key={index}>
                                <td>{ item.id_paciente }
                                
                                </td>
                                <td>
                                  <Link to="/nada" onClick={(e)=>this.onLinkClick(e,item)}>
                                  { item.foto_path1.length > 0 &&
                                    <img src={ process.env.REACT_APP_AFILES + item.foto_path1} className="avatar" alt="Ver expediente del paciente" /> 
                                    
                                  }
                                  {item.nombre}
                                
                                  </Link>
                                </td>
                                <td>{item.nro_doc}</td>
                                <td style={{textAlign: "center"}}>
                                  { cUtilFecha.edad(item.fecha_nac) } 
                                </td>
                                <td>{item.dir}</td>
                                <td className='text-center'>{item.cel}</td>
                                <td>
                                  <div className="btn-group">
                                    <Link to= { "/paciente_show/e/" + item.id_paciente  } title="Editar" className="btn btn-warning btn-sm">
                                      <i className="fa fa-edit"></i>
                                    </Link>
                                    &nbsp;
                                    <Button className="btn btn-danger btn-sm" title="Eliminar" onClick="eliminar(1,'');"  >
                                      <i className="fa fa-trash"></i> 
                                    </Button>
                                  </div>
                                </td>
                              </tr>
                            ))}
                             

                          </tbody>
                          
                          </table>
                        <div className="pull-left">

                        </div>
                      </div>

                    </div>


                </div>
              </div>
              {/*<!-- fin box table -->*/}

            </div>

          </div>
          {/*<!--- ----------------- form busqueda ------------------>*/}

          {/*<!-- -------------------------------------------------->*/}

        </div>
      </div>
    );
  }
}
  
//------------para que soporte la funcionalidad de parametros
export default withRouterJim(Pacientes);