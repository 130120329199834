import React, { useRef, useEffect, useState, useContext } from "react";

import { Link } from 'react-router-dom';
import AuthService from "../services/auth.service";
import { useNavigate } from "react-router-dom";
import { useHistory } from 'react-router-dom';


import InputBox from "../components/InputBox";
import { Outlet } from "react-router-dom";
import logo2 from "../assets/img/logo2.png";
 
//--componentes
import back2 from 'assets/img/back2.png';
//import authService from "../services/auth.service";

//---framework
import cUtilFecha from "helpers/cUtilFecha"

//--- CONTEXT API -----------
import { useStore, useDispatch } from "store/StoreProvider";
import { types } from "store/StoreReducer"; 

/* ===============================================================*/
// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    //---------------- CONTEXT API ------------------
    const store = useStore();
    const dispatch = useDispatch();
    //--- sacamos el valor del paciente
    const {user, paciente} = store;
    

    //---toogle menu side
    const [menuSideShow, setMenuSideToogle] = useState(false);
    const [isOverPanel, setIsOverPanel] = useState(false);

    const [modalShow, setModalShow] = useState(false);
    const [input, setInput] = useState("");

    const [modalShow1, setModalShow1] = useState(false);
    const [input1, setInput1] = useState("");

    //const [user, setUser] = useState(null);

    const navigate = useNavigate();
    
    //--------------para el timer---
    const [expirationDate, setExpirationDate] = useState("");
    let timer = useRef(null);
    let i=0;

    //---trabajo en el fondo
    /*id_ca: 0
    id_rol: 0
    id_u: 11
    rol_nom: ""
    username: "anita"
    */
    /*useEffect (() => {
        //refreshToken();
        //setUser(authService.getCurrentUser());
        console.log(user);
    },[]);*/
    //---------------------------------------------
    const refreshToken =async()=>{
        try {
            await AuthService.refreshToken().then(
              (response) => {
                
                if(response!=""){
                    setExpirationDate({"expirationDate": response});
                  
                }else{
                 //--correcto el registro  
                 navigate("/login");
                }
                //window.location.reload();
              },
              (error) => {
                console.log(error);
              }
            );
          } catch (err) {
            console.log(err);
        } 
    }
    
    //-----------------------------------
    const logout=(e)=>{
        e.preventDefault();
        //console.log("aqui estoy");
        AuthService.logout();
        navigate("/login");
        //let history = useHistory();
        //history.push("/login");
    }
    //---------- si la ultima cadena en el pathname es igual a nameLink ------------------
    const isActiveItemJim = ( nameLink ) => {  
      let sol= false;
      const pathname = window.location.pathname;
      //console.log("pathname:"+pathname+ " nameLink:"+ nameLink);///paciente_show/e/1
      //const active = pathname === nameLink? true:false;
      let result = pathname.indexOf(nameLink);
      //console.log(result);
      if(result!=-1){
        sol= true;
      }
        
      return sol;  //true o false
    };
    //---mostrar y ocultar sideshow
    const menuSideToogle_click=()=>{ 

        //-- 1ra modif
        //html className="menu-opened"
        const elem1 = document.querySelector("body");
        elem1.classList.toggle("mini-sidebar");


        if(menuSideShow){
            //-- esta en true
            console.log("true");
            setMenuSideToogle(false);  
        }else{
            console.log("false");
            setMenuSideToogle(true);  
        }

    }
    const menuSideToogleMovile_click=()=>{ 

        //-- 1ra modif
        //html className="menu-opened"
        const elem = document.querySelector("div.main-wrapper");
        elem.classList.toggle("slide-nav");
        //------2da modifi
        const elem2 = document.querySelector("div.sidebar");
        elem2.classList.toggle("opened");
        
        //------3da modifi
        const elem1 = document.querySelector("body");
        elem1.classList.toggle("menu-opened");

        const elem3 = document.querySelector("div.sidebar-overlay");
        elem3.classList.toggle("opened");
        

        if(menuSideShow){
            //-- esta en true
            console.log("true");
            setMenuSideToogle(false);  
        }else{
            console.log("false");
            setMenuSideToogle(true);  
        }

    }
    /*-------- */
    
    const onMouseEnterHandler=(e)=>{ 
        const elem1 = document.querySelector("body.mini-sidebar");
        if(elem1!=null){
            elem1.classList.add("expand-menu");
        }
        //e.target.style.background = 'red';
        // showbar
      
    }
    const onMouseLeaveHandler=(e)=>{ 
        const elem1 = document.querySelector("body.mini-sidebar");
        if(elem1!=null){
            elem1.classList.remove("expand-menu");
        }
        //e.target.style.background = 'blue';
      
    }
    /*-------- */
    const handleCancel1=()=>{ setModalShow1(false);   }
    const handleChange1=(e)=>{setInput1(e.target.value);  }
    //------------------ ------------------------
    
  
    //-----------------------------------------------------------
    return (
        <div>
            <div className="main-wrapper">
                <div className="header">
                    <div className="header-left">
                    <Link to="/dashboard" className="logo">
                      <img  src={ process.env.REACT_APP_AFILES + user.e_logo}
                            alt="" width="35" 
                            height="35" 
                            tag="" />
                    </Link>
                    </div>
                    {/*barra de menu*/}
                    <a id="toggle_btn" href="javascript:void(0);" onClick={(e) => menuSideToogle_click()}>
                        <i className="fa fa-bars"></i>
                        {menuSideShow}
                    </a>
                    <a  id="mobile_btn" 
                        className="mobile_btn float-left" 
                        href="#sidebar" 
                        onClick={(e) => menuSideToogleMovile_click()}>
                        <i className="fa fa-bars"></i>
                    </a>
                    {/*---- */}
                    <ul className="nav user-menu float-left">
                        <li className="nav-item dropdown d-none d-sm-block">
                          <span className="menu_text">
                            Rol: { user.rol_nom}
                          </span>
                        </li>
                    </ul>
                    {/*---- */}
                    <ul className="nav user-menu float-right">
                        <li className="nav-item dropdown d-none d-sm-block">
                            <a href="#" className="dropdown-toggle nav-link" data-toggle="dropdown">
                              <i className="fa fa-bell-o"></i> 
                            <span className="badge badge-pill bg-danger float-right">3</span></a>
                            <div className="dropdown-menu notifications">
                                <div className="topnav-dropdown-header">
                                    <span>Notifications</span>
                                </div>
                                <div className="drop-scroll">
                                    <ul className="notification-list">
                                        <li className="notification-message">
                                            <a href="activities.html">
                                                <div className="media">
                                                    <span className="avatar">
                                                        <img alt="John Doe" src="assets/img/user.jpg" className="img-fluid" tag="" />
                                                    </span>
                                                    <div className="media-body">
                                                        <p className="noti-details"><span className="noti-title">John Doe</span> added new task <span className="noti-title">Patient appointment booking</span></p>
                                                        <p className="noti-time"><span className="notification-time">4 mins ago</span></p>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                        <li className="notification-message">
                                            <a href="activities.html">
                                                <div className="media">
                                                    <span className="avatar">V</span>
                                                    <div className="media-body">
                                                        <p className="noti-details"><span className="noti-title">Tarah Shropshire</span> changed the task name <span className="noti-title">Appointment booking with payment gateway</span></p>
                                                        <p className="noti-time"><span className="notification-time">6 mins ago</span></p>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                        <li className="notification-message">
                                            <a href="activities.html">
                                                <div className="media">
                                                    <span className="avatar">L</span>
                                                    <div className="media-body">
                                                        <p className="noti-details"><span className="noti-title">Misty Tison</span> added <span className="noti-title">Domenic Houston</span> and <span className="noti-title">Claire Mapes</span> to project <span className="noti-title">Doctor available module</span></p>
                                                        <p className="noti-time"><span className="notification-time">8 mins ago</span></p>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                        <li className="notification-message">
                                            <a href="activities.html">
                                                <div className="media">
                                                    <span className="avatar">G</span>
                                                    <div className="media-body">
                                                        <p className="noti-details"><span className="noti-title">Rolland Webber</span> completed task <span className="noti-title">Patient and Doctor video conferencing</span></p>
                                                        <p className="noti-time"><span className="notification-time">12 mins ago</span></p>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                        <li className="notification-message">
                                            <a href="activities.html">
                                                <div className="media">
                                                    <span className="avatar">V</span>
                                                    <div className="media-body">
                                                        <p className="noti-details"><span className="noti-title">Bernardo Galaviz</span> added new task <span className="noti-title">Private chat module</span></p>
                                                        <p className="noti-time"><span className="notification-time">2 days ago</span></p>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="topnav-dropdown-footer">
                                    <a href="activities.html">View all Notifications</a>
                                </div>
                            </div>
                        </li>
                        <li className="nav-item dropdown d-none d-sm-block">
                            <a href="javascript:void(0);" id="open_msg_box" className="hasnotifications nav-link"><i className="fa fa-comment-o"></i> <span className="badge badge-pill bg-danger float-right">8</span></a>
                        </li>
                        <li className="nav-item dropdown has-arrow">
                            <a href="#" className="dropdown-toggle nav-link user-link" data-bs-toggle="dropdown">
                                <span className="user-img">
                                { user?.foto_path1.length > 0 &&
                                  <img    src={ process.env.REACT_APP_AFILES + user.foto_path1} 
                                        className="rounded-circle" 
                                        alt="Usuario" 
                                        width="40" tag=""/> 
                                    
                                }
                                </span>
                                <span>{ user?.username }</span>
                            </a>
                            <div className="dropdown-menu">
                                <a className="dropdown-item" href="login.html">Logout</a>
                            </div>
                        </li>


                        <li className="nav-item dropdown has-arrow">
                            {/*<Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                        Dropdown Button
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                        <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                        <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown> */}
                        </li>
                    </ul>
                    <div className="dropdown mobile-user-menu float-right">
                        <a href="#" className="nav-link dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-v"></i></a>
                        <div className="dropdown-menu dropdown-menu-right">
                            <a className="dropdown-item" href="profile.html">My Profile</a>
                            <a className="dropdown-item" href="edit-profile.html">Edit Profile</a>
                            <a className="dropdown-item" href="settings.html">Settings</a>
                            <a className="dropdown-item" href="login.html">Logout</a>
                        </div>
                    </div>
                </div>
                <div className="sidebar" id="sidebar">
                  <div className="slimScrollDiv"
                    style={{ position: "relative", overflow: "hidden", width: "100%", height: "894px" }}
                  >
                    <div className="sidebar-inner slimscroll"
                      style={{ overflow: "hidden", width: "100%", height: "894px" }}
                      onMouseEnter={onMouseEnterHandler}
                      onMouseLeave={onMouseLeaveHandler}
                    >
                      <div className="sidebar-menu">
                        <ul>
                          <li>
                            <Link to="/pacientes">
                              <img src={back2} width="20" alt="" />
                              <span>Menú Principal</span>
                            </Link>
                          </li>

                          <li>
                            <div className="widget-user-header bg-primary1" style={{ backgroundColor: "#e6e3e3" }}>


                              {
                                paciente !== null &&
                                <img src={process.env.REACT_APP_AFILES + paciente.foto_path1}
                                  className="avatar"
                                  alt="Perfil del paciente" />
                              }

                              <h3 className="profile-username text-center">{paciente.nombre}</h3>
                              <span className="membership-big-txt emailr"
                                style={{ textAlign: "center", fontSize: "14pt" }}
                              >{cUtilFecha.edad(paciente.fecha_nac)} Años</span>

                            </div>
                          </li>
                          <li className={isActiveItemJim('paciente_show') ? 'active' : ''}>
                            <Link to={`/paciente_show/e/${paciente.id_paciente}`} >
                              <i className="fa fa-user"></i> <span>Datos paciente</span>
                            </Link >
                          </li>

                          <li className={isActiveItemJim('historialist') ? 'active' : ''}>
                            <Link to={`/historialist/` + paciente.id_paciente}>
                              <i className="fa fa-file-text-o"></i> <span>Exploración clínica </span>
                            </Link >
                          </li>

                          <li className={isActiveItemJim('imagenologia') ? 'active' : ''}>
                            <Link to={`/imagenologia/` + paciente.id_paciente}>
                              <i className="fa fa-image"></i> <span>Imagenes</span>
                            </Link >
                          </li>

                          <li className={isActiveItemJim('citasmi') ? 'active' : ''}>
                            <Link to="/citasmi" >
                              <i className="fa fa-calendar"></i> <span>Citas</span>
                            </Link >
                          </li>

                          <li className="menu-title">Clinico</li>

                          <li className={isActiveItemJim('tratamientolist') ? 'active' : ''}>
                            <Link to={`/tratamientolist/` + paciente.id_paciente}>
                              <i className="fa fa-image"></i> <span>Tratamientos</span>
                            </Link >
                          </li>
                         
                          <li className={isActiveItemJim('recetalist') ? 'active' : ''}>
                            <Link to="/recetalist">
                              <i className="fa fa-file"></i> <span>Recetas</span>
                            </Link>
                          </li>

                          <li className="menu-title">Facturación</li>
                          <li className={isActiveItemJim('recaudacionlist') ? 'active' : ''}>
                            <Link to="/recaudacionlist">
                              <i className="fa fa-user-md"></i> <span>Recaudación</span>
                            </Link>
                          </li>
                          <li className={isActiveItemJim('pagolist') ? 'active' : ''}>
                            <Link to="/pagolist">
                              <i className="fa fa-list-alt"></i> <span>Lista de pagos</span>
                            </Link>
                          </li>
                          <li>
                            <Link to="/archivos"><i className="fa fa-file"></i> <span>Reportes</span></Link>
                          </li>





                        </ul>
                      </div>
                    </div>
                    <div className="slimScrollBar"
                      style={{ background: "rgb(204, 204, 204)", width: "7px", position: "absolute", top: "0px", opacity: "0.4", display: "none", borderRadius: "7px", zIndex: "99", right: "1px", height: "894px" }}>
                    </div>
                  </div>
                </div>
                <div className="page-wrapper" style={{ minHeight: "954px" }}>

                    {/* incrustar */}
                    <Outlet />
                    {/* incrustar */}

                    <div className="notification-box">
                        <div className="msg-sidebar notifications msg-noti">
                            <div className="topnav-dropdown-header">
                                <span>Messages</span>
                            </div>
                            <div className="slimScrollDiv"
                                style={{ position: "relative", overflow: "hidden", width: "auto", height: "830px" }}>

                                <div className="drop-scroll msg-list-scroll" id="msg_list"
                                    style={{ overflow: "hidden", width: "auto", height: "830px" }} >

                                    <ul className="list-box">
                                        <li>
                                            <a href="chat.html">
                                                <div className="list-item">
                                                    <div className="list-left">
                                                        <span className="avatar">R</span>
                                                    </div>
                                                    <div className="list-body">
                                                        <span className="message-author">Richard Miles </span>
                                                        <span className="message-time">12:28 AM</span>
                                                        <div className="clearfix"></div>
                                                        <span className="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="chat.html">
                                                <div className="list-item new-message">
                                                    <div className="list-left">
                                                        <span className="avatar">J</span>
                                                    </div>
                                                    <div className="list-body">
                                                        <span className="message-author">John Doe</span>
                                                        <span className="message-time">1 Aug</span>
                                                        <div className="clearfix"></div>
                                                        <span className="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="chat.html">
                                                <div className="list-item">
                                                    <div className="list-left">
                                                        <span className="avatar">T</span>
                                                    </div>
                                                    <div className="list-body">
                                                        <span className="message-author"> Tarah Shropshire </span>
                                                        <span className="message-time">12:28 AM</span>
                                                        <div className="clearfix"></div>
                                                        <span className="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="chat.html">
                                                <div className="list-item">
                                                    <div className="list-left">
                                                        <span className="avatar">M</span>
                                                    </div>
                                                    <div className="list-body">
                                                        <span className="message-author">Mike Litorus</span>
                                                        <span className="message-time">12:28 AM</span>
                                                        <div className="clearfix"></div>
                                                        <span className="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="chat.html">
                                                <div className="list-item">
                                                    <div className="list-left">
                                                        <span className="avatar">C</span>
                                                    </div>
                                                    <div className="list-body">
                                                        <span className="message-author"> Catherine Manseau </span>
                                                        <span className="message-time">12:28 AM</span>
                                                        <div className="clearfix"></div>
                                                        <span className="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="chat.html">
                                                <div className="list-item">
                                                    <div className="list-left">
                                                        <span className="avatar">D</span>
                                                    </div>
                                                    <div className="list-body">
                                                        <span className="message-author"> Domenic Houston </span>
                                                        <span className="message-time">12:28 AM</span>
                                                        <div className="clearfix"></div>
                                                        <span className="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="chat.html">
                                                <div className="list-item">
                                                    <div className="list-left">
                                                        <span className="avatar">B</span>
                                                    </div>
                                                    <div className="list-body">
                                                        <span className="message-author"> Buster Wigton </span>
                                                        <span className="message-time">12:28 AM</span>
                                                        <div className="clearfix"></div>
                                                        <span className="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="chat.html">
                                                <div className="list-item">
                                                    <div className="list-left">
                                                        <span className="avatar">R</span>
                                                    </div>
                                                    <div className="list-body">
                                                        <span className="message-author"> Rolland Webber </span>
                                                        <span className="message-time">12:28 AM</span>
                                                        <div className="clearfix"></div>
                                                        <span className="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="chat.html">
                                                <div className="list-item">
                                                    <div className="list-left">
                                                        <span className="avatar">C</span>
                                                    </div>
                                                    <div className="list-body">
                                                        <span className="message-author"> Claire Mapes </span>
                                                        <span className="message-time">12:28 AM</span>
                                                        <div className="clearfix"></div>
                                                        <span className="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="chat.html">
                                                <div className="list-item">
                                                    <div className="list-left">
                                                        <span className="avatar">M</span>
                                                    </div>
                                                    <div className="list-body">
                                                        <span className="message-author">Melita Faucher</span>
                                                        <span className="message-time">12:28 AM</span>
                                                        <div className="clearfix"></div>
                                                        <span className="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="chat.html">
                                                <div className="list-item">
                                                    <div className="list-left">
                                                        <span className="avatar">J</span>
                                                    </div>
                                                    <div className="list-body">
                                                        <span className="message-author">Jeffery Lalor</span>
                                                        <span className="message-time">12:28 AM</span>
                                                        <div className="clearfix"></div>
                                                        <span className="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="chat.html">
                                                <div className="list-item">
                                                    <div className="list-left">
                                                        <span className="avatar">L</span>
                                                    </div>
                                                    <div className="list-body">
                                                        <span className="message-author">Loren Gatlin</span>
                                                        <span className="message-time">12:28 AM</span>
                                                        <div className="clearfix"></div>
                                                        <span className="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="chat.html">
                                                <div className="list-item">
                                                    <div className="list-left">
                                                        <span className="avatar">T</span>
                                                    </div>
                                                    <div className="list-body">
                                                        <span className="message-author">Tarah Shropshire</span>
                                                        <span className="message-time">12:28 AM</span>
                                                        <div className="clearfix"></div>
                                                        <span className="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="slimScrollBar"
                                    style={{ background: "rgb(135, 135, 135)", width: "4px", position: "absolute", top: "0px", opacity: "0.4", display: "block", borderRadius: "0px", zIndex: "99", right: "1px", height: "815px" }}>

                                </div>
                                <div className="slimScrollRail"
                                    style={{ width: "4px", height: "100%", position: "absolute", top: "0px", display: "none", borderRadius: "7px", background: "rgb(51, 51, 51)", opacity: "0.2", zIndex: "90", right: "1px" }}>
                                </div></div>
                            <div className="topnav-dropdown-footer">
                                <a href="chat.html">See all messages</a>
                            </div>
                        </div>
                    </div>
                </div>
 
                <div className="sidebar-overlay " data-reff="#sidebar" onClick={(e) => menuSideToogleMovile_click()}></div>
            </div>

            



        </div>
    )
}