import React from 'react'
import './styles.css';
import NuSpinner  from '../NuSpinner';

const NuLoader =( { isLoading, msg}) => (

    <div >
        {/*<label>{props.labelName} :</label>*/}
        { isLoading &&
            <>
                <div className='datagrid-mask' style={{ display: "block" }}>
                </div>
                <div  className="datagrid-mask-msg" style={{ display: "block", left: "50%" }}>
                    {isLoading ?
                        <NuSpinner
                            color='#e12d6c'
                            size={30}
                        />
                        : ''
                    }
                    {msg}
                </div>
            </>
        }
        
    </div>
);
export default NuLoader;