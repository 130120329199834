
import { useNavigate } from "react-router-dom";
import {useParams} from 'react-router-dom';
//--- CONTEXT API -----------
import { useStore, useDispatch } from "store/StoreProvider";
import { types } from "store/StoreReducer";


export const withRouterJim = WrappedComponent => props => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const store = useStore();
  const params = useParams();
  // etc... other react-router-dom v6 hooks

  return (
    <WrappedComponent
      {...props}
      navigate={ navigate }
      dispatch={ dispatch }  /* context API */
      store={ store } /* context API */
      params={params}
      // etc...
    />
  );
};