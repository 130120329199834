import React from "react";
import axios2 from 'services/axios2';
import AuthService from "services/auth.service";

import {useParams} from "react-router-dom";
import {Link,hashHistory} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import  NuDropdown from "../../components/nubox/NuDropdown";
import  NuInput from "components/nubox/NuInput";
import cUtilFecha from "helpers/cUtilFecha"


import { NotificationContainer, NotificationManager } from 'react-notifications';
import {
  Table,
  Button,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  ModalFooter,
  ToastHeader,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

const cbo=[
  {  value:'1', label: 'label1'},
  {  value:'2', label: 'Clss'},
]
let formInitial = {
  idd:0,
  ope:'n',
  id_paciente: 0,  
  nombre :'',
  nro_doc:'',
  fecha_nac:'',    
  foto_path1:'',
  cel: '', 
  email:'',
  estado:1,
};

class PacienteModal extends React.Component {
  

  state = {
    modalInsertar:false,
    id_tra:0,  /*maestro */
   
    form: formInitial,
        
    prestaciones:[], /* cbo */
    
  };
  //------------------------------------------------------
  componentDidMount() {
    //console.log("componentDidMount Modal");
    //--limpiamos el array de prueba
    //this.setState({data:[]});
    
  }
  //---- retorna un formulario con valores iniciales
  limpiar = () =>{
    this.setState({form: formInitial});
  }
  //-------------------------
  cargar_form = (idd, ope) => {
    //context API
    console.log("cargar_form");   

    let param = { 
      idd: idd,
      ope:'f'
    }
    console.log(param); 
    axios2.post( "pacienteM_store", param )
    .then(res => {
      
        if(! this.isEmptyObject(res.data)){ 
            
            this.setState({form: res.data});
            //añadimos el id y ope
            this.setState({
              form: {  ...this.state.form, 
                idd: idd,  
                ope: ope,
              },
            });
            //---------------
        }else{
          toast.error("Registro no encontrado", {
            position: toast.POSITION.TOP_RIGHT
          });
        }

    }).catch(error => {
        alert("---- Existio un error ---");
        console.log(error);
        
    });
  };
  //------------ cambiamos la fecha
  /*handleChangeFechai=(event)=> {    
    this.setState({fechai: event.target.value}); 
  }*/
  //----------------especial, actualiza valores del form -------------------------------
  handleChangeForm = (e) => {
    //requerimos la prop name="?" en cada control
    
    //--- esto lo hacemos porque en vez de utilizar valores true, false
    //--- tenemos que utilizar 1, 0
    if(e.target.type==="checkbox"){
      //console.log("cheched:"+ e.target.checked);
      let res = e.target.checked? 1:0;
      //console.log("value:"+ res);
      
      this.setState({
        form:{
          ...this.state.form,
          [e.target.name]: res,
        }
      })
    }else{
      this.setState({
        form: {
          ...this.state.form,
          [e.target.name]: e.target.value,
        },
      });
    }
  };
  //-------------------------------------------------------------- 
  //======================================================
  showModal = () => {
    //setModalOpen(true);
    this.setState({ modalInsertar: true});
  }
  closeModal = () => {
    //setModalOpen(true);
    this.setState({ modalInsertar: false});
  }

  //--------------- 
  //--- hora, solo se puede utilizar una ves setStare en una funcion 
  //--- cuando se utiliza multiples veces, solo hace caso la ultima, tomar en cuenta
  nuevo =() =>{
    console.log("nuevo");
    
    //"fecha": cUtilFecha.fechaActualStr()
    this.showModal();
    
    this.setState({
      form: {
        ...formInitial,
        "idd": 0,
        "ope":'n',
      },
    });
    
    console.log(this.state.form);
    
  }
  //------------------------------------------------------
  editar =(idd)=>{
    console.log("editar:" + idd);
    let param = { 
      idd: idd,
      ope:'f'
    }
    console.log(param);
    axios2.post( "tradet_store", param).then(res => {
      console.log(res.data);

        if(res.data!==null){ 
            //NotificationManager.success("Correcto");
            this.setState({
              form: {
                ...res.data,
                "idd": idd,
                "ope": 'e',
              },
            });

            //----
            this.showModal();            
            
        }else{
          toast.error("No existe el registro", {
            position: toast.POSITION.TOP_RIGHT
          });
        }

    }).catch(error => {
        alert("---- Existio un error ---");
        console.log(error);
        
    });
  }

  eliminar =(idd)=>{
    console.log("editar:" + idd);
    let param = { 
      idd: idd,
      ope:'f'
    }
    console.log(param);
    axios2.post( "tradet_store", param).then(res => {
      console.log(res.data);

        if(res.data!==null){ 
            //NotificationManager.success("Correcto");
            this.setState({
              form: {
                ...res.data,
                "idd": idd,
                "ope": 'x',
              },
            });

            //----
            this.showModal();
            //this.disabled();
            
        }else{
          toast.error("No existe el registro", {
            position: toast.POSITION.TOP_RIGHT
          });
        }

    }).catch(error => {
        alert("---- Existio un error ---");
        console.log(error);
        
    });
  }
  //----cargamos los datos desde la base de datos
  /*[{ "value": 1, "label": "PERDIDO", "precio": 50, "color": "#fff"},    ]
  */
  cargar_form = async() => {
    let param = { }
    
    await axios2.post("cbo_producto", param).then(res => {
        //if(res.data.length>0){ 
            this.setState({prestaciones: res.data});            
        //}
    }).catch(error => {
        alert("---- Existio un error ---");
        console.log(error);
    });
  };
  //----------------------------
  
  //--------------------------------------
  validar = () =>{
    console.log("validar");
    console.log(this.state.form.fecha);
    if( this.state.form.fecha===''){
      toast.error( "Fecha no valida", {position: toast.POSITION.TOP_RIGHT});
      return true;
    }

    return false;
  }
  //-------------------------------------
  formSave = () => {
    // the item selected
    console.log("save");

    let error = this.validar();
    console.log(error);
    if(error===true){
      return;
    }
    //----------------------------
    const user = AuthService.getCurrentUser();
    let id_u = user.id_u;
    //------------------------

    let param = {...this.state.form,
                "id_u":id_u
                };
    console.log(param);

    axios2.post( "pacienteM_store_new", param).then(res => {
        let response = res.data;
        console.log(res.data);
        if(response.exito===1){ 
            
          toast.success( response.mensaje, {position: toast.POSITION.TOP_RIGHT});
          //---llamamos a la funcion externa
          this.closeModal();
          this.props.okModal(response.id_paciente); //callback
        }else{
            
          toast.error( response.mensaje, {position: toast.POSITION.TOP_RIGHT});
        }

    }).catch(error => {
        //if(error.response.status && error.response.status===400)
        alert("Bad Request - ");
        console.log(error);
        
    });

  }
  //-----------------------------------------------------------------------------------------------
  render() {
    const {form} = this.state;
    let clase ='';
    let titulo= '';
    let btnClass='primary';
    let btnText='Aceptar';
    switch(this.state.form.ope){
      case 'n': titulo='Nuevo Paciente'; clase='header-azul'; break;
      case 'e': titulo='Editar'; clase='header-naranja'; break;
      case 'x': titulo='Eliminar '; clase='header-rojo'; btnClass='danger'; btnText="Eliminar"; break;
      default: break;
    }
    return (
      <div>
        <ToastContainer />
        
        {/*--- modal---- */}
        <Modal isOpen={this.state.modalInsertar} toggle={this.closeModal} >
          <ModalHeader toggle={this.closeModal} className= { clase } 
          >
            <h4 className="modal-title">
            { titulo }
            </h4>
          </ModalHeader>

          <ModalBody style={{ background: "#F7F7F7"}}>
            
              <div>{ 
                //JSON.stringify(this.state.form) 
              } 
              </div>
              <br/>
              { JSON.stringify(this.props.id_tra)}
      
              <div className="row mb-2">
                <div className="col-5">
                  <b>Cod, paciente:</b>
                </div>
                <div className="col-7">
                  <input 
                    type="text"                           
                    className="text-left form-control" 
                    readOnly
                    name="id_paciente" 
                    value={this.state.form.id_paciente}
                    onChange={this.handleChangeForm}
                  />
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-5">
                  <b>Nombre:</b>
                </div>
                <div className="col-7">
                  <input 
                    type="text"                           
                    className="text-left form-control" 
                    style={{textTransform: "uppercase"}}
                    name="nombre" 
                    value={this.state.form.nombre}
                    onChange={this.handleChangeForm}
                  />
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-5">
                  <b>Nro doc:</b>
                </div>
                <div className="col-7">
                  <input 
                    type="text"                           
                    className="text-left form-control" 
                    name="nro_doc" 
                    value={this.state.form.nro_doc}
                    onChange={this.handleChangeForm}
                  />
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-5">
                  <b>Fecha nacimiento:</b>
                </div>
                <div className="col-7">
                  <input 
                    type="date"                           
                    className="text-left form-control" 
                    name="fecha_nac" 
                    value={this.state.form.fecha_nac}
                    onChange={this.handleChangeForm}
                  />
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-5">
                  <b>Telefono:</b>
                </div>
                <div className="col-7">
                  <input 
                    type="text"                           
                    className="text-left form-control" 
                    name="cel" 
                    value={this.state.form.cel}
                    onChange={this.handleChangeForm}
                  />
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-5">
                  <b>email:</b>
                </div>
                <div className="col-7">
                  <input 
                    type="email"                           
                    className="text-left form-control" 
                    name="email" 
                    value={this.state.form.email}
                    onChange={this.handleChangeForm}
                  />
                </div>
              </div>             
            
          </ModalBody>
          <ModalFooter>
            <Button color={ btnClass } onClick={ this.formSave}>{ btnText }</Button>
            {' '}
            <Button color="secondary" onClick={this.closeModal}>Cancelar</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
export default  PacienteModal;