
import React, { useRef, useEffect, useState } from "react";

import NuLoading from 'components/nubox/NuLoading';
import axios2 from 'services/axios2';

//import './nuCheckBox.css'
import {
  Table,
  Button,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  ModalFooter,
  ToastHeader,
} from "reactstrap";


const miselect={
    /*fontSize: "14pt",*/
    /*lineHeight: "1.5",*/
    border: "1px solid #ced4da",
    borderRadius: ".25rem",
    display: "block",
    width: "100%",
    padding: ".275rem .75rem",
}

const ImageUpload = ({ id, label, checked, ...props }) => {
    // 
    const inputFileRef = React.useRef();
    const [isloaging, setIsloaging] = useState(false);
    const [mensaje, setMensaje] = useState("");
    const [imageUrl, setImageUrl] = useState("");
    const [fileName, setFileName] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    
    function handleChange(event) {
      console.log(event.target.value);
    }
    //
    const chooseFile = () => {
      console.log("chooseFile");
      //uploadImage_Click();
      inputFileRef.current.click();
      //---------------
    }
    async function uploadImage_Change(event) {
      //---------------------
      const URL = "image_uploadPhoto";
      const data = new FormData();
      data.append("name", "my-picture");
      data.append("imageFile", event.target.files[0]);
      const config = {
          header: {
              "Content-Type": "image/png",
          },
      };

      
      setIsloaging(true);
      axios2.post(URL, data, config)
      .then(function (response) {
          console.log(response.data);
          //this.availabilityMessage = response.data.message;
          setIsloaging(false);

          setMensaje( response.data.mensaje );
          let aux = process.env.REACT_APP_AFILES + response.data.filename;
          setImageUrl(aux);
          setFileName( response.data.filename);

      }).catch(function (error) {
          // this.availabilityMessage = false;
          console.log(error);
      });
  }
  //-------------------------------------------
  function aceptar_click() {
    props.okModal(fileName);
  }
  function cancelar_click() {
    props.closeModal();
  }
    return (
    <>
      <div className="container">
        <div className=" previewBlock profile-user-img img-responsive img-circle1"
          onClick={chooseFile}
          style={{  backgroundImage: `url(${imageUrl})`, height: "150px", width: "150px", 
                    backgroundSize: "contain", backgroundRepeat: "no-repeat" }}>
        </div>

        <br />
        
        <div>
          <input
            className="form-control form-control-lg"
            type="file"
            ref={inputFileRef}
            value={selectedFile}
            onChange={(e) => uploadImage_Change(e)}
            style={{display: "none" }}
          />

          <div className="form-group">
            <div className="bootstrap-filestyle input-group">

                <input 
                  value={fileName}
                  onChange={chooseFile} 
                  type="text" 
                  className="form-control " 
                  placeholder="" 
                  readOnly/>

                <span className="group-span-filestyle input-group-btn" tabIndex="0">
                  <label className="btn btn-info " onClick={ chooseFile }>
                  <span className="icon-span-filestyle glyphicon glyphicon-folder-open"></span>
                  <span className="buttonText"> Seleccionar archivo</span>
                </label>
              </span>
            </div>
          </div>
         
         {/*<!-- -->*/}
         <NuLoading text="Cargando...." show= { isloaging }/>
          {/*<!-- -->*/}
          <span>{ mensaje }</span>
          {/*<!-- -->*/}
          <div className="flex " style={{ padding: "0.75rem 1.25rem", textAlign: "right" }}  >
            <Button autoFocus onClick={ aceptar_click } className="btn btn-primary" > Aceptar</Button>
            &nbsp;
            <Button className="" onClick={ cancelar_click } > Cancelar</Button>
          </div>


        </div>
      </div>
    </>
    );
  }
  
  export default ImageUpload;


