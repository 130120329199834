import React from "react";
import './styles.css';
/*const techCompanies = [
    { value: 1, label: "Apple",  icon:'fa fa-home', color:'#ccc' },
    { value: 2, label: "Facebook", icon:'fa fa-circle', color:'#abc' },
];*/

// Reusable Dropdown
class NuListCheck extends React.Component { 
    // selectValue => current selected value
  
    state = {
      checkedArr: [],/* lista de seleccioados */
    }
    
    // Return classes based on whether item is checked ----------------------
    isChecked = (item) =>{
      return this.state.checkedArr.includes(item) ? "checked-item" : "not-checked-item";
    }

    // Add/Remove checked item from list ------------------------------------
    handleCheck = (event) => {
      let updatedList = [...this.state.checkedArr];
      if (event.target.checked) {
        updatedList = [...this.state.checkedArr, event.target.value];
      } else {
        //--lo quitamos de la lista
        updatedList.splice(this.state.checkedArr.indexOf(event.target.value), 1);
      }
      //---actualizamos 
      //setChecked(updatedList);
      this.setState({checkedArr: updatedList});
    };

    // Generate string of checked items --------------------------------------
    ToString = () => {
      const checkedItems = this.state.checkedArr.length
      ? this.state.checkedArr.reduce((total, item) => {
          return total + ", " + item;
        })
      : "";
      return checkedItems;
    }
    // ------------------------------------------------------------------------
    onClickItem = (e,item) => {
      e.target.name = this.props.name;
      e.target.value = item.value;
      this.props.onChange(e);
    }; 
    //=======================================
    render() {
       const { name, value, options } = this.props;

       //const miClassName = `nu-sidenav_item ${ value===item.value? "menu-item--active":'' }`;
       
       return (
        <nav className="side-menu">
          { 
            //JSON.stringify( this.state.checkedArr ) 
          }
          {this.props.titulo?
          <h3>{this.props.titulo}</h3>
          :""
          }
          <ul className="nu-sidenav">
              {options.map( (item, index) => {
                return (
                  <li key={ index } 
                        className={ `nu-sidenav_item ${ value===item.value? "menu-item--active":'' }` }
                      >
                    <div 
                          title={item.label} 
                      >
                        
                      { item.icon!=""?
                        <>
                        <i className={ item.icon} style={{color: item.color }}></i>&nbsp;&nbsp;
                        </> :""
                      }

                     

                      <label  style={{ fontWeight:"normal", cursor: "pointer" }}
                              className={ this.isChecked(item) }
                      >
                          <input  type="checkbox" 
                              id={item.value}
                              value={item.value}
                              onChange={ this.handleCheck } 
                          />&nbsp;&nbsp;
                      {item.label}</label>
                      
                    </div>
                  </li>
                );
              })}
          </ul>
        </nav>
        );
     }
};
export default NuListCheck;