import { useEffect, useState, useMemo } from "react";

import NuPaginationItem from '../NuPaginationItem'
import { usePagination, DOTS } from './usePagination';
import './styles.css';

const NuPagination=({totalCount=0, pageSize=10, siblingCount = 1, currentPage=1, onPageChange })=>{

    const [totalPages, setTotalPages]= useState(0);

    useEffect( ()=>{
        if(totalCount >0 && pageSize >0){
            setTotalPages(Math.ceil(totalCount / pageSize));
        }
    },[totalCount, pageSize]);
    //-----------------------------
    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize
    });
    // If there are less than 2 times in pagination range we shall not render the component
    if (currentPage === 0 || paginationRange.length < 2) {
        return null;
    }
    let lastPage = paginationRange[paginationRange.length - 1];
    //---------------------------------------------------------
   

    if(totalPages===0) return null;
    //==============================================RENDER 
    return(
        <ul className="pagination pull-right">
            {/*<NuPaginationItem 
                onClickItem={() => onPageChange(1)}
                text={1}
                disabled={currentPage === 1 } 
                icon="fa fa-angle-double-left"
            />*/}
            <NuPaginationItem
                key={-1}
                onClickItem={() => onPageChange(currentPage - 1)}
                text={currentPage-1}
                disabled={currentPage === 1} 
                icon="fa fa-angle-left"
            />
            { /*paginationItems*/}
            {paginationRange.map( (pageNumber,index) => {
         
                // If the pageItem is a DOT, render the DOTS unicode character
                if (pageNumber === DOTS) {
                    return <li key={index} className="pagination-item dots">&#8230;</li>;
                }
                
                // Render our Page Pills
                return (
                <NuPaginationItem 
                    key={index}
                    text={pageNumber} 
                    active={pageNumber===currentPage} 
                    onClickItem={() => onPageChange(pageNumber)}
                />
                );
            })}
            
            <NuPaginationItem 
                key={-2}
                onClickItem={() => onPageChange(currentPage + 1)}
                text={currentPage + 1}
                disabled={currentPage === totalPages } 
                icon="fa fa-angle-right"
            />
            {/*<NuPaginationItem 
                onClickItem={() => onPageChange(totalPages)}
                text={totalPages}
                disabled={currentPage === totalPages } 
                icon="fa fa-angle-double-right"
            />*/}
        </ul>
    )
}

export default NuPagination;

