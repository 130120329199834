/*****************************************************/
let rules = {
  required: { test: function (e) { return (e.length > 0) } }, //new RegExp(/\S/),
  email: new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i),
  date: new RegExp(/^((((0?[1-9]|[12]\d|3[01])[\.\-\/](0?[13578]|1[02])[\.\-\/]((1[6-9]|[2-9]\d)?\d{2}))|((0?[1-9]|[12]\d|30)[\.\-\/](0?[13456789]|1[012])[\.\-\/]((1[6-9]|[2-9]\d)?\d{2}))|((0?[1-9]|1\d|2[0-8])[\.\-\/]0?2[\.\-\/]((1[6-9]|[2-9]\d)?\d{2}))|(29[\.\-\/]0?2[\.\-\/]((1[6-9]|[2-9]\d)?(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00)|00)))|(((0[1-9]|[12]\d|3[01])(0[13578]|1[02])((1[6-9]|[2-9]\d)?\d{2}))|((0[1-9]|[12]\d|30)(0[13456789]|1[012])((1[6-9]|[2-9]\d)?\d{2}))|((0[1-9]|1\d|2[0-8])02((1[6-9]|[2-9]\d)?\d{2}))|(2902((1[6-9]|[2-9]\d)?(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00)|00))))$/), //  format dd/mm/yyyy
  hour: new RegExp(/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/),
  int: new RegExp(/[+-]?\d+$/),
  positive_int: new RegExp(/\d+$/),
  float: new RegExp(/^([+-]?(((\d+(\.)?)|(\d*\.\d+))([eE][+-]?\d+)?))$/),
  number: { test: function (e) { return !isNaN(e) } },
  string: new RegExp(/^([\w]|[\W]|[\s\/])+$/i),
  string2: new RegExp(/^([a-z]|[A-Z]|[0-9]|[\s\/]|[\.\,\-ÁÉÍÓÚáéíóúÑñ;:¡!·#$%&@()=_?¿º\\])+$/i),
  //equaltoxxx: { test: function (e) { return (e == $("#xxx").val()) } }  // ejemplo pocra comprobar igualdad
};
/************************************************************* */
/************************************************************* */
let descrip = {
  required: " Campo obligatorio",
  email: "E-mail incorrecto",//correo electronico
  date: "La fecha requiere formato dd/mm/yyyy",  //  dd/mm/yyyy
  date_m: "La fecha requiere formato dd/mm/yyyy",  //  YYYY-MM-DD
  hour: "La hora requiere formato HH:MM",//HH:mm
  int: "Requiere un número entero",//numeros enteros
  positive_int: "Requiere número positivo",//numero entero positivo
  float: "Requiere número decimal",//numeros decimales
  number: "Requiere número", //numero 
  string: "Requiere letras y numeros", //letras y numeros
  string2: "Requiere una cadena",//letras y numeros y simbolos
};
/**************************************************************/
/**valtipo */

const validacion = (value, valtipo) => {
  let valid = true;
  let men_err ="";
  console.log("valtipo->:" + valtipo);
  
  value = (value == undefined) ? '' : value;

  if  ( value.length > 0 )  {
    let indexr=valtipo;  
    
    //--- verificamos si es correcto su configuracion
		switch (valtipo) {
			case "email":  
			case "date":  
			case "date_m":
			case "hour": 
			case "int":  
			case "positive_int": 
			case "float": 
			case "number": break;
			case "string": 
			case "string2": //letras y numeros y simbolos
      break;
			default:
				  //ninguno de los valores coincide con el valor de la expresión
          return "Err3: Falta propiedad valtipo";
			  	break;
		}
    console.log("indexr:" +indexr);
		//------------------aplicamos la expresion regular
		valid = rules[indexr].test(value);
    if(valid==false){
      men_err = descrip[indexr];
    }
		
    return men_err;

	} 
}
export default validacion;
