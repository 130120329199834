import React from "react";
import axios from 'axios';
import axios2 from 'services/axios2';

import {useParams} from "react-router-dom";
import {Link,hashHistory} from 'react-router-dom';
import { useNavigate } from "react-router-dom";

import { ToastContainer, toast } from 'react-toastify';
import { NuDropdown } from "../../components/nubox/NuDropdown";
import  NuAutocomplete  from "../../components/nubox/NuAutocomplete";
import  Autocomplete  from "../../components/nubox/Autocomplete";
import confirmService from 'components/confirmService';

import { withRouterJim } from "router/withRouterJim";

//---cutil
import cUtilFecha from "helpers/cUtilFecha"
//--- CONTEXT API -----------
import { useStore, useDispatch } from "store/StoreProvider";
import { types } from "store/StoreReducer";


import { NotificationContainer, NotificationManager } from 'react-notifications';
import {
  Table,
  Button,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  ModalFooter,
  ToastHeader,
} from "reactstrap";


//type:'numeric'
const columnas=[
    {  field:'id', title: 'Cod. Cert.', type:'numeric'},
    {  field:'cliente', title: 'Cliente'},
    {  field:'nombre', title: 'Nombre'},
    {  field:'lote', title: 'Lote'},
    {  field:'elem', title: 'Elementos'},
];
const cbo=[
  {  value:'1', label: 'label1'},
  {  value:'2', label: 'Clss'},
  {  value:'3', label: 'Nod'},
  {  value:'4', label: 'Lod'},
  {  value:'5', label: 'Elggg'},
]

const data = [
  { id_paciente: 1,  nombre: "ALEX LIZARRAGA",  nro_doc: "00000023",  fecha_nac: "2022-01-13",  foto_path1: "1665435807.jpg",  dir: "Cerro Bola #319",  cel: "0123456789"},
  { id_paciente: 2,  nombre: "MARIANELA",  nro_doc: "00000023",  fecha_nac: "2022-01-13",  foto_path1: "1665435807.jpg",  dir: "Cerro Bola #319",  cel: "0123456789"},
 
];

//$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
class ProductoList extends React.Component {
  state = {

    modalActualizar: false,
    modalInsertar: false,
    form: {
      name: '',
    },
    data: data,/* lista de citas */
    
  };
  
  //------------------------------------------------------
  componentDidMount() {
    //--limpiamos el array de prueba
    this.setState({data:[]});

    const date = new Date();
    const defaultValue = date.toLocaleDateString('en-CA');
  
    //this.setState({fechai: defaultValue}); 
    this.setState({ form:{...this.state.form, fecha: defaultValue,}}); 

    this.cargar_productos();
    
    //this.cargar_citas();
    //this.fetchData(this.state.fechai);
  }

  //------------ cambiamos la fecha
  /*handleChange = event =>{
    this.setState({ name: event.target.value});
  }*/
  //----------------especial, actualiza valores del form -------------------------------
  handleChangeForm = (e) => {
    //requerimos la prop name="?" en cada control
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      },
    });
  };
  
  //====================================================== 
  //----cargamos los datos desde la base de datos
  /*{
    "id_paciente": 1,
    "nombre": "ALEX LIZARRAGA",
    "nro_doc": "00000023",
    "fecha_nac": "2022-01-13",
    "foto_path1": "1665435807.jpg",
    "dir": "Cerro Bola #319",
    "cel": "0123456789"
  },*/
  cargar_productos = () => {
    let param = { 
      param1: this.state.form.name
    }
    
    axios2.post("productocrud_lista", param)
    .then(res => {
        if(res.data.length>0){ 
            //NotificationManager.success("Correcto");
            this.setState({data: res.data});
            //console.log(this.state.medicos);
        }else{
            NotificationManager.error("No existen datos");
        }

    }).catch(error => {
        alert("---- Existio un error ---");
        console.log(error);
        
    });
  };
  //--------------------------------------
  imprimir =(id_certi)=>{
    let fileURL = process.env.REACT_APP_API_URL  + "certificado_reciboPDF/"+ id_certi;
    let  windowFeatures = 'width=650,height= 700, left=100,top=100 ';
    window.open(fileURL,'',windowFeatures);
  }
  //--------------------------------------
  eliminar = (item) =>{
    console.log("eliminar");
    console.log(item);
    //--------------------------------
    confirmService.show({
      message: 'Esta seguro de eliminar?',
      title: 'Confirmar',
      icon: 'fa fa-question-circle',
      classHeader: 'bg-default1 ',
      classButton: 'btn btn-danger',
      accept: () => {
        let idd = item.id_prod;
        this.delete(idd);
      },
      reject: () => {
          console.log('cancel');
      },
      acceptLabel: 'Aceptar',
      rejectLabel: 'Cancelar'
    })

  }
  //---------------------------------------
  /* === This is where the magic happens ==== */
  handleSubmit = event => {
    event.preventDefault();
    this.cargar_productos();
  }
  //-------------
  onLinkClick = (e,item) => {
    e.preventDefault();
    //--------primero actualizamos datos del paciente en el 
    //--- context api
    let obj = { "id_paciente": item.id_paciente,
                "nombre": item.nombre,
                "foto_path1": item.foto_path1,
                "fecha_nac": item.fecha_nac,
              };
    this.props.dispatch({type: types.setPaciente, payload: obj }); 
    //---do your stuff---
    
    this.props.navigate('/paciente_show/e/'+ item.id_paciente);
    
  };
  //--------------------
  delete =(idd) =>{
      let param = {idd: idd, ope: 'x'};
      //console.log(param);
      axios2.post( "productocrud_store", param)
      .then(res => {
          if(res.data.exito === 1){ 
              toast.success(res.data.mensaje);
              //redireccionamos a la lista
              this.cargar_productos();
          }else{
              toast.error(res.data.mensaje);
          }

      }).catch(error => {
          alert("---- Existio un error ---");
          console.log(error);  
      });
    
  }
  //-------------------------------------
  render() {
    
    return (
      <div>
        <ToastContainer />
        <div className="content">

          {/*<!--- ------------------------------->*/}
          <div className="row">
            <div className="col-md-12">
              <h3> Lista de servicios

                <div className="btn-group pull-right">

                  <Link to= { "/producto/n/0" } className="create-modal btn btn-info " >
                    <i className="fa fa-plus"></i> Nuevo
                  </Link>
                </div>
              </h3>


              <div className="box box-info">
                {/*<!-- /.box-header -->*/}
                <div className="box-body pad">

                  <form onSubmit = { this.handleSubmit }>
                    
                    <div className="row">

                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="control-label">Busqueda por nombre:</label>
                          <input type="text" name = "name" onChange= {this.handleChangeForm} className="form-control input-sm" />
                        </div>
                      </div>

                      <div className="col-md-2">
                        <button type="submit" style={{ marginTop: "24px" }} className="btn btn-success ">
                          <i className="fa fa-binoculars"></i> Buscar</button>

                      </div>

                      <div id="divres1"></div>
                    </div>
                  </form>

                </div>
                {/*<!-- /.box-body -->*/}
              </div>


              {/*<!-- box table -->*/}
              <div className="box">
                <div className="box-header">
                  <br/>
                    <div className="col-md-12">


                      <div className="table-responsive">
                        <table className="table tabla_azul0 table-striped table-hover ">
                          <thead>
                            <tr>
                            
                              <th>Cod.Prod</th>
                              <th>Nombre</th>
                              <th>Precio</th>
                              <th>Color</th>

                              <th className="text-center" style={{ width:"150px" }}>
                                Acción
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                        
                            {this.state.data.map((item) => (
                              <tr>
                                <td>{ item.id_prod }</td>
                                <td>{item.nombre_p}</td>
                                <td>{item.preciov1}</td>
                                <td>
                                  <span style={{ backgroundColor: item.color, width: "20px", display: "block",height: "20px" }}></span>
                                </td>
                                
                                <td style={{ textAlign: "center" }}>
                                  <div className="btn-group">
                                    <Link to= { "/producto/e/" + item.id_prod  } title="Editar" className="btn btn-warning btn-sm">
                                      <i className="fa fa-edit"></i>
                                    </Link>
                                    &nbsp;
                                    <Button className="btn btn-danger btn-sm" title="Eliminar" onClick={ ()=> this.eliminar(item) }   >
                                      <i className="fa fa-trash"></i> 
                                    </Button>
                                  </div>
                                </td>
                              </tr>
                            ))}
                             

                          </tbody>
                          
                          </table>
                        <div className="pull-left">

                        </div>
                      </div>

                    </div>


                </div>
              </div>
              {/*<!-- fin box table -->*/}

            </div>

          </div>
          {/*<!--- ----------------- form busqueda ------------------>*/}

          {/*<!-- -------------------------------------------------->*/}

        </div>
      </div>
    );
  }
}
  
//------------para que soporte la funcionalidad de parametros
export default withRouterJim(ProductoList);