import React from "react";
import axios2 from 'services/axios2';

import {useParams} from "react-router-dom";
import {Link,hashHistory} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import NuDropdown  from "../../components/nubox/NuDropdown";
import ImageUploadSingle from "components/nubox/ImageUploadSingle";

//import listac from '../assets/img/lista2.png';


import {
  Table,
  Button,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  ModalFooter,
  ToastHeader,
} from "reactstrap";

//type:'numeric'

const cbo=[
  {  value:'1', label: 'label1'},
  
]


const formInitial = {
  idd:0,
  ope:'n',
  id_medico: 0,      
  id_espe: 0,      
  fecha_reg: null,
  nro_doc:0,
  tel:'',
  cel:'',
  dir:'',
  foto_path1: 0,
  email:'',
  changePass_sn: false,
  password :'',
};
//$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
//-- la parte de mostrar y ocultar el modal, es manejada externamente
class ModalMedico extends React.Component {
  state = {
    modalInsertar:false,
    
    form: formInitial,

    especialidades:[],
    horas:[],
  };
  
  //------------------------------------------------------
  componentDidMount() {
    console.log("componentDidMount Modal");
    //--limpiamos el array de prueba
    this.setState({data:[]});

    const date = new Date();
    const defaultValue = date.toLocaleDateString('en-CA');
  
    //this.setState({fechai: defaultValue}); 
    this.setState({ form:{...this.state.form, fecha: defaultValue,}}); 

    this.cargar_especialidad();
   
    //this.cargar_citas();
    //this.fetchData(this.state.fechai);
    //----------- limpiamos el form -------------

    //---------si viene la propiedad true, mostramos la informacion
    this.limpiar();

  }

  limpiar = () =>{
     this.setState({form: formInitial});
  }
  //------------ cambiamos la fecha
  /*handleChangeFechai=(event)=> {    
    this.setState({fechai: event.target.value}); 
  }*/
  //----------------especial, actualiza valores del form -------------------------------
  handleChange = (e) => {
    //requerimos la prop name="?" en cada control
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleChangeForm = (e) => {
    console.log("name:"+ e.target.name + " value:"+ e.target.value);
    if(e.target.value){
      console.log("true");
    }else{ console.log("false"); } 
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      },
    });

  };
  handleChangeCheck = (e) => {
    //requerimos la prop name="?" en cada control
    //--- esto lo hacemos porque en vez de utilizar valores true, false
    //--- tenemos que utilizar 1, 0
    //console.log("cheched:"+ e.target.checked);
    let res = e.target.checked? 1:0;
    //console.log("value:"+ res);
    
    this.setState({
      [e.target.name]: res,
    })
  };
  handleChangeFormCheck = (e) => {
    //requerimos la prop name="?" en cada control
    //--- utilizamos valores true, false
    
    console.log("cheched:"+ e.target.checked);
   
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.checked,
      },
    });
  };
 
  //======================================================
  //======================================================
  showModal = () => {
    //setModalOpen(true);
    this.setState({ modalInsertar: true});
  }
  closeModal = () => {
    //setModalOpen(true);
    this.setState({ modalInsertar: false});
  }
  asigna_form1_val=(name,value)=>{
    this.setState({
      form: {
        ...this.state.form,
        name: value,
      },
    });
  }
  //---------------
  nuevo =() =>{
    console.log('nuevo');
    this.limpiar();
    
    this.setState({
      
      modalInsertar: true,
    });
  }
  //---------------
  editar =(idd)=>{
    console.log('editar');
    let param = { 
      idd: idd,
      ope:'f'
    }
    console.log(param);
    axios2.post("medicocrud_store", param).then(res => {
      console.log(res);
        if(res.data !== null){ 
            
            console.log(res.data);
            //---asignamos los valores
            this.setState({
              form: {
                ...res.data,
                "idd": idd,
                "ope": 'e',
                "changePass_sn":false,
              },
              modalInsertar: true, /* show modal */
            });

            //----
        }else{
          toast.error("No existen datos");
        }

    }).catch(error => {
        alert("---- Existio un error ---");
        console.log(error);
        
    });
  }

  eliminar =(idd)=>{
    console.log("eliminar");
    let param = { 
      idd: idd,
      ope:'x'
    }
    //console.log(param);
    axios2.post( "medicocrud_store", param).then(res => {
      console.log(res.data);

        if(res.data.exito===1){ 
            
            this.limpiar();
            this.closeModal();
            toast.success("Eliminado con exito");
            this.props.okModal(); //callback
        }else{
          toast.error("Error al eliminar registro", {
            position: toast.POSITION.TOP_RIGHT
          });
        }

    }).catch(error => {
        alert("---- Existio un error ---");
        console.log(error);
        
    });
  }
  //----cargamos los datos desde la base de datos
  cargar_especialidad = async() => {
    let param = { 
    }
    
    await axios2.post( "cbo_especialidad", param).then(res => {
        if(res.data.length>0){ 
            //NotificationManager.success("Correcto");
            this.setState({especialidades: res.data});
            //console.log(this.state.especialidades);
            
        }else{
          toast.error("No existen datos");
        }

    }).catch(error => {
        alert("---- Existio un error ---");
        console.log(error);
        
    });
  };
 
  okImage = (filename) =>{
        
    this.setState({ form:{
      ...this.state.form, 
      foto_path1: filename
    }}); 
  }
  cancelImage = () =>{
    
    //---ocultamos la ventana
    this.setState({ form:{...this.state.form, foto_path1: '' }}); 
  }

  validar = () =>{
    if( this.state.form.id_espe===0){
      toast.error( "Especialidad no valido", {position: toast.POSITION.TOP_RIGHT});
      return true;
    }
  }
  //-------------------------------------
  formSave = () => {
    // the item selected
    console.log("save");

    let error = this.validar();
    if(error==true){
      return;
    }
    
    
    let param = this.state.form;
    console.log("form save");
    console.log(param);

    axios2.post(process.env.REACT_APP_API_URL  + "medicocrud_store", param).then(res => {
        let response = res.data;
    

        if(response.exito===1){ 
            
          toast.success( response.mensaje, {position: toast.POSITION.TOP_RIGHT});
          //---llamamos a la funcion externa
          this.props.okModal();
          this.closeModal();
        }else{

          toast.error( response.mensaje, {position: toast.POSITION.TOP_RIGHT});
        }

    }).catch(error => {
        //if(error.response.status && error.response.status===400)
        alert("Bad Request");
        console.log(error);
        
    });

  }
  
  render() {
    
    return (
      <div>
        <ToastContainer />

        {/*--- modal---- */}
        <Modal isOpen={this.state.modalInsertar} toggle={this.closeModal} >
          <ModalHeader toggle={this.closeModal}
            className={(this.state.form.ope === 'n' ? "header-verde" : "header-naranja") + " " + (this.state.form.ope === 'n' ? "color:'#45b545'" : "color:'#343b43'")}
          >
            <h4 className="modal-title">
              {this.state.form.ope === 'n'
                ? "Nuevo"
                : "Editar"
              }
            </h4>
          </ModalHeader>

          <ModalBody style={{ background: "#F7F7F7" }}>
            {  //JSON.stringify(this.state.form) 
            }
            <form method='POST'>

              <div className="row">
                <div className="col-md-8">
                  <div className='form-group row'>
                    <label htmlFor='id_cita' className='col-md-6 control-label'>
                      <b>Cod. médico</b>
                    </label>
                    <div className='col-md-6'>
                      <input
                        name='id_medico'
                        type='text'
                        className='form-control'
                        value={this.state.form.id_medico}
                        onChange={this.handleChangeForm} readOnly />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-8">
                  <div className="form-group">
                    <label className="control-label">Nombre:</label>
                    <input
                      type="text"
                      name="nomape"
                      value={this.state.form.nomape}
                      onChange={this.handleChangeForm}
                      className="form-control"
                    />
                  </div>
                </div>

              </div>

              <div className="row">
                <div className="col-md-8">
                  <div className="form-group">
                    <label className="control-label">Especialidad:
                      <span id=""></span>
                    </label>

                    <NuDropdown
                      options={this.state.especialidades}
                      onChange={this.handleChangeForm}
                      value={this.state.form.id_espe}
                      name="id_espe"
                    />


                  </div>
                </div>
              </div>

              <div className="row">

                <div className="col-md-4">
                  <div className="form-group">
                    <label className="control-label">Email.:</label> 
                    <input
                      type="email"
                      name="email"
                      value={this.state.form.email}
                      onChange={this.handleChangeForm}
                      className="form-control"
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group">
                    <label className="control-label">Telf.:</label>
                    <input
                      type="text"
                      name="tel"
                      value={this.state.form.tel}
                      onChange={this.handleChangeForm}
                      className="form-control"
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group">
                    <label className="control-label">Movil.:</label>
                    <input
                      type="text"
                      name="cel"
                      value={this.state.form.cel}
                      onChange={this.handleChangeForm}
                      className="form-control"
                    />
                  </div>
                </div>

              </div>

              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="control-label">Nro Doc.:</label>
                    <input
                      type="text"
                      name="nro_doc"
                      value={this.state.form.nro_doc}
                      onChange={this.handleChangeForm}
                      className="form-control"
                    />
                  </div>
                </div>

                <div className="col-md-8">
                  <div className="form-group">
                    <label className="control-label">Dirección:</label>
                    <input
                      type="text"
                      name="dir"
                      value={this.state.form.dir}
                      onChange={this.handleChangeForm}
                      className="form-control"
                    />
                  </div>
                </div>

              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="control-label">Foto</label>
                    <ImageUploadSingle
                      okImage={this.okImage}
                      cancelImage={this.cancelImage}
                      imageURL={process.env.REACT_APP_AFILES}
                      fileName={this.state.form.foto_path1}
                      withImage="150px"
                    />
                  </div>
                </div>

              </div>

              <hr />

              <div className="row"> 
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="control-label">Información de usuario</label>
                    <br/>

                    <label style={{ fontWeight: "normal" }}>
                      <input
                        type="checkbox"
                        name="changePass_sn"
                        checked={ this.state.form.changePass_sn }
                        onChange={this.handleChangeFormCheck}  
                      />&nbsp;Cambiar contraseña
                    </label>

                    <input className="form-control"
                      type="password" 
                      name="password"
                      value={ this.state.form.password }
                      onChange={ this.handleChangeForm }
                      disabled = {(!this.state.form.changePass_sn)? "disabled" : ""}
                      autocomplete="off"
                    />

                  </div>
                </div>

              </div>


          </form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.formSave}>Guardar</Button>{' '}
          <Button color="secondary" onClick={this.closeModal}>Cancel</Button>
        </ModalFooter>
      </Modal>
      </div >
    );
  }
}
  
//------------
export default ModalMedico;