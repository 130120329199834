import * as React from "react";
import './styles.css';
import validacion from "helpers/validacion"
//import Errors from "../Errors";
//import Icon from "../Icon";
//-----------------------------------------------------------------------
const NuInputEdit = ({
  
  className,
  containerStyle,
  errors,
  disabled,
  icon,
  inputStyle,
  name,
  onChange,
  placeholder,
  readOnly,
  required,
  type,
  value,
  wrapperStyle,
  valtipo='',
  classError='',
  onOkInput,  /* evento al hacer enter */
}) => {
  const inputRef = React.useRef(null);

  const [error,setError] = React.useState("");
  const [isShow, setIsShow] = React.useState(false);

  const toggle= () =>setIsShow(!isShow);

  const handleClick = () => {
    if (inputRef && inputRef.current) inputRef.current.focus();
  };
  
  const handleChange = (e) => {
    //---- lo validamos
    //---si es correcto retorna cadena vacia
    //---sino, retorna el error
    let cad = validacion(e.target.value, valtipo );
    setError(cad);
    //---llamamos al obChange del padre
    onChange(e);
  };

  //-----------------------------------------
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      // 👇 Get input value
      setIsShow(false);
      onOkInput(event);
    }
  };

  return (
    <div className={containerStyle} style={wrapperStyle}>
      <div onClick={handleClick} className="" style={{ display: "flex" }}>
        <span style={{display : isShow? 'none':'block' }}>{value}</span>
        <input
          ref={inputRef}
          aria-label={name}
          data-testid={name}
          tabIndex={0}
          type={type}
          name={name}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
          value={value}
          style={{display : isShow? 'block':'none' }}
          disabled={disabled}
          readOnly={readOnly}
          className= {` ${className} ${error===""? '': classError}` }
          valtipo={valtipo}
          
        />
        &nbsp;
        <i className="fa fa-pencil" style={{color: "#0d6efd"}} onClick={ toggle } ></i>
      </div>
      { !value && required && (
        <div data-testid="errors">Requerido!</div>
      )}
      { error!=='' &&
        <div className="text-danger">{ error }</div>  
      }
    </div>
  );
};


export default NuInputEdit;
