import React from "react";
import axios from 'axios';
import axios2 from 'services/axios2';

import {useParams} from "react-router-dom";
import {Link,hashHistory} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { NuDropdown } from "../../components/nubox/NuDropdown";
import  NuAutocomplete  from "../../components/nubox/NuAutocomplete";
import  Autocomplete  from "../../components/nubox/Autocomplete";

import user from 'assets/img/user.jpg';

import { NotificationContainer, NotificationManager } from 'react-notifications';
import {
  Table,
  Button,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  ModalFooter,
  ToastHeader,
} from "reactstrap";


//type:'numeric'
const columnas=[
    {  field:'id', title: 'Cod. Cert.', type:'numeric'},
    {  field:'cliente', title: 'Cliente'},
    {  field:'nombre', title: 'Nombre'},
    {  field:'lote', title: 'Lote'},
    {  field:'elem', title: 'Elementos'},
];
const cbo=[
  {  value:'1', label: 'label1'},
  {  value:'2', label: 'Clss'},
  {  value:'3', label: 'Nod'},
  {  value:'4', label: 'Lod'},
  {  value:'5', label: 'Elggg'},
]

const data = [
  { id_paciente: 1,  nombre: "ALEX LIZARRAGA",  nro_doc: "00000023",  fecha_nac: "2022-01-13",  foto_path1: "1665435807.jpg",  dir: "Cerro Bola #319",  cel: "0123456789"},
  { id_paciente: 2,  nombre: "MARIANELA",  nro_doc: "00000023",  fecha_nac: "2022-01-13",  foto_path1: "1665435807.jpg",  dir: "Cerro Bola #319",  cel: "0123456789"},
 
];

//$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
class Consultas extends React.Component {
  state = {

    modalActualizar: false,
    modalInsertar: false,
    form: {
      name: 0,
    },
    data: data,/* lista  */
    
  };
  
  //------------------------------------------------------
  componentDidMount() {
    //--limpiamos el array de prueba
    this.setState({data:[]});

    const date = new Date();
    const defaultValue = date.toLocaleDateString('en-CA');
  
    //this.setState({fechai: defaultValue}); 
    this.setState({ form:{...this.state.form, fecha: defaultValue,}}); 

    this.cargar_consultas();
    
    //this.cargar_citas();
    //this.fetchData(this.state.fechai);
  }

  //------------ cambiamos la fecha
  /*handleChange = event =>{
    this.setState({ name: event.target.value});
  }*/
  //----------------especial, actualiza valores del form -------------------------------
  handleChangeForm = (e) => {
    //requerimos la prop name="?" en cada control
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      },
    });
  };
  
  //====================================================== 
  //----cargamos los datos desde la base de datos
  /*{
    "id_paciente": 1,
    "nombre": "ALEX LIZARRAGA",
    "nro_doc": "00000023",
    "fecha_nac": "2022-01-13",
    "foto_path1": "1665435807.jpg",
    "dir": "Cerro Bola #319",
    "cel": "0123456789"
  },*/
  cargar_consultas = () => {
    let param = { 
      param1: this.state.form.name
    }
    
    axios2.post(process.env.REACT_APP_API_URL  + "pacientecrud_lista", param)
    .then(res => {
        if(res.data.length>0){ 
            //NotificationManager.success("Correcto");
            this.setState({data: res.data});
            //console.log(this.state.medicos);
        }else{
            NotificationManager.error("No existen datos");
        }

    }).catch(error => {
        alert("---- Existio un error ---");
        console.log(error);
        
    });
  };
      
  imprimir =(id_certi)=>{
    let fileURL = process.env.REACT_APP_API_URL  + "certificado_reciboPDF/"+ id_certi;
    let  windowFeatures = 'width=650,height= 700, left=100,top=100 ';
    window.open(fileURL,'',windowFeatures);
  }
  //----------------
  
  /* === This is where the magic happens ==== */
  handleSubmit = event => {
    event.preventDefault();
    this.cargar_consultas();
  }

  /*handleChange = event =>{
    this.setState({ name: event.target.value});
  }*/
  //-------------------------------------
  render() {
    
    return (
      <div>
        <ToastContainer />
        <div className="content">

          {/*<!--- ------------------------------->*/}
          <div className="row">
            <div className="col-md-12">

              <h3> Consultas - Historia Clinica
                <div className="btn-group pull-right">

                  <Button className="create-modal btn btn-primary " >
                    <i className="fa fa-plus"></i> Nueva consulta
                  </Button>
                </div>
              </h3>


              <br />


              {/*<!-- box table -->*/}
              <div className="box box-primary">
                <div className="box-body">
                  <div className="container mt-5">
                    <div className="lightBoxGallery">
                      <div id="mi_galeria" className="row">
                        <div className="col-lg-3 col-md-4 col-sm-6">

                          <div className="box">

                            <div className="box-body p-2">
                              <div className="d-flex justify-content-between align-items-center">
                                <h5 className="dress-name">Nº: 25</h5>
                              </div>

                              <span className="text-">undefined</span>
                              <span className="text-danger">18 Aug, 2022</span>
                              <h4><span className="badge badge-secondary">CERRADO</span></h4>

                              <div className="d-flex justify-content-between align-items-center pt-1">

                                <button id="btn_edit" className="btn " onclick="ingresar(25); return false;">
                                  <i className="fa fa-edit"></i> Ingresar
                                </button>


                              </div>

                            </div>
                          </div>

                        </div>
                        
                        <div className="col-lg-3 col-md-4 col-sm-6">
                          <div className="box">

                            <div className="box-body p-2">
                              <div className="d-flex justify-content-between align-items-center">
                                <h5 className="dress-name">Nº: 32</h5>
                              </div>


                              <span className="text-danger">04 Dec, 2022</span>
                              <h4><span className="badge badge-primary">ABIERTO</span></h4>

                              <div className="d-flex justify-content-between align-items-center pt-1">

                                <button id="btn_edit" className="btn " onclick="ingresar(32); return false;">
                                  <i className="fa fa-edit"></i> Ingresar
                                </button>


                              </div>

                            </div>
                          </div>
                        </div><div className="col-lg-3 col-md-4 col-sm-6">
                          <div className="box">

                            <div className="box-body p-2">
                              <div className="d-flex justify-content-between align-items-center">
                                <h5 className="dress-name">Nº: 35</h5>
                              </div>


                              <span className="text-danger">14 Dec, 2022</span>
                              <h4><span className="badge badge-primary">ABIERTO</span></h4>

                              <div className="d-flex justify-content-between align-items-center pt-1">

                                <button id="btn_edit" className="btn " onclick="ingresar(35); return false;">
                                  <i className="fa fa-edit"></i> Ingresar
                                </button>


                              </div>

                            </div>
                          </div>
                        </div></div>
                    </div>
                  </div>


                </div>
              </div>
              {/*<!-- fin box table -->*/}

            </div>

          </div>
          {/*<!--- ----------------- form busqueda ------------------>*/}

          {/*<!-- -------------------------------------------------->*/}

        </div>
      </div>
    );
  }
}
  
  //------------para que soporte la funcionalidad de parametros
export function withRouter(Children){
  return(props)=>{

     const match  = {params: useParams()};
     return <Children {...props}  match = {match}/>
 }
}
export default withRouter(Consultas);