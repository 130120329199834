import React from "react";

import axios2 from 'services/axios2';

import {useParams} from "react-router-dom";
import {Link,hashHistory} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { NuDropdown } from "../../components/nubox/NuDropdown";
import ImageUploadSingle from "components/nubox/ImageUploadSingle"; 

import confirmService from 'components/confirmService';
import ModalMedico from "views/medicos_crud/ModalMedico"



import user from 'assets/img/user.jpg';

import { NotificationContainer, NotificationManager } from 'react-notifications';
import {
  Table,
  Button,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  ModalFooter,
  ToastHeader,
} from "reactstrap";

const initialForm={
  idglo:1,
      e_nom: '',
      e_dir: '',
      e_logo:'',
      e_tel: '', 
      e_email: '',
      e_ciudad: '',
}

/*$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$*/
class Globalx extends React.Component {  

  modalRef = React.createRef();//create ref
  
  state = {
    modalInsertar: false,
    form: initialForm,
    data: [],/* lista  */
    
  };
  
  //------------------------------------------------------
  componentDidMount() {
    //--limpiamos el array de prueba
    this.setState({data:[]});

    const date = new Date();
    const defaultValue = date.toLocaleDateString('en-CA');
  
    //this.setState({fechai: defaultValue}); 
    this.setState({ form:{...this.state.form, fecha: defaultValue,}}); 

    this.cargar_globalx();
    
    //this.cargar_citas();
    //this.fetchData(this.state.fechai);
    console.log(this.modalRef);
  }

  //------------ cambiamos la fecha
  /*handleChange = event =>{
    this.setState({ name: event.target.value});
  }*/
  //----------------especial, actualiza valores del form -------------------------------
  handleChangeForm = (e) => {
    //requerimos la prop name="?" en cada control
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      },
    });
  };
  
  //====================================================== 
  //----cargamos los datos desde la base de datos
  /*{
    "id_medico": 1,
        "nombre": "Dr. Gerson Abner Villafuerte Oporto",
        "dir": "",
        "tel": "",
        "cel": "",
        "foto_path1": ""
        "especialidad": "Ortodoncia"
  },*/
  cargar_globalx = () => {
    let param = { 
      
    }
    
    axios2.post(process.env.REACT_APP_API_URL  + "globalx_get_global", param)
    .then(res => {
      //console.log(res.data);

        if(res.data !== null ){ 
            //NotificationManager.success("Correcto");
            console.log(res.data);
            this.setState({form: res.data});
            //console.log(this.state.medicos);
        }else{
            this.setState({form: initialForm});
        }

    }).catch(error => {
        alert("---- Existio un error ---");
        console.log(error);
        
    });
  };
      
  imprimir =(id_certi)=>{
    let fileURL = process.env.REACT_APP_API_URL  + "certificado_reciboPDF/"+ id_certi;
    let  windowFeatures = 'width=650,height= 700, left=100,top=100 ';
    window.open(fileURL,'',windowFeatures);
  }
  //----------------
  
  /* === This is where the magic happens ==== */
  handleSubmit = event => {
    event.preventDefault();
    this.cargar_globalx();
  }
  /************************************************ */
  okImage = (filename) =>{    
    this.setState({ form:{...this.state.form, e_logo: filename}}); 
  }
  cancelImage = () =>{
    //---ocultamos la ventana
    this.setState({ form:{...this.state.form, e_logo: '' }}); 
  }
  okImage1 = (filename) =>{    
    this.setState({ form:{...this.state.form, rep_logo: filename}}); 
  }
  cancelImage1 = () =>{
    //---ocultamos la ventana
    this.setState({ form:{...this.state.form, rep_logo: '' }}); 
  }

  save =() =>{
  
    //let param =  {};
    let param =  this.state.form;
    param.idd =1;
    param.ope ='e';
    //console.log(param);

    axios2.post(process.env.REACT_APP_API_URL  + "globalx_store", param)
    .then(res => {
        if(res.data.exito === 1){ 
            toast.success(res.data.mensaje);
        }else{
            toast.error(res.data.mensaje);
        }

    }).catch(error => {
        alert("---- Existio un error ---");
        console.log(error);
        
    });
  
  }
  //-------------------------------------
  render() {
    
    return (
      <div>
        <ToastContainer />
        <div className="content">

          {/*<!--- ------------------------------->*/}
          <div className="row justify-content-center">
            <div className="col-md-8">

              { //JSON.stringify( this.state.form )
              }
              <div className="table-responsive">
                <table className="table table-bordered table-hover " style={{ backgroundColor: "#fff", border: "1px solid #d2d2d2" }} >
                  <tbody>
                    <tr>
                      <td colSpan="4" className="div_azulAma" style={{   backgroundColor: "#dddedf" }}> 
                        <h4>Configuracion global</h4>
                      </td>
                    </tr>

                    <tr>
                      <td className=" text-right" width="30%"><strong>Nombre:</strong></td>
                      <td width="30%">
                        <input
                          name="e_nom"
                          type="text"
                          onChange={this.handleChangeForm}
                          value={this.state.form.e_nom}
                          size="25" />
                      </td>
                      
                      <td>
                        Logo:
                      </td>
                    </tr>


                    <tr>
                      <td className=" text-right"><strong>Direccion:</strong></td>
                      <td>
                        <input
                          name="e_dir"
                          type="text"
                          className=""
                          onChange={this.handleChangeForm}
                          value={this.state.form.e_dir}
                          size="25" />
                      </td>
                      <td className=" text-center"  rowSpan={4} >
                        <div style={{ width:"120px",display: "inline-block" }}>
                          <ImageUploadSingle  
                            okImage={ this.okImage}
                            cancelImage={ this.cancelImage}
                            imageURL={process.env.REACT_APP_AFILES}
                            fileName={this.state.form.e_logo}
                          />
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td className=" text-right"><strong>Teléfono:</strong></td>
                      <td>
                        
                        <input
                          name="e_tel"
                          type="text"
                          className=""
                          onChange={this.handleChangeForm}
                          value={this.state.form.e_tel}
                        />
                      </td>
                      
                    </tr>

                  

                    <tr>
                      <td className=" text-right"><b>Email:</b></td>
                      <td>
                        <input
                          name="e_email"
                          type="text"
                          className=""
                          onChange={this.handleChangeForm}
                          value={this.state.form.e_email}
                        />
                      </td>
                      
                    </tr>

                    <tr>
                      <td className=" text-right"><b>Ciudad:</b></td>
                      <td>
                        <input
                          name="e_ciudad"
                          type="text"
                          className=""
                          onChange={this.handleChangeForm}
                          value={this.state.form.e_ciudad}
                        />
                      </td>
                      
                    </tr>
                    <tr>
                    
                    <td colSpan="4" className="div_azulAma" style={{   backgroundColor: "#dddedf" }}> 
                        <h4>Reporte</h4>
                      </td>
                    </tr>

                    <tr>
                      <td className=" text-right"><b>Reporte logo:</b></td>
                      <td>
                        <div style={{ width:"180px",display: "inline-block" }}>
                          <ImageUploadSingle  
                            okImage={ this.okImage1}
                            cancelImage={ this.cancelImage1}
                            imageURL={process.env.REACT_APP_AFILES}
                            fileName={this.state.form.rep_logo}
                          />
                        </div>
                      </td>
                      <td></td>
                    </tr>

                    <tr>
                      <td className=" text-right"><b>Rep leyenda:</b></td>
                      <td>
                        <textarea
                          name="rep_leyenda"
                          type="text"
                          className="form-control"
                          onChange={this.handleChangeForm}
                          rows="2"
                          value={this.state.form.rep_leyenda}
                        />
                      </td>
                      <td className=" text-right">
                        <div className="notecafe info"></div>
                      </td>
                    </tr>


                    <tr>
                    <td colSpan="4" className="div_azulAma" style={{   backgroundColor: "#dddedf" }}> 
                        <h4>Facturación</h4>
                      </td>
                    </tr>

                    <tr>
                      <td className=" text-right"><strong>Tipo:</strong></td>
                      <td>
                      </td>
                      <td className=" text-right">
                        <div className="notecafe info">.</div>
                      </td>
                    </tr>


                    <tr>
                      <td className="" colSpan="3" align="center">
                      <Button onClick={ this.save } className="btn btn-success">
                          <i className="fa fa-floppy-o"></i>&nbsp;
                          Guardar Datos
                      </Button>
                      
                      <button className="btn btn-default" >Cancelar</button>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan="3">
                        <div id="divres"></div>

                      </td>
                    </tr>

                  </tbody>
                </table>
              </div>
                
            </div>
          </div>

          {/*<!-- -------------------------------------------------->*/}

        </div>

      </div>
    );
  }
}
  
export default Globalx;