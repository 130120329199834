import React, { useEffect, useState, useMemo } from "react";


import NuPagination from './NuPagination';
import NuSearch from './NuSearch';
import NuCol from './NuCol';
import NuLoader from '../NuLoader';
import NuSpin from './NuSpin';

import NuDropdownSimple from 'components/nubox/NuDropdownSimple'
import NuCell from "./NuCell";

//import useFullPageLoader from "hooks/useFullPageLoader";

// 
// pageSettings: {current_page: 1, total: 12, per_page: 8, },

class NuDataTable extends  React.Component {
    state={
      //userData:{"current_page": 1,}
      //selectedOption: '',
      selectValue: this.props.pageSettings.per_page,  /*para el dropdown */
      sort:{field:'', dir:''},  //asc, desc, none or ''
    }
    componentDidMount(){
        
    }
    onHandleChangeSort({ field, dir }){
        this.setState({ sort: {field:field, dir:dir}} );
        this.props.onChangeSort({ field, dir })
    }
    //-------------------para el select
    onHandleChangeOpt = (pageSize) => {
        this.setState({ selectValue: pageSize });
        this.props.onChangePageSize(pageSize)
    };
   
    //==================================================
    render(){
    //const { selectedOption } = this.state;
    //const options = this.props.pageLengthOptions
    return (
        <>
        {/*<h2>Mi datatable en react</h2>*/}
        {/* this.props.dataSource.length */}
        <div className="row">
            <div className="col-md-4">
                <div style={{ display: "flex" }}>
                    <label>Mostrar&nbsp;</label>
                    <NuDropdownSimple
                        selectValue={this.state.selectValue}
                        options={this.props.pageLengthOptions} 
                        onChangeOption={this.onHandleChangeOpt}  
                    />
                    <label>&nbsp;registros</label>
                </div>
            </div>
            <div className="col-md-8 ">
                <div className="table_tools float-right text-right" style={{display: "inline-block", verticalAlign: "top"}}>
                <button className="btn btn-default buttons-excel" tabIndex="0" title="Export to Excel" style={{marginRight: "5px"}}>
                        <span>
                            <i className="fa fa-file-excel-o" aria-hidden="true"></i>
                        </span>
                    </button>
                    <button className="btn btn-default buttons-csv" tabIndex="1" title="Export to CSV" style={{marginRight: "5px"}}>
                        <span>
                            <i className="fa fa-file-text-o" aria-hidden="true"></i>
                        </span>
                    </button>
                    <button className="btn btn-default buttons-pdf" tabIndex="0" title="Export to PDF" style={{marginRight: "5px"}}>
                        <span>
                            <i className="fa fa-print" aria-hidden="true"></i>
                        </span>
                    </button>
                </div>
            </div>
        </div>
            <div className="row ">
            <div className="col mb-3 col-12 text-center">
                <div className="row">
                    <div className="col-md-6">
                        
                    </div>
                    <div className="col-md-6 d-flex flex-row-reverse">
                        
                    </div>
                </div>
                
                <div id="Grid" style={{position: "relative"}}>
                    <NuLoader 
                        isLoading={this.props.isLoading}
                        msg="Procesando..."
                    />
                    <div className="table-responsive">
                      <table className={ this.props.classNameTable}>
                          
                          <thead>
                              <tr>
                                  {this.props.columns.map((item,index) =>(
                                      
                                      <NuCol 
                                          key={index}
                                          field={item.field}
                                          text={item.text}
                                          className= {item.className}
                                          sorting={item.sorting}
                                          sort= {this.state.sort}
                                          onChangeSort= {(sortx)=>this.onHandleChangeSort(sortx)}
                                      />
                                  ))
                                  }
                          
                              </tr>
                          </thead>

                          <tbody>
                              {/*----- para cada fila ----*/}
                              {this.props.dataSource.map( (item, index) => (
                                  <tr key={index}>
                                      {/*----- para cada columna----*/}
                                      {/* esto era antes <td key={index2}>{ item[col.field]}- {col.dataType} </td>*/}
                                      { this.props.columns.map(( col,index2)=>(
                                          <NuCell 
                                              key={index2}
                                              itemRow={item}
                                              column={col}
                                              onClicButton1={ this.props.onClicButton1 }
                                              onClicButton2={ this.props.onClicButton2 }
                                              onClicButton3={ this.props.onClicButton3 }
                                              onClicButton4={ this.props.onClicButton4 }
                                            
                                          />
                                      ))}
                                      
                                  </tr>
                              ))}
                          </tbody>
                      </table>
                    </div>
                </div>
                {/* --- FOOTER --- */}
                <div className="row table-foot asrt-table-foot" >
                    <div className="col-md-4" style={{ textAlign: "left"}}>
                        Registros del {this.props.pageSettings.from } al {this.props.pageSettings.to} de {this.props.pageSettings.total} registros
                    </div>
                    <div className="col-md-8 pull-right text-right">
                    <NuPagination
                        totalCount={this.props.pageSettings.total}
                        pageSize={this.props.pageSettings.per_page}
                        currentPage={ this.props.pageSettings.current_page}
                        onPageChange={page => this.props.onChangePage(page)}
                        siblingCount={2}
                    />
                    </div>
                </div>
            </div>
        </div>
        {/*loader*/}
    </>
        );
    }
  }

/**
* Define defaultProps for this component
*/
NuDataTable.defaultProps = {
    columns:[],
    dataSource:[],
    rowHeight:16,

    show_pagination:true,
    pageLengthOptions: [ 5,10, 20, 50 ],
    pageSettings: {current_page: 2, total: 12, per_page: 10, }, 
    onChangePage:() => { }, /*ok */
    onChangePageSize:() => { }, /*ok */
    onChangeSort:() => { },
    onRowClicked: () => { },
    
    excel: false,
    print: false,
    csv: false,
    show_filter: false,
    show_length_options: true,
    isLoading:false,
}

export default NuDataTable;
export {
    NuPagination, NuSearch,
  };