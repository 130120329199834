import React, { Component, Fragment } from "react";
import cUtilFecha from "helpers/cUtilFecha";

import moment from 'moment'
import NuLoading from 'components/nubox/NuLoading';
import axios2 from 'services/axios2';

//import './nuCheckBox.css'
import {
  Table,
  Button,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  ModalFooter,
  ToastHeader,
} from "reactstrap";
import NuCalMes from "./NuCalMes";
import NuCalSemana from "./NuCalSemana";

const modes = {
  day: "D",
  month: "M",
  year: "Y",
  weekday: "W",
};

class NuCalendar extends Component {
  constructor(props) {
    super(props);
    const currentDate = new Date();
    //---ajuste por el cambio de horario
    currentDate.setMinutes(currentDate.getMinutes() + currentDate.getTimezoneOffset())
    this.state = {
      mode: "M", //M-W-D
      fecha: currentDate,
      day: currentDate.getDate(),
      month: currentDate.getMonth(),
      year: currentDate.getFullYear()
    };
  }

  //----------------------- 
  //---------------------------
  //const mesRef = useRef(null);
  //const semanaRef = useRef(null);
  //---------------------
  onClickDay(date) {
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();
    this.setState(
      {
        mode: "M",
        day,
        month,
        year
      },
      this.onChange
    );
  }
  hoy=()=>{
    const fechaNew = new Date();
    //---ajuste por el cambio de horario
    fechaNew.setMinutes(fechaNew.getMinutes() + fechaNew.getTimezoneOffset())
    this.setState({fecha: fechaNew});
  }
  //-------------------------------------------
  next=()=>{
    if(this.state.mode =="M"){
      const fechaNew = cUtilFecha.addMonths(this.state.fecha, 1);
      this.setState({fecha: fechaNew});
    }
    if(this.state.mode=="W"){
      const fechaNew = cUtilFecha.nextWeek(this.state.fecha);
      this.setState({fecha: fechaNew});
    }

  }
  //-------------------------------------------
  prev=()=>{
    if(this.state.mode =="M"){  
      const fechaNew = cUtilFecha.addMonths(this.state.fecha, -1);
      this.setState({fecha: fechaNew});
    }
    if(this.state.mode=="W"){
      const fechaNew = cUtilFecha.prevWeek(this.state.fecha);
      this.setState({fecha: fechaNew});
    }
  }
  onClickMode=(mode)=>{
    this.setState({mode : mode});
  }
  //--------------------------
  format_mes=(datex)=>{
    let ye = new Intl.DateTimeFormat('en', { year: 'long' }).format(datex);
    let mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(datex);
    let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(datex);
    //console.log(`${da}-${mo}-${ye}`);
    //March 2023
    return `${ye} ${mo}`;
  }
  //--------------------------
  //fecha_title =  format_mes(this.sta)
  //-------------------------------------------
  render() {
    //const events = formatEvents(this.props.events); //lista de eventos, a ser marcados
    const events = this.props.events; //lista de eventos, a ser marcados
    //const title = moment(this.state.fecha).format('MMMM YYYY'); // June 1, 2019
    let title = ""; //this.state.fecha.toDateString();
    if(this.state.mode =="M"){
      title = moment(this.state.fecha).format('MMMM YYYY'); // June 1, 2019
    }
    if(this.state.mode =="W"){
      let start = cUtilFecha.startOfWeek(this.state.fecha);
      let end = cUtilFecha.endOfWeek(this.state.fecha);
      let mes = cUtilFecha.mes_To_literal(this.state.fecha.getMonth() );
      let anyo = this.state.fecha.getFullYear();
      title = ` ${mes} (${start.getDate()}-${end.getDate()})  ${anyo}`;
    }
    return (
    <>
      <div className="container">
        { JSON.stringify(this.state.mode ) }
        <div class="ca-header ca-toolbar " >
          <div>
            <div class="btn-group">
              <button class="btn btn-primary" data-calendar-nav="prev" onClick={ this.prev }><i class="fa fa-fw fa-chevron-left"></i></button>
              <button class="btn btn-primary" data-calendar-nav="next" onClick={ this.next }><i class="fa fa-fw fa-chevron-right"></i></button>
            </div>
            
            <button class="btn btn-default" style={{ marginLeft: ".75em" }}  onClick={ this.hoy }>Hoy</button>
          </div>
         
          
          <div>
            <h2 class="ca-toolbar-title">{ title }</h2>
          </div>
          

          <div class="form-group pull-left">
              <button className="btn btn-default" onClick={()=> this.onClickMode("M") }> Mes </button>
              <button className="btn btn-default" onClick={()=> this.onClickMode("W") }> Semana </button>
              <button className="btn btn-default" onClick={()=> this.onClickMode("D") }> Día </button>
          </div>
        </div>

        { this.state.mode === 'M' &&
          <NuCalMes
        
            fecha={ this.state.fecha }
            events={events} //lista de eventos

            onClickDay={this.onClickDay}
            onClickPrev={this.onClickPrev}
            onClickNext={this.onClickNext}
          />
        }
        {  this.state.mode === 'W' &&
          <NuCalSemana
            
          fecha={ this.state.fecha } 
          onChange={(e) => this.setDate(e.value)} 
        />
        }

       
      </div>
    </>
    );
  }
}
  
  export default NuCalendar;


