
import React, { useRef, useEffect, useState } from "react";


const miselect={
    /*fontSize: "14pt",*/
    /*lineHeight: "1.5",*/
    border: "1px solid #ced4da",
    borderRadius: ".25rem",
    display: "block",
    width: "100%",
    padding: ".275rem .75rem",
}
/*<NuHighlighter
    searchPhrase="is"
    text="Kurtis Weissnat"
  />*/
  
const NuHighlighter = ( props ) => {
    // 
    const textToRepace = props.text; // we will replace : with div spacer
    const key = props.searchPhrase;

    //const key1 =/(a)/i;
    //const content = textToRepace.replace(key1, "<span class='text-red'>$&</span>" );
    //--- regex dinamico
    var regex = new RegExp("(" + key + ")", "i");
    const content = textToRepace.replace(regex, "<span class='text-red'>$&</span>" );
    //
    return (
      <div >
        {
          <div dangerouslySetInnerHTML={{   __html: content    }} />
        }
        
      </div>
    );
  }
  
  export default NuHighlighter;