import React from "react";
import {Link,hashHistory} from 'react-router-dom';
import axios2 from 'services/axios2';
import { withRouterJim } from "router/withRouterJim";
import AuthService from "services/auth.service";


import { NotificationContainer, NotificationManager } from 'react-notifications';
import {
  Table,
  Button,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  ModalFooter,
} from "reactstrap";

import PagoModal from "views/_box/PagoModal";

//type:'numeric'
const columnas=[
    {  field:'id', title: 'Cod. Cert.', type:'numeric'},
    {  field:'cliente', title: 'Cliente'},
    {  field:'nombre', title: 'Nombre'},
    {  field:'lote', title: 'Lote'},
    {  field:'elem', title: 'Elementos'},
]

const data = [
  { "id_tra": 1,  "fecha": "2023-02-26",  "estado": 1,  "mstotal": 0,  "mdesc": 0,  "mtotal": 0,  "pagado": null,  "0": "saldo",  "1": 0},
 
];



class RecaudacionList extends React.Component {
  
  modalRef = React.createRef();//create ref

  state = {
    data: data,
    id_paciente:'', /*id_paciente */
   
    form: {
      idd: "",
      ope: "",
      
    },
  };
  
  componentDidMount() {
    //--limpiamos el array de prueba
    this.setState({data:[]});

    const date = new Date();
    const defaultValue = date.toLocaleDateString('en-CA');
  
    //this.setState({fechai: defaultValue}); 

    let id = this.props.store.paciente.id_paciente;
    console.log("id_paciente:" + id);
    this.setState({id_paciente: id});

    this.fetchData(id);
  }

  
  //----cargamos los datos desde la base de datos
  fetchData = ( id ) => {
    let param = { 
      id_paciente: id,
    }
    
    axios2.post("recaudacion_get_pagado_saldo", param).then(res => {

        if(res.data.data.length>0 ){   
          console.log(res.data.data)          ;
            this.setState({
              data: res.data.data,
            });
        }else{
            NotificationManager.error("No existen registros");
        }

    }).catch(error => {
        NotificationManager.error("Bad Request");
        //this.setState({ errors: error })
        console.log(error);
    });
  };
 
  //------------------------------------------------------------------
  handleChange = (e) => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      },
    });
  };

  //============================================================
  pagar_click=(id_tra)=>{
    
    if(this.modalRef.current!=null){   
      this.modalRef.current.nuevo(id_tra); 
      //this.modalRef.current.setId_tra(id_tra);
    }
  }
  //----------------------- MODAL *---------------
  okModal =()=>{
    console.log("okModal");
    //--------
    let id_paciente = this.state.id_paciente;
    this.fetchData(id_paciente);
  }
  closeModal =()=>{
    console.log("closeModal");
  }
  //============================================================
  //--------------------------------------------------------------------
  render() {
    
    return (
      <>
        <Container style={{ marginTop: "50px", minHeight: "700px" }}>

          <NotificationContainer />
          <br />
          <div className="row">

            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">


              {/*  Authentication card end ---------------------------------------------  */}
              <div className="card">
                <div className="card-header">
                  <h3>Recaudación organizada por tratamiento</h3>
                </div>

                <div className="card-body">

                <div className="alert alert-primary" role="alert">
                  <b>Nota:</b> Tenga en cuenta que tiene que tener asignada una caja, y que esta caja este abierta.
                </div>
                  <Table>
                    <thead>
                      
                      <tr style={{backgroundColor: "#e3e3e3"}}>
                        <th>Nro.Tra</th>
                        <th>Nombre</th>
                        <th>Total</th>
                        <th>Pagado</th>
                        <th>Saldo</th>
                        <th>Accion</th>
                      </tr>
                    </thead>

                    <tbody>
                      {this.state.data.map((item) => (
                        <tr key={item.id_tra}>
                          <td>{item.id_tra}</td>
                          <td>{item.nombre}</td>
                          <td>{item.mtotal}</td>
                          <td>{item.pagado}</td>
                          <td>{item.saldo}</td>
                          <td>
                            <Button color="info" onClick={() => this.pagar_click(item.id_tra)}>Pagar</Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                 
                </div>

                <div className="card-footer">
                  <div className="text-center">

                    

                  </div>
                </div>
              </div>

           
            </div>{/*  end    */}

          </div>

          <PagoModal
            ref={this.modalRef}
            okModal={ this.okModal}
            closeModal={ this.closeModal}
            
          />

        </Container>

      </>
    );
  }
}

export default withRouterJim(RecaudacionList);