import React, { useReducer } from "react";
import axios from 'axios';
import axios2 from 'services/axios2';

import { ToastContainer, toast } from 'react-toastify';
import { withRouterJim } from "router/withRouterJim";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";


//$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
class ImagenZoom extends React.Component {

  state = {
    modalImagen: false,    
    imageUrl:'',
  };
  

  

  //------------------------------------------------------
  componentDidMount() {
    //--limpiamos el array de prueba
    this.setState({data:[]});

    const date = new Date();
    const defaultValue = date.toLocaleDateString('en-CA');
    //--- sacamos el parametro que viene con el filename
    let filename = this.props.params.filename;
    console.log("param file:"+ filename);
    
    let aux = process.env.REACT_APP_AFILES + filename;
    this.setState({imageUrl:aux })
  }


  //-------------------------------------
  render() {
    //const {form} = this.state;

    return (
      <div>
        <ToastContainer />
        {/* this.state.imageUrl */}
        <div className="content">
          <TransformWrapper>
          {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
          <React.Fragment>
            <div className="tools">
              <button onClick={() => zoomIn()}>+ Zoom</button>
              <button onClick={() => zoomOut()}>- Zoom</button>
              <button onClick={() => resetTransform()}>Reset</button>
            </div>
            <TransformComponent>
              <img src={ this.state.imageUrl} alt="test" />
            </TransformComponent>
          </React.Fragment>
        )}
          </TransformWrapper>  
        </div>

      </div>
    
    );
  }
}
  
//------------para que soporte la funcionalidad de parametros
export default withRouterJim(ImagenZoom);