import React from "react";
import './styles.css';
/*const techCompanies = [
    { label: "Apple", value: 1, icon:'fa fa-home', color:'#ccc' },
    { label: "Facebook", value: 2, icon:'fa fa-circle', color:'#abc' },
];*/

// Reusable Dropdown
class NuListSimple extends React.Component { 
    // selectValue => current selected value
    // options => choices in the dropdown
    // setFilteredData => function to change the selectValue
    onClickItem = (e,item) => {
      e.target.name = this.props.name;
      e.target.value = item.value;
      this.props.onChange(e);
    }; 
    //=======================================
    render() {
       const { name, value, options } = this.props;

       //const miClassName = `nu-sidenav_item ${ value===item.value? "menu-item--active":'' }`;
       
       return (
        <nav className="side-menu">
          {this.props.titulo?
          <h3>{this.props.titulo}</h3>
          :""
          }
          <ul className="nu-sidenav">
              {options.map( (item, index) => {
                return (
                    <li key={ index } 
                        className={ `nu-sidenav_item ${ value===item.value? "menu-item--active":'' }` }
                      >
                      
                      <div 
                          title={item.label} 
                          className="" 
                          onClick={ (e)=> this.onClickItem(e,item) }
                      >
                      { item.iconColor!=""?
                        <>
                        <i className={ item.icon} style={{color: item.iconColor }}></i>&nbsp;&nbsp;
                        </> :""
                      }

                      { item.label }</div>
                    </li>
                );
              })}
          </ul>
        </nav>
        );
     }
};
export default NuListSimple;