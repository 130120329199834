import React from 'react';
import axios2 from 'services/axios2';
import { ToastContainer, toast } from 'react-toastify';
import  NuDropdown from "components/nubox/NuDropdown";

import { withRouterJim } from "router/withRouterJim";

import { Link } from "react-router-dom";
//import '../App.css';
import logo from '../assets/logo.png';

//$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
class CambiarClaveA extends React.Component {

  myRef = React.createRef();//create ref
  citaRef = React.createRef();//create ref

  state = {
    data: [],/* lista  */
    form: {
      id_u:0,
      nueva_clave: "",
      confirmar_clave:'',
    },
    usuarios: [],
  };

  //-----------------------------------------------

  //------------------------------------------------------
  componentDidMount() {
    console.log("componentDidMount citas");
    //--limpiamos el array de prueba
    this.setState({ data: [] });

    const date = new Date();
    const defaultValue = date.toLocaleDateString('en-CA');

    //this.setState({fechai: defaultValue}); 
    this.setState({ form: { ...this.state.form, fecha: defaultValue, } });

    this.cargar_usuarios();
    //this.cargar_citas();
    //this.fetchData(this.state.fechai);


  }

  //----------------especial, actualiza valores del form -------------------------------
  handleChangeForm = (e) => {
    //requerimos la prop name="?" en cada control
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      },
    });
  };
  //-----------------
  cargar_usuarios = async() => {
    let param = { }
    
    await axios2.post("cbo_users", param).then(res => {
        //if(res.data.length>0){ 
            this.setState({usuarios: res.data});            
        //}
    }).catch(error => {
        alert("---- Existio un error ---");
        console.log(error);
    });
  };
  //--------------------------


  onSubmitHandler= (event)=>{
    event.preventDefault();

    this.save();
  }
  //-----------------------------------
  save =() =>{
    const user = this.props.store.user;
    //const id_paciente = this.props.store.paciente.id_paciente;

    let param = {  ...this.state.form,   };

    axios2.post("cambiarclave_storeA", param)
    .then(res => {
        console.log(res.data);
        if(res.data.exito === 1){ 
            toast.success(res.data.mensaje);            
        }else{
            toast.error(res.data.mensaje);
        }

    }).catch(error => {
        alert("---- Existio un error ---");
        console.log(error);
        
    });
    
  }
  //-------------------------------------

  render() {

    return (
      <div>
        <ToastContainer />
        <div className="content">
          <form onSubmit={this.onSubmitHandler}>
            <fielset>
              <legend>Cambiar Clave</legend>

              <div className="alert alert-info">
                <strong>Informacion!</strong> La nueva clave debe tener una longitud minima de 6 caracteres alfanumericos ademas de un caracter especial.
              </div>

              <div className="table-responsive">
                <table className="table">
                  <tbody>

                    <tr>
                      <td>
                        <label>USUARIO::</label>
                      </td>
                      <td>
                        <NuDropdown
                          options={this.state.usuarios}
                          name="id_u"
                          value={this.state.form.id_u}
                          onChange={this.handleChangeForm}
                        />
                      </td>
                    </tr>
                    

                    <tr>
                      <td>
                        <label>NUEVA CLAVE::</label>
                      </td>
                      <td>
                        <input
                          type='password'
                          className='form-control'
                          style={{ width: "160px" }}
                          name='nueva_clave'
                          value={this.state.form.nueva_clave}
                          onChange={this.handleChangeForm} />
                      </td>
                    </tr>
                    
                    <tr>
                      <td>
                        <label>CONFIRMAR NUEVA CLAVE::</label>
                      </td>
                      <td>
                        <input
                          type='password'
                          className='form-control'
                          style={{ width: "160px" }}
                          name='confirmar_clave'
                          value={this.state.form.confirmar_clave}
                          onChange={this.handleChangeForm} />
                      </td>
                    </tr>
                  </tbody>
                  </table>
              </div>
              <div className="row" style={{ padding: "5px" }}>
                <div className="col-md-12" align="center">
                  <input
                    className="btn btn-primary"
                    type="submit"
                    value="CAMBIAR CLAVE"
                  />
                </div>
              </div>
            </fielset>
          </form>
          <br />

        </div>


      </div>
    );
  }
}

export default withRouterJim(CambiarClaveA);