import React, { useState,useFocus } from "react";


const miselect={
    /*fontSize: "14pt",*/
    /*lineHeight: "1.5",*/
    border: "1px solid #ced4da",
    borderRadius: ".25rem",
    display: "block",
    width: "100%",
    padding: ".275rem .75rem",
}
/** nuestras rules */
const rules = {
	required:{ test:function(e) { return( e.length > 0 ) }   }, //new RegExp(/\S/),
	email:new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i),
	date: new RegExp(/^((((0?[1-9]|[12]\d|3[01])[\.\-\/](0?[13578]|1[02])[\.\-\/]((1[6-9]|[2-9]\d)?\d{2}))|((0?[1-9]|[12]\d|30)[\.\-\/](0?[13456789]|1[012])[\.\-\/]((1[6-9]|[2-9]\d)?\d{2}))|((0?[1-9]|1\d|2[0-8])[\.\-\/]0?2[\.\-\/]((1[6-9]|[2-9]\d)?\d{2}))|(29[\.\-\/]0?2[\.\-\/]((1[6-9]|[2-9]\d)?(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00)|00)))|(((0[1-9]|[12]\d|3[01])(0[13578]|1[02])((1[6-9]|[2-9]\d)?\d{2}))|((0[1-9]|[12]\d|30)(0[13456789]|1[012])((1[6-9]|[2-9]\d)?\d{2}))|((0[1-9]|1\d|2[0-8])02((1[6-9]|[2-9]\d)?\d{2}))|(2902((1[6-9]|[2-9]\d)?(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00)|00))))$/), //  format dd/mm/yyyy
	hour: new RegExp(/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/),
	int: new RegExp(/[+-]?\d+$/),
	positive_int: new RegExp(/\d+$/),
	float: new RegExp(/^([+-]?(((\d+(\.)?)|(\d*\.\d+))([eE][+-]?\d+)?))$/),
	number: { test:function(e) { return !isNaN( e ) }   },
	string:new RegExp(/^([\w]|[\W]|[\s\/])+$/i),
	string2:new RegExp(/^([a-z]|[A-Z]|[0-9]|[\s\/]|[\.\,\-ÁÉÍÓÚáéíóúÑñ;:¡!·#$%&@()=_?¿º\\])+$/i),
};
/************************************************************* */
const descrip = {
	required:" Campo obligatorio",
	email:"E-mail incorrecto",
	date: "La fecha requiere formato dd/mm/yyyy",
	hour: "La hora requiere formato HH:MM",
	int: "Requiere un número entero",
	positive_int:"Requiere número positivo",
	float:"Requiere número decimal",
	number:"Requiere número",
	string:"Requiere una cadena",
	string2:"Requiere una cadena, acepta acentos",
};
/**************************************************************/ 

export const NuTextInput = (props) => {
    //const {value, onChange} = props;

    const [error,setError] = useState("");

    //----evento onChange -----
    const onInputChange = (event) => {
                
        validateFiled(event);
        
    }
    const validateFiled =(event) =>{
        let value = event.target.value;
        //-------------
        let type = event.target.type;
        let name = event.target.name;
        var valtipo = props.valtipo; 
        var valreq = props.valreq;
        //-----------
        //---- si no existe rules de validacion, retornar
        if(valtipo=== undefined){ 
            console.log("sin reglas de validacion");
            return ;
        }
        //----------------------------
        var valid = true;
        //---si es indefinido, lo cambiamos por vacio
	    value = (value == undefined)? '': value;
        //---primera validacion, si es vacio ----------------------
        if ( valreq==true ) {
			if ( value.length <= 0 ){
                setError("Valor requerido");
                return;
            } 
		} 
        
        //-- segunda validacion -----------------------------------
        let indexr= valtipo;  //ejemplo: indexr= "positive_int";
        //---- verificamos que el key exista
        const arraux = rules.keys();
        let obj = arraux.find(valtipo);
        if(obj===''){
            setError("Tipo de validacion no reconocido");
            return;
        }
	    //----------ahora comprobamos las rules
	    if ( value.length > 0 )  {
            //------------------aplicamos la expresion regular
            let men_err = descrip[indexr];
            valid = rules[indexr].test(value);
            //--------------------------------------
            if ( valid ) {
                setError("");
            } else {
                setError(men_err);
            }
            //--------------------------------------
        }

    }
    const removeError =() =>{
        setError("");
    }
    const addError =(men_err) =>{
        setError(men_err);
    }

    return (
    <div className="">
        <input 
           type={props.type}
           name={props.name}
           className={props.className}
           value={props.value}
           placeholder={props.placeholder}
           //onChange={e => props.handleInputData(e.target.value)}
           onChange= {onInputChange}
        />
        {/*<label>{props.labelName} :</label>*/}
        {/*<div class="valid-message">{error}</div>*/}
        {error && props.required === "required" && <span>Campo es requerido</span>}
        {error!=='' && props.tipoval !== "" && <div style={{color:'red'}}>{error}</div>}
        
    </div>
    )
};