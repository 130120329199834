import React from "react";
import axios2 from 'services/axios2';

import {useParams} from "react-router-dom";
import {Link,hashHistory} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import NuDropdown from "../../components/nubox/NuDropdown";
import  NuAutocomplete  from "../../components/nubox/NuAutocomplete";
import NuListRadio from "components/nubox/NuListRadio";

import { NotificationContainer, NotificationManager } from 'react-notifications';
import {
  Table,
  Button,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  ModalFooter,
  ToastHeader,
} from "reactstrap";

const initialForm={
  idd:0,
  ope:'n',
  id_cita:0,
  id_medico: 0,      
  fecha: null,
  hora_ini:'8:00',
  id_paciente:0,
  paciente: "",
  tel:"",
  motivo:'',
  atendido_sn: 0,
  id_estadoc:0,
}

class CitaModal extends React.Component {
  

  state = {
    modalInsertar:false,
    form0: initialForm,
    medicos:[],
    horas:[],
    //selected_estado:0,
    estados:[ { value:0, label: "No Confirmado",color:"#008ffb"  },
              { value:1, label: "Confirmado", color:"#ce9320" },
              { value:2, label: "Hora Cancelada", color:"#ff4560" },
              { value:3, label: "Atendido", color:"#00e396" },
            ],
  };

  //------------------------------------------------------
  componentDidMount() {
    //console.log("componentDidMount Modal");
    //--limpiamos el array de prueba
    //this.setState({data:[]});

    const date = new Date();
    date.setMinutes(date.getMinutes() + date.getTimezoneOffset());

    const defaultValue = date.toLocaleDateString('en-CA');
    //this.setState({fechai: defaultValue}); 
    this.setState({ form0:{...this.state.form0, fecha: defaultValue,}}); 

    this.cargar_medicos();
    this.cargar_horas();
    //this.cargar_citas();
    //this.fetchData(this.state.fechai);
    //----------- limpiamos el form -------------

    //---------si viene la propiedad true, mostramos la informacion

  }
  //---- retorna un formulario con valores iniciales
  formReset = () =>{
    //--obtenemos el primer id del combo medico y de citaho

    let formx = initialForm; 
    
    console.log("length:"+ this.state.medicos.length);
    if(this.state.medicos.length>0){
      formx.id_medico = this.state.medicos[0].value;
    }

    //this.setState({form0: formx});

    return formx;
  }
  //------------ cambiamos la fecha
  /*handleChangeFechai=(event)=> {    
    this.setState({fechai: event.target.value}); 
  }*/
  //----------------especial, actualiza valores del form -------------------------------
  handleChange = (e) => {
    //requerimos la prop name="?" en cada control
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      },
    });
  };
  handleChangeForm0 = (e) => {
    //requerimos la prop name="?" en cada control
    //console.log(e.target.name);
    //console.log(e.target.value);

    this.setState({
      form0: {
        ...this.state.form0,
        [e.target.name]: e.target.value,
      },
    });

  };
  //-------------------------
  //======================================================
  showModal = () => {
    //setModalOpen(true);
    this.setState({ modalInsertar: true});
  }
  closeModal = () => {
    //setModalOpen(true);
    this.setState({ modalInsertar: false});
  }
  asigna_form0_val=(name,value)=>{
    this.setState({
      form0: {
        ...this.state.form0,
        [name]: value,
      },
    });
  }
  //--------------- 
  //--- hora, solo se puede utilizar una ves setStare en una funcion 
  //--- cuando se utiliza multiples veces, solo hace caso la ultima, tomar en cuenta
  nuevo =(id_medico, fecha,  hora) =>{
    console.log("nuevo");
    console.log("Medico:" + id_medico);
    console.log("Fecha:" + fecha);

    //this.limpiar();
    this.showModal();
    
    this.setState({
      form0: {
        ...this.formReset(),
        "idd":0,
        "ope":'n',
        "id_medico": id_medico,
        "fecha": fecha,
        "hora_ini": hora,
      },
    });
    
    console.log(this.state.form0);
    
  }
  //---------------
  editar =(idd)=>{
    console.log("editar:" + idd);
    let param = { 
      idd: idd,
      ope:'f'
    }
    console.log(param);
    axios2.post(process.env.REACT_APP_API_URL  + "cita_store", param).then(res => {
      console.log(res.data);

        if(res.data!==null){ 
            //NotificationManager.success("Correcto");
            this.setState({
              form0: {
                ...res.data,
                "idd": idd,
                "ope": 'e',
              },
            });

            //----
            this.showModal();
            //colocamos el valor a autocompletado
            //this.autocompleteRef.current.selectText({"id": res.data.id_paciente, "label":res.data.paciente });
            //---asignamos los valores
            /*this.setState({
              form0: {
                ...this.state.form0,
                "idd": idd,
                "ope": 'e',
              },
            });*/

            
            
        }else{
          toast.error("No existe el registro", {
            position: toast.POSITION.TOP_RIGHT
          });
        }

    }).catch(error => {
        alert("---- Existio un error ---");
        console.log(error);
        
    });
  }
  //-------------------------------
  eliminar =(idd)=>{
    console.log("eliminar");
    let param = { 
      idd: idd,
      ope:'x'
    }
    console.log(param);
    axios2.post(process.env.REACT_APP_API_URL  + "cita_store", param).then(res => {
      console.log(res.data);

        if(res.data.exito===1){ 
            //NotificationManager.success("Correcto");
            this.limpiar();
            this.closeModal();
            toast.success("Eliminado con exito");
            this.props.okModal(); //callback
        }else{
          toast.error("Error al eliminar registro", {
            position: toast.POSITION.TOP_RIGHT
          });
        }

    }).catch(error => {
        alert("---- Existio un error ---");
        console.log(error);
        
    });
  }
  
  //----cargamos los datos desde la base de datos
  cargar_medicos = async() => {
    let param = { 
    }
    
    await axios2.post(process.env.REACT_APP_API_URL  + "cbo_medicos", param).then(res => {
        if(res.data.length>0){ 
            //NotificationManager.success("Correcto");
            this.setState({medicos: res.data});
            //console.log(this.state.medicos);
            
        }else{
            NotificationManager.error("No existen medicos");
        }

    }).catch(error => {
        alert("---- Existio un error ---");
        console.log(error);
        
    });
  };

  cargar_horas = () => {
    let param = { 
    }
    
    axios2.post( "cbo_horas", param).then(res => {
        if(res.data.length>0){ 
            //NotificationManager.success("Correcto");
            //-- formato value, label
            this.setState({horas: res.data});
            //console.log(this.state.horas);
        }else{
            NotificationManager.error("No existen medicos");
        }

    }).catch(error => {
        NotificationManager.error("Bad Request");
        console.log(error);
        
    });
  };
 
  
  
  //---------------- del autocompletado----------------
  // { id: 11, label: "LORENA JAMES" }
  handleOnSelect = (item) => {
    // the item selected
    console.log("handleOnSelect");
    console.log(item)
    this.setState({
      form0: {
        ...this.state.form0,
        id_paciente: item.id,
        paciente: item.label,
        tel: item.tag,
      },
    });

  }
  validar = () =>{
    if( this.state.form0.id_medico===0){
      toast.error( "Medico no valido", {position: toast.POSITION.TOP_RIGHT});
      return true;
    }
    if( this.state.form0.id_paciente===0){
      toast.error( "Paciente no valido", {position: toast.POSITION.TOP_RIGHT});
      return true;
    }
    if( this.state.form0.motivo===""){
      toast.error( "Motivo no valido", {position: toast.POSITION.TOP_RIGHT});
      return true;
    }

    return false;
  }
  //-------------------------------------
  formSave = () => {
    // the item selected
    console.log("save");

    let error = this.validar();
    if(error===true){
      return;
    }
    
    let param = this.state.form0;
    console.log(param);

    axios2.post( "cita_store", param).then(res => {
        let response = res.data;
        console.log("cita_store");
        console.log(res.data);
        if(response.exito===1){ 
            
          toast.success( response.mensaje, {position: toast.POSITION.TOP_RIGHT});
          //---llamamos a la funcion externa
          this.closeModal();
          this.props.okModal(); //callback
        }else{
            
          toast.error( response.mensaje, {position: toast.POSITION.TOP_RIGHT});
        }

    }).catch(error => {
        //if(error.response.status && error.response.status===400)
        alert("Bad Request - ");
        console.log(error);
        
    });

  }
  //--------------------------------
  openInNewTab = url => {
    // 👇️ setting target to _blank with window.open
    window.open(url, '_blank', 'noopener,noreferrer');
  };
  //-------------------------------
  onNuevoPaciente=(e)=>{
    e.preventDefault();
    this.props.onNuevoPaciente();
  }
  
  render() {
    
    return (
      <div>
        <ToastContainer />
        
        {/*--- modal---- */}
        <Modal isOpen={this.state.modalInsertar} toggle={this.closeModal} >
          <ModalHeader toggle={this.closeModal} className= { (this.state.form0.ope==='n'? "header-verde":"header-naranja") + " " + (this.state.form0.ope==='n'? "color:'#45b545'":"color:'#343b43'")  } 
          >
            <h4 className="modal-title">
            { this.state.form0.ope==='n'
              ? "Nuevo"
              : "Editar"
            }
            </h4>
            { 
              //JSON.stringify(this.state.form0)
              //JSON.stringify(this.state.form0.id_estadoc)
            }
          </ModalHeader>

          <ModalBody style={{background: "#F7F7F7"}}>
            <form  method='POST'>

              <div className="row">
                <div className="col-md-8">
                  <div className='form-group row'>  
                    <label htmlFor='id_cita' className='col-md-4 control-label'><b>Cod. cita</b></label>
                    <div className='col-md-4'>
                        <input 
                          name='id_cita' 
                          type='text' 
                          className='form-control' 
                          value={this.state.form0.id_cita} 
                          onChange={ this.handleChangeForm0} readOnly />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row  pt-2">
                <div className="col-md-8">
                  <div className="form-group">
                    <label className="control-label">Medico:
                      <span id=""></span>
                    </label>

                    <NuDropdown
                        options={this.state.medicos}
                        onChange={this.handleChangeForm0}
                        value={this.state.form0.id_medico}
                        name="id_medico"
                    />
                    
                    
                  </div>
                </div>  
              </div>

              <div className="row  pt-2">
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="control-label">Fecha: </label>
                      <input 
                        name='fecha' 
                        type='date' 
                        className="form-control" 
                        value={this.state.form0.fecha} 
                        onChange={ this.handleChangeForm0} 
                          />
                    
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="control-label">Hora:</label>
                    <NuDropdown
                        options={this.state.horas}
                        onChange={this.handleChangeForm0}
                        value={this.state.form0.hora_ini}
                        name="hora_ini"
                    />
                  </div>
                </div>
              </div>

              <div className="row  pt-2">
                <div className="col-md-8">
                  <div className="form-group">
                    <label className="control-label">Paciente</label>
                    <div className="input-group">

                      <NuAutocomplete
                        
                        className="form-control"
                        items_api_url={ process.env.REACT_APP_API_URL  + "get_list_paciente_key"}
                        value={this.state.form0.paciente}
                        isEditable ={false}
                        onSelect={this.handleOnSelect}
                        
                      />

                      <div className="btn-group">
                        
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  
                  <div className="form-group">
                    
                    <Button className="btn btn-success btn-md" onClick={(e)=> this.onNuevoPaciente(e) } >
                      <i className="fa fa-plus"></i> Paciente Nuevo
                    </Button>
                    
                  </div>

                </div>
              </div>

              <div className="row  pt-2">
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="control-label">Telf.: </label> &nbsp;&nbsp;
                    <span >{this.state.form0.tel} </span>
                    
                  </div>
                </div>
                
              </div>

              <div className="row  pt-2">
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="control-label">Motivo Consulta</label>
                    <textarea 
                        name="motivo" 
                        className="form-control" 
                        rows="2"
                        value={this.state.form0.motivo} 
                        onChange={ this.handleChangeForm0}  
                    ></textarea>
                  </div>
                </div>
                
              </div>
              
              <div className="row  pt-2">
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="control-label">Estado</label>
                    <NuListRadio
                      options={this.state.estados}
                      name="id_estadoc"
                      value={this.state.form0.id_estadoc}
                      onChange={this.handleChangeForm0}
                      isHorizontal = {true}
                    />
                  </div>
                </div>
              </div>

              <div>
                {/*
                  <span>Id medico:</span>  {  this.state.form0.id_medico }<br/>
                <span>fecha:</span>  {  this.state.form0.fecha }<br/>
                <span>hora_ini:</span>  {  this.state.form0.hora_ini }<br/>
                */}   
              </div>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={ this.formSave}>Guardar</Button>{' '}
            <Button color="secondary" onClick={this.closeModal}>Cancel</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
export default CitaModal;