import React, { useState,useFocus } from "react";
//import axios from "axios";
import axios2 from 'services/axios2';
import NuHighlighter  from "components/nubox/NuHighlighter";

import classnames from "classnames";
import './styles.css'
import { EditAttributes } from "@material-ui/icons";

const items = [
  { id: 1, label: 'The Godfather'},
  { id:2, label: 'Pulp Fiction'},
];

//const itemSelected =
// or
//const options = ['The Godfather', 'Pulp Fiction'];


const NuAutocomplete = (props) => {
  const [active, setActive] = useState(0);
  const [filtered, setFiltered] = useState([]);
  const [isShow, setIsShow] = useState(false);
  const [input, setInput] = useState(""); /* valor del input */
  //--
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  //----para hacer visible el item cuando se pierde de la pantalla tecla down
  const [suggestRowIdx, setSuggestRowIdx] = useState(0);
  const itemRefs = React.createRef();
  itemRefs.current = [];
  //-------------------------------
  const [flagEditable, setFlagEditable] = useState(false); //false res readOnly , true editable
  //---------------------------------------------------
  const onChange = e => {
    //const { suggestions } = props;
    const input = e.currentTarget.value; //valor del textyo
    /*const newFilteredSuggestions = suggestions.filter(
      suggestion =>
        suggestion.toLowerCase().indexOf(input.toLowerCase()) > -1
    );*/
    //----buscamos las sugerencias
    let key = input.toLowerCase(); //en minusculas;
    let items_api_url = props.items_api_url;

    //let accessToken= props.accessToken;
    //---
    fetchData(key, items_api_url, "");
    //---
    setActive(0);
    //setFiltered(newFilteredSuggestions);
    setIsShow(true);
    setInput(e.currentTarget.value)
  };

  //-------------------------------------
  function fetchData(query, api_url, token) {
    setData([]);
    let cancel;

    setIsLoading(true); //cargando
    setSuggestRowIdx(0); //id =0

    axios2({
      method: "POST",
      url: api_url,
      params: { q: query },
    })
      .then((res) => {
        setIsLoading(false);
        setData(res.data);
        
      })
      .catch((e) => {
        console.log("error fetch data");
      });
    return () => cancel();
  }
  //========== refs
  const addToRefs = (el, index) => {
    if(el && ! itemRefs.current.includes(el)){
      itemRefs.current[index] = el;
    }
  }
  //---------------------------------------------------
  const selectText = obj => {
    
    setSuggestRowIdx(0);
    setData([]);
    setIsShow(false);
    setInput(obj.label);
    
    //---------- llamos al evento externo 
    props.onSelect(obj);
  };
  //===============================================
  const onKeyDown = e => {
    
    //-------------------
    let scrollIndex = 0;
    //---validacion
    if(data.length===0){ return;}

    if (e.keyCode === 13) { // enter key
      setActive(0);
      setIsShow(false);
      //setInput(filtered[active])

      setInput(data[suggestRowIdx])
      setSuggestRowIdx(0);
      //--------- pasamos el objeto ---------------
      selectText(data[suggestRowIdx]);
    }
    else if (e.keyCode === 38) { // up arrow
      scrollIndex = suggestRowIdx - 1;
      
      if(scrollIndex>=0){
        //hacer que entre en pantalla item seleccionado, la clave
        itemRefs.current[scrollIndex].scrollIntoView({block: 'end', behavior: 'smooth'});
        //this.setState({ suggestRowIdx: scrollIndex });
        setSuggestRowIdx(scrollIndex);
        setActive(scrollIndex); //colocamos la clase
      }else{
        //setActive(null);
      }

      //return (active === 0) ? null : setActive(active - 1);
    }
    else if (e.keyCode === 40) { // down arrow

      scrollIndex = suggestRowIdx + 1;
      
      if(scrollIndex < data.length){
        itemRefs.current[scrollIndex].scrollIntoView({block: 'end', behavior: 'smooth'});
        //this.setState({ suggestRowIdx: scrollIndex });
        setSuggestRowIdx(scrollIndex);
        setActive(scrollIndex); //colocamos la clase
      }else{
        //setActive(null);
      }

      //return (active - 1 === filtered.length) ? null : setActive(active + 1);
      //return (active - 1 === data.length) ? null : setActive(active + 1);
    }

    
  };
  //===============================================
  const set_editable =(e,flag)=>{
    e.preventDefault();
    setFlagEditable(flag);
  }
  //---------------------------------------------------
  const renderAutocomplete = () => {
    if (isShow && input) {
      if (data.length) {
        return (
          <div className="p-autocomplete-panel">
            <ul className="autocomplete " >
              {
              data.map((item, index) => {
                let classA="";
                if (index === active) {
                  //classA = "active"; //nu_list-item nu_list-item--focus
                  classA = "nu_list-item--focus"; //nu_list-item nu_list-item--focus
                }
                return (
                  <li className={`nu_list-item  ${classA}`} 
                    key={item.id} 
                    onClick={e => selectText(data[index])} 
                    ref={el => addToRefs(el, index)}>

                    {/*{item.label}*/}
                    {/* pintamos las palabras que coincidan */}
                    <NuHighlighter
                      searchPhrase={ input }
                      text={ item.label }
                    />
                    

                  </li>
                );
              })
              }
            </ul>
          </div>
        );
      } else {
        return (
          <div className="no-autocomplete">
            <em>No Encontrado</em>
          </div>
        );
      }
    }
    return <></>;
  }
  //---------------------------------------------------
  return (
    <>
      <div className={classnames("control", { "is-loading": isLoading })}  style= {{ width: "100%" }}>
        
          { (flagEditable===false && props.isEditable===false)?
            <div className="input-group">
              <input 
                type="text" 
                className="form-control"
                readOnly
                value={props.value}
                />
              <button className="btn" onClick={(e)=>set_editable(e,true)}> <i className="fa fa-close"></i>  </button>
            </div>
            :
            <div className="input-group">
              <input
                type="text"
                className= {props.className}
                onChange={onChange}
                onKeyDown={onKeyDown}
                value={input}
              />
              <button className="btn" onClick={(e)=>set_editable(e,false)}> <i className="fa fa-search"></i>  </button>
            </div>
          }
          
          
      </div>

      {renderAutocomplete()}
    </>
  );
}
export default NuAutocomplete;