import React from "react";

import axios2 from 'services/axios2';

import {useParams} from "react-router-dom";
import {Link,hashHistory} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import NuDropdown from "../../components/nubox/NuDropdown";



import Child from "views/cita_all/Child";
import CitaModal from "views/cita_all/CitaModal";
//import listac from '../assets/img/lista2.png';
import confirmService from 'components/confirmService';
import DatePicker from "components/DatePicker"
import NuListCheck from "components/nubox/NuListCheck";
import Calendar from 'react-calendar';

import { NotificationContainer, NotificationManager } from 'react-notifications';
import {
  Table,
  Button,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  ModalFooter,
  ToastHeader,
} from "reactstrap";
import { Save } from "@material-ui/icons";
import cUtilFecha from "helpers/cUtilFecha";


const cbo=[
  {  value:'1', label: 'label1'},
];
const data = [
  { id_cita: 1, id_citaho:0, hora: "00:00", hora_ini: "00:30", historia:'V-415', paciente:"", motivo:"", atendido_sn:"", id_estadoc:""},
  { id_cita: 2, id_citaho:0, hora: "00:00", hora_ini: "00:30", historia:'V-415', paciente:"", motivo:"", atendido_sn:"", id_estadoc:""},
 
];

//$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
class CitasHoy extends React.Component {
  
  myRef = React.createRef();//create ref
  citaRef = React.createRef();//create ref

  state = {
    data: data,/* lista de citas */
    form: {
      id_medico: 0,
      id_estado:0,
      fecha: "",
    },
    medicos:[], 
    selected_estado:0,
    estados:[ { value:0, label: "No Confirmado",icon:'fa fa-circle', color:'#008ffb' },
              { value:1, label: "Confirmado",icon:'fa fa-circle', color:'#feb019' },
              { value:2, label: "Hora Cancelada",icon:'fa fa-circle', color:'#ff4560' },
              { value:3, label: "Atendido",icon:'fa fa-circle', color:'#00e396' },
            ],
  };
  
  //-----------------------------------------------
 
  //------------------------------------------------------
  componentDidMount() {
    console.log("componentDidMount citas");
    //--limpiamos el array de prueba
    this.setState({data:[]});

    const date = new Date();
    const defaultValue = date.toLocaleDateString('en-CA');
  
    //this.setState({fechai: defaultValue}); 
    this.setState({ form:{...this.state.form, fecha: defaultValue,}}); 

    this.cargar_medicos();
    
    //this.cargar_citas();
    
  }

  //----------------especial, actualiza valores del form -------------------------------
  handleChangeForm = (e) => {
    //requerimos la prop name="?" en cada control
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      },
    });
  }; 
  handleCellChangeCheck=(index, columnId, value)=>{
    
    const newData = [...this.state.data];
    newData[index][columnId] = value;
    
    console.log(newData);
    //setData({  data: newData    });
    this.setState({ datalist: newData });
  }
  handleChangeDate = (e) => {
    //requerimos la prop name="?" en cada control
    //fechax.setMinutes(fechax.getMinutes() + fechax.getTimezoneOffset())
    //const fechai = cUtilFecha.toDateMy(fechax);
    //console.log(fechai);
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      },
    });
    
    //------- hacemos que cargue los dato
    //const fechai = cUtilFecha.toDateMy(e.target.value);
    const fechai = e.target.value;
    this.fetchData( fechai, this.state.form.id_medico );
  }; 
  //======================================================
  //----cargamos los datos desde la base de datos
  /*id_cita, id_medico, fecha,id_citaho, hora_ini, hora_fin, paciente, medico,motivo, 
    cita.obs, atendido_sn, id_estadoc, paciente.cel, paciente.foto_path1 */
  cargar_medicos = () => {
    let param = { 
    }
    
    axios2.post(process.env.REACT_APP_API_URL  + "cbo_medicos", param).then(res => {
        if(res.data.length>0){ 
            //---lega un array en este formato:
            //-- { value: 1, label: "Dr. Gerson Abner Villafuerte Oporto" }
            this.setState({medicos: res.data});

            //-- esrtablecemos la primera opcion como la elegida
            this.setState({
              form: {
                ...this.state.form,
                id_medico: res.data[0].value,
              },
            });

            //console.log("cargar_medicos");
            const fecha =  cUtilFecha.fechaHoy();
            //console.log("fecha:"+ fecha);
            const fechai = cUtilFecha.toDateMy( fecha);
            //console.log("fechai:" + fechai);
            this.fetchData( fechai, this.state.form.id_medico );

        }else{
            NotificationManager.error("No existen medicos");
        }

    }).catch(error => {
        alert("---- Existio un error ---");
        console.log(error);
        
    });
  };
  //-------------------para el select
  onHandleChangeOpt = (value) => {
    //this.setState({ selected_estado: value });
    console.log(value);
  };
  //----cargamos los datos desde la base de datos
  fetchData = (fechai, id_medico) => {
    let param = { 
      id_medico: id_medico,
      fechai: fechai,
    }
    console.log(param);

    axios2.post(process.env.REACT_APP_API_URL  + "citahoy_get_citas", param).then(res => {
        if(res.data.length>0){ 
            
            this.setState({ data: res.data});
            
        }else{
            this.setState({ data: []});
            //toast.error("No existen registros", {position: toast.POSITION.TOP_LEFT});
        }

    }).catch(error => {
        //if(error.response.status && error.response.status===400)
        alert("Bad Request");
        console.log(error);
        
    });
  };
 
  
  //---clic en el boton para buscar los elementos
  buscar_click=(e)=>{
    //console.log(this.state.fechai);
    if(this.state.fechai==""){
      toast.error("Error Notification !", {
        position: toast.POSITION.TOP_LEFT
      });
      return;
      
    }

    this.fetchData(this.state.form.fecha, this.state.form.id_medico);
    
  }
  
  //-------------------------------------
 
  render() {
    
    return (
      <div>

        <div className="content">
          <ToastContainer />

          <section className="content-header">
            <h2>
              <i className="fa fa-calendar"></i> Citas hoy
            </h2>
          </section>

          <div className="row">
            <div className="col-md-3">
              <div className="box">

                {/*  */}



                <div className="box-body">
                  <DatePicker
                    name="fecha"
                    value={this.state.form.fecha}
                    onChange={this.handleChangeDate}
                  />

                  <label className="col-md-3 col-form-label form-control-label">Médico</label>
                  <NuDropdown
                    options={this.state.medicos}
                    onChange={this.handleChangeForm}
                    value={this.state.form.id_medico}
                    name="id_medico"
                  />

                  <label className="col-md-3 col-form-label form-control-label">Estado</label>
                  <NuListCheck
                    options={this.state.estados}
                    name="id_estado"
                    value={this.state.id_estado}
                    onChange={this.handleChangeForm}
                  />
                </div>
              </div>
            </div>



            <div className="col-md-8">
              <div className="box">


                {/* /.box-header */}
                <div className="box-body">
                  <h3>{this.state.form.fecha}</h3>

                  <div className="row">
                    <div className="col-md-12">
                      <div className="table-responsive">

                        <table id="tabla01" className="table  table-bordered table-condensed table-hover"
                          width="100%" cellSpacing="0" cellPadding="0">
                          <thead>
                            <tr className="btn-default">
                              <th className="text-center" >Hora</th>
                              <th className="text-center" >Paciente</th>
                              <th className="text-center" >Doctor</th>
                              <th className="text-center" >Estado cita</th>
                              <th className="text-center" >Accion</th>

                            </tr>
                          </thead>
                          <tbody>
                            {/*id_cita, id_medico, id_paciente, fecha,id_citaho, hora_ini, hora_fin, paciente, medico,motivo, 
                                cita.obs, atendido_sn, id_estadoc, 
                                paciente.cel, paciente.foto_path1 */}
                            {this.state.data.map((item, index) => (
                              <tr>
                                <td>{item.hora_ini}</td>
                                <td>{item.paciente}<br/>
                                <i className="fa fa-phone"></i>&nbsp; <span style={{color: "rgb(142, 142, 142)"}}>{item.cel}</span>
                                </td>
                                <td>{item.medico}</td>
                                <td>
                                  <NuDropdown
                                    value={item.id_estadoc}
                                    options={this.state.estados}
                                    onChangeOption={this.onHandleChangeOpt}
                                  />
                                </td>
                                <td>
                                  <Link to={"/paciente_show/e/" + item.id_paciente} className="btn btn-warning float-right"
                                    style={{ background: 'chocolate' }}
                                  >
                                    <i className="fa fa-fw fa-list-alt"></i> Historia
                                  </Link>
                                </td>


                              </tr>
                            ))}
                          </tbody>
                        </table>

                      </div>
                    </div>
                  </div>
                </div>
                {/* /.box-body */}
              </div>
              {/* /.box -->*/}
            </div>
            {/*<!-- /.col -->*/}
          </div>





        </div>

        
        <CitaModal
          ref={this.citaRef}
          okModal={this.citaOkModal}
          cancelModal={this.citaCancelModal}
          value={this.state.form.id_cita}
        />


        {/*--- modal---- */}

      </div>
    );
  }
}
  
  //------------para que soporte la funcionalidad de parametros
export function withRouter(Children){
  return(props)=>{

     const match  = {params: useParams()};
     return <Children {...props}  match = {match}/>
 }
}
export default withRouter(CitasHoy);