import React, { useRef, useEffect, useState, useContext } from "react";

import { Link } from 'react-router-dom';
import AuthService from "../services/auth.service";
import { useNavigate } from "react-router-dom";
import { useHistory } from 'react-router-dom';


import InputBox from "../components/InputBox";
import { Outlet } from "react-router-dom";
import logo2 from "../assets/img/logo2.png";
import close from "../assets/img/close.png";

//--componentes
import Dropdown from 'react-bootstrap/Dropdown';
import { ContactSupportOutlined } from "@material-ui/icons";

import logo from '../assets/logo.png';

//--- CONTEXT API -----------
import { useStore, useDispatch } from "store/StoreProvider";
import { types } from "store/StoreReducer";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    //---------------- CONTEXT API ------------------
    const store = useStore();
    const dispatch = useDispatch();

    const {user, products} = store;


    //---toogle menu side
    const [menuSideShow, setMenuSideToogle] = useState(false);
    const [isOverPanel, setIsOverPanel] = useState(false);

    const [modalShow, setModalShow] = useState(false);
    const [input, setInput] = useState("");

    const [modalShow1, setModalShow1] = useState(false);
    const [input1, setInput1] = useState("");

    const navigate = useNavigate();
    
    //--------------para el timer---
    const [expirationDate, setExpirationDate] = useState("");
    let timer = useRef(null);
    let i=0;
    

    //---trabajo en el fondo
    /*useEffect (() => {
        refreshToken();
    }, [expirationDate]);*/
    //---------------------------------------------
    const refreshToken =async()=>{
        try {
            await AuthService.refreshToken().then(
              (response) => {
                
                if(response!=""){
                    setExpirationDate({"expirationDate": response});
                  
                }else{
                 //--correcto el registro  
                 navigate("/login");
                }
                //window.location.reload();
              },
              (error) => {
                console.log(error);
              }
            );
          } catch (err) {
            console.log(err);
        } 
    }
    
    //-----------------------------------
    const logout=(e)=>{
        e.preventDefault();
        console.log("logout");

        AuthService.logout();
        navigate("/login");
        //let history = useHistory();
        //history.push("/login");
        dispatch({type: types.authLogout})
    }
    //---------- NUEVO ------------------
    //----------------INPUTBOX EDITAR (1) ---------------------
    const editar=(e)=>{
        e.preventDefault();
        setModalShow1(true);
    }
    //---mostrar y ocultar sideshow
    const menuSideToogle_click=()=>{ 

        //-- 1ra modif
        //html className="menu-opened"
        const elem1 = document.querySelector("body");
        elem1.classList.toggle("mini-sidebar");


        if(menuSideShow){
            //-- esta en true
            console.log("true");
            setMenuSideToogle(false);  
        }else{
            console.log("false");
            setMenuSideToogle(true);  
        }

    }
    const menuSideToogleMovile_click=()=>{ 

        //-- 1ra modif
        //html className="menu-opened"
        const elem = document.querySelector("div.main-wrapper");
        elem.classList.toggle("slide-nav");
        //------2da modifi
        const elem2 = document.querySelector("div.sidebar");
        elem2.classList.toggle("opened");
        
        //------3da modifi
        const elem1 = document.querySelector("body");
        elem1.classList.toggle("menu-opened");

        const elem3 = document.querySelector("div.sidebar-overlay");
        elem3.classList.toggle("opened");
        

        if(menuSideShow){
            //-- esta en true
            console.log("true");
            setMenuSideToogle(false);  
        }else{
            console.log("false");
            setMenuSideToogle(true);  
        }

    }
    /*-------- */
    
    const onMouseEnterHandler=(e)=>{ 
        const elem1 = document.querySelector("body.mini-sidebar");
        if(elem1!=null){
            elem1.classList.add("expand-menu");
        }
        //e.target.style.background = 'red';
        // showbar
      
    }
    const onMouseLeaveHandler=(e)=>{ 
        const elem1 = document.querySelector("body.mini-sidebar");
        if(elem1!=null){
            elem1.classList.remove("expand-menu");
        }
        //e.target.style.background = 'blue';
      
    }
    /*-------- */
    const handleCancel1=()=>{ setModalShow1(false);   }
    const handleChange1=(e)=>{setInput1(e.target.value);  }
    //------------------INPUTBOX--REIMPRIME () ------------------------
    const reimprimir=(e)=>{
        e.preventDefault();
        setModalShow(true);
    }
    
    const handleOK=()=>{ 
        setModalShow(false);   
        let fileURL = process.env.REACT_APP_API_URL  + "certificado_reciboPDF/"+ input;
        let  windowFeatures = 'width=650,height= 700, left=100,top=100 ';
        window.open(fileURL,'',windowFeatures);
    }
    const handleOK1=()=>{ 
        setModalShow(false);   
        let fileURL = process.env.REACT_APP_API_URL  + "certificado_reciboPDF/"+ input;
        let  windowFeatures = 'width=650,height= 700, left=100,top=100 ';
        window.open(fileURL,'',windowFeatures);
    }
    const handleCancel=()=>{ setModalShow(false);   }
    const handleChange=(e)=>{setInput(e.target.value);  }
    //-----------------------------------------------------------
    return (
      <div>
        <div className="main-wrapper">
          <div className="header">
            <div className="header-left">
              <Link to="/dashboard" className="logo">
                <img src={process.env.REACT_APP_AFILES + user.e_logo}
                  alt="" width="35"
                  height="35" 
                  tag="" />
              </Link>
            </div>
            {/*barra de menu, este no tiene menu*/}

            {/*<a id="mobile_btn"
              className="mobile_btn float-left" href="#sidebar"
              onClick={(e) => menuSideToogleMovile_click()}>
              <i className="fa fa-bars"></i>
            </a>
            */}
            {/*---- */}
            <ul className="nav user-menu float-left">
              <li className="nav-item dropdown d-none d-sm-block">
                <span className="menu_text">
                  Rol: {user.rol_nom}
                </span>
              </li>
             





            </ul>
            {/*---- */}
            <ul className="nav user-menu float-right">
              <li className="nav-item dropdown d-none d-sm-block">
                <a href="#" className="dropdown-toggle nav-link" data-toggle="dropdown"><i className="fa fa-bell-o"></i> <span className="badge badge-pill bg-danger float-right">3</span></a>
                <div className="dropdown-menu notifications">
                  <div className="topnav-dropdown-header">
                    <span>Notifications</span>
                  </div>
                  <div className="drop-scroll">
                    <ul className="notification-list">
                      <li className="notification-message">
                        <a href="activities.html">
                          <div className="media">
                            <span className="avatar">
                              <img alt="John Doe" src="assets/img/user.jpg" className="img-fluid" tag="" />
                            </span>
                            <div className="media-body">
                              <p className="noti-details"><span className="noti-title">John Doe</span> added new task <span className="noti-title">Patient appointment booking</span></p>
                              <p className="noti-time"><span className="notification-time">4 mins ago</span></p>
                            </div>
                          </div>
                        </a>
                      </li>
                      <li className="notification-message">
                        <a href="activities.html">
                          <div className="media">
                            <span className="avatar">V</span>
                            <div className="media-body">
                              <p className="noti-details"><span className="noti-title">Tarah Shropshire</span> changed the task name <span className="noti-title">Appointment booking with payment gateway</span></p>
                              <p className="noti-time"><span className="notification-time">6 mins ago</span></p>
                            </div>
                          </div>
                        </a>
                      </li>
                      <li className="notification-message">
                        <a href="activities.html">
                          <div className="media">
                            <span className="avatar">L</span>
                            <div className="media-body">
                              <p className="noti-details"><span className="noti-title">Misty Tison</span> added <span className="noti-title">Domenic Houston</span> and <span className="noti-title">Claire Mapes</span> to project <span className="noti-title">Doctor available module</span></p>
                              <p className="noti-time"><span className="notification-time">8 mins ago</span></p>
                            </div>
                          </div>
                        </a>
                      </li>
                      <li className="notification-message">
                        <a href="activities.html">
                          <div className="media">
                            <span className="avatar">G</span>
                            <div className="media-body">
                              <p className="noti-details"><span className="noti-title">Rolland Webber</span> completed task <span className="noti-title">Patient and Doctor video conferencing</span></p>
                              <p className="noti-time"><span className="notification-time">12 mins ago</span></p>
                            </div>
                          </div>
                        </a>
                      </li>
                      <li className="notification-message">
                        <a href="activities.html">
                          <div className="media">
                            <span className="avatar">V</span>
                            <div className="media-body">
                              <p className="noti-details"><span className="noti-title">Bernardo Galaviz</span> added new task <span className="noti-title">Private chat module</span></p>
                              <p className="noti-time"><span className="notification-time">2 days ago</span></p>
                            </div>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="topnav-dropdown-footer">
                    <a href="activities.html">View all Notifications</a>
                  </div>
                </div>
              </li>
              <li className="nav-item dropdown d-none d-sm-block">
                <a href="javascript:void(0);" id="open_msg_box" className="hasnotifications nav-link"><i className="fa fa-comment-o"></i> <span className="badge badge-pill bg-danger float-right">8</span></a>
              </li>
              <li className="nav-item dropdown has-arrow">
                <a href="#" className="dropdown-toggle nav-link user-link" data-bs-toggle="dropdown">
                  <span className="user-img">
                    {user?.foto_path1.length > 0 &&
                      <img src={process.env.REACT_APP_AFILES + user.foto_path1}
                        className="rounded-circle"
                        alt="Usuario"
                        width="40" tag="" />

                    }
                    { /*<span className="status online"></span> */}
                  </span>
                  <span>{user?.username}</span>
                </a>
                <div className="dropdown-menu">
                  <a className="dropdown-item" href="profile.html">My Profile</a>
                  <a className="dropdown-item" href="edit-profile.html">Edit Profile</a>
                  <Link to="/cambiarclave" className="dropdown-item">
                    <i className="fa fa-key"></i> Cambiar clave
                  </Link>
                  <a className="dropdown-item" href="settings.html">Settings</a>
                  <button className="dropdown-item" onClick={logout} >Cerrar sesión</button>
                </div>
              </li>


              <li className="nav-item dropdown has-arrow">
                {/*<Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                        Dropdown Button
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                        <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                        <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown> */}
              </li>
            </ul>
            <div className="dropdown mobile-user-menu float-right">
              <a href="#" className="nav-link dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                <i className="fa fa-ellipsis-v"></i></a>
              <div className="dropdown-menu dropdown-menu-right">
                <a className="dropdown-item" href="profile.html">My Profile</a>
                <a className="dropdown-item" href="edit-profile.html">Edit Profile</a>
                <a className="dropdown-item" href="settings.html">Settings</a>

                <a className="dropdown-item" href="login.html">Logout</a>
              </div>
            </div>
          </div>
          
          {/*================================================================ */}

          <div className="page-wrapper1" 
                style={{ minHeight: "954px", backgroundColor: "#ecf0f5", marginTop: "50px" }}>
            {/* NAV */}
            <nav className="py-1 bg-light border-bottom" >
              <div className="d-flex justify-content-center ">
                <ul className="nav nu-menu" style={{ fontSize: "16px"}}>
                  <li className="nav-item">
                    <Link to="/citas_tabla" className="nav-link link-plomo px-2" data-toggle="dropdown">
                      <i className="fa fa-calendar"></i> Citas
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/citas_hoy" className="nav-link link-plomo px-2" >
                      <i className="fa fa-calendar"></i> Citas Hoy
                    </Link>
                  </li>
                  <li className="nav-item ">
                    <Link to="/pacientes" className="nav-link link-plomo px-2" >
                      <i className="fa fa-users"></i> Pacientes
                    </Link>
                  </li>

                  <li className="nav-item ">
                    <Link to="/menu_rep" className="nav-link link-plomo px-2" >
                      <i className="fa fa-file"></i> Reportes
                    </Link>
                  </li>

                  <li className="nav-item ">
                    <Link to="/panel" className="nav-link link-plomo px-2" >
                      <i className="fa fa-th"></i> Panel
                    </Link>
                  </li>
                  
                </ul>
                
              </div>
            </nav>

            {/* incrustar */}
            <Outlet />
            {/* incrustar */}


          </div>
 
          
        </div>

        <InputBox modalShow={modalShow} handleChange={(e) => handleChange(e)}
          titulo="Entrada"
          mensaje="Introduzca número de certificado"
          handleOK={handleOK}
          handleCancel={handleCancel} />

        <InputBox modalShow={modalShow1} handleChange={(e) => handleChange1(e)}
          titulo="Entrada"
          mensaje="Número de certificado"
          handleOK={handleOK1}
          handleCancel={handleCancel1} />



      </div>
    )
}