import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import cUtilFecha from 'helpers/cUtilFecha';

import './styles.scss'

const anyos=[ 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030 ];

const DatePicker = ({value, onChange, ...props}) => {
  // 
  
  //Examples of ISO format: YYYY-MM-DD
  const fechax = new Date(value);
  //--compensar la diferencia por la zona horaria
  fechax.setMinutes(fechax.getMinutes() + fechax.getTimezoneOffset())

  //console.log("fechax inicial: "+ fechax );
  //const mes = fechax.getMonth()+1;
  
  const dia = fechax.getDate();
  const mes = fechax.getMonth(); //para indice de array desde 0
  const anyo = fechax.getFullYear();
  // [{"value":1, "label":"Miércoles"},...]
  //const array_dias_mes = cUtilFecha.arrayDiasMes(mes, anyo);
  const mes_literal = cUtilFecha.mes_literal_peq(mes);
  const dia_literal = cUtilFecha.dia_literal(fechax.toDateString());

  //-------------------------------------------------------------
  const mesAnterior=(e)=>{
    let fecha_new = cUtilFecha.mes_anterior(fechax);
    fecha_new = cUtilFecha.toDateMy(fecha_new);
    
    let target = {"name":props.name, "value": fecha_new }
    e.target= target;
    onChange(e);
  }
  //-------------------------------------------------------------
  const mesSiguiente=(e)=>{
    
    let fecha_new = cUtilFecha.mes_siguiente(fechax);
    fecha_new = cUtilFecha.toDateMy(fecha_new);
    
    let target = {"name":props.name, "value": fecha_new }
    e.target= target;
    onChange(e);
  }
  //-------------------------------------------------------------
  const diaAnterior=(e)=>{
    e.preventDefault();
    let fecha_new = cUtilFecha.addDays(fechax,-1);
    fecha_new = cUtilFecha.toDateMy(fecha_new);
    
    let target = {"name":props.name, "value": fecha_new }
    e.target= target;
    onChange(e);
  }
  //-------------------------------------------------------------
  const diaSiguiente=(e)=>{
    e.preventDefault();
    let fecha_new = cUtilFecha.addDays(fechax,1);
    fecha_new = cUtilFecha.toDateMy(fecha_new);
    
    let target = {"name":props.name, "value": fecha_new }
    e.target= target;
    onChange(e);
  }
  //-------------------------------------------------------------
  const anyoAnterior=(e)=>{
    e.preventDefault();
    let fecha_new = cUtilFecha.addYears(fechax,-1);
    fecha_new = cUtilFecha.toDateMy(fecha_new);
    
    let target = {"name":props.name, "value": fecha_new }
    e.target= target;
    onChange(e);
  }
  //-------------
  const anyoSiguiente=(e)=>{
    e.preventDefault();
    let fecha_new = cUtilFecha.addYears(fechax,1);
    fecha_new = cUtilFecha.toDateMy(fecha_new);
    
    let target = {"name":props.name, "value": fecha_new }
    e.target= target;
    onChange(e);
  }
  //-------------------------------------------------------------
  return (
    <div className="form-group grupo">
      { /* JSON.stringify( fechax) */ }
      <div  className="datedropper primary picker-fxs picker-focus" 
            id="datedropper-0"
            style={{  left: "50px", position: "initial", zIndex: 0,
                      transform: "translateX(0px)", margin: "8px auto 0px",
                      maxWidth: "180px",
                      width: "auto" }}
            >

        <div className="picker">
          {/*-------------------- */}
          <ul className="pick pick-m" data-k="m">
              
            <li class="pick-sl" value="3">{ mes_literal }</li>
            <div className="pick-arw pick-arw-s1 pick-arw-l" onClick={(e)=> mesAnterior(e) } ><i className="fa fa-chevron-left"></i></div>
            <div className="pick-arw pick-arw-s1 pick-arw-r" onClick={(e)=> mesSiguiente(e) }><i className="fa fa-chevron-right"></i></div>
          </ul>

          {/*-------------------- */}
          <ul className="pick pick-d" data-k="d">

            <li class="pick-sl" value={dia}>{ dia }<span>{dia_literal}</span></li>
              
            <div className="pick-arw pick-arw-s1 pick-arw-l" onClick={(e)=> diaAnterior(e) }><i className="fa fa-chevron-left"></i></div>
            <div className="pick-arw pick-arw-s1 pick-arw-r" onClick={(e)=> diaSiguiente(e) }><i className="fa fa-chevron-right"></i></div>
          </ul>

          {/*-------------------- */}
          <ul className="pick pick-y" data-k="y">
            <li class="pick-sl" value={ anyo } >{ anyo }</li>
              
            <div className="pick-arw pick-arw-s1 pick-arw-l" onClick={(e)=> anyoAnterior(e) }><i className="fa fa-chevron-left"></i>.</div>
            <div className="pick-arw pick-arw-s1 pick-arw-r" onClick={(e)=> anyoSiguiente(e) }><i className="fa fa-chevron-right"></i></div>
            <div className="pick-arw pick-arw-s2 pick-arw-l"><i className="fa fa-chevron-left"></i>x</div>
            <div className="pick-arw pick-arw-s2 pick-arw-r"><i className="fa fa-chevron-right"></i></div>
          </ul>
          
        </div>
      </div>
    </div>
   );

}

export default DatePicker;