import { useEffect, useState, useMemo } from "react";


const NuPaginationItem=({ text='', active=false, disabled=false, onClickItem, icon='' })=>{
    let classActive= active ? 'active' : '';
    let classDisabled= disabled ? 'disabled' : '';
    const miClassName = ` ${classActive } ${classDisabled}`;

    const handleClick =()=>{
        if(!disabled){
            onClickItem(text)
        }
    }
    //==============================================RENDER 
    return(
        <li  
            onClick={(e)=>handleClick()} 
            className={miClassName }
        >
            <span role="button" tabIndex="0"  className={classDisabled }>
            { icon=='' ? text : <i className={icon}></i>}            
            </span>
            
        </li>
    )
}

export default NuPaginationItem;