import React from 'react'
import './styles.css';


import cUtilFecha from "helpers/cUtilFecha";
//npm install date-fns --save
/*
// event source that contains custom events on the scope 
$scope.events = [
  {title: 'All Day Event',start: new Date(y, m, 1)},
  {title: 'Long Event',start: new Date(y, m, d - 5),end: new Date(y, m, d - 2)},
  {id: 999,title: 'Repeating Event',start: new Date(y, m, d - 3, 16, 0),allDay: false},
  {id: 999,title: 'Repeating Event',start: new Date(y, m, d + 4, 16, 0),allDay: false},
  {title: 'Birthday Party',start: new Date(y, m, d + 1, 19, 0),end: new Date(y, m, d + 1, 22, 30),allDay: false},
  {title: 'Click for Google',start: new Date(y, m, 28),end: new Date(y, m, 29),url: 'http://google.com/'}
];
 */
class NuCalSemana extends React.Component {
  state = {
    currentMonth: new Date(),
    selectedDate: new Date(),
  };

  renderHeader() {
    const dateFormat = "MMMM YYYY";

    return (
      <div className="header row flex-middle">
        <div className="col col-start">
          <div className="icon" onClick={this.prevMonth}>
            chevron_left
          </div>
        </div>
        <div className="col col-center">
          <span>{  }</span>
        </div>
        <div className="col col-end" onClick={this.nextMonth}>
          <div className="icon">chevron_right</div>
        </div>
      </div>
    );
  }
  //----------------------------------------------------------------------------
  generateDays() {
    const dateFormat = "dddd";
    const days = [];

    //let startDate = cUtilFecha.startOfWeek(this.state.currentMonth);
    let startDate = cUtilFecha.startOfWeek(this.props.fecha );
    //console.log("startDate"+ startDate.toDateString());
    
    for (let i = 0; i < 7; i++) {
      let fecha = cUtilFecha.addDays(startDate, i);
      let dia = fecha.getDate(); //dia 1,2,...
      let dia_literal = cUtilFecha.dia_literal_peq(fecha);// Lun
      days.push({"dia_literal": dia_literal, "dia": dia, "fecha": fecha } );
      //console.log (i);
    }

    return days;
  }
  //-----------------------------------------------
  generateHours() {
    const dateFormat = "dddd";
    const horas = [];
    //const { horaIni, horaFin, intervalo } = this.props;
    //--new Date(year,month,day,hours,minutes,seconds)
    let horai = new Date(2023,3,14,9,0,0);
    let horaf = new Date(2023,3,14,17,0,0);

    //----le sumaremos 30 minutos 
    let  cn=0;
    while (cn< 10) {
      //let hora_new = moment(horai).add(30, 'm').format('HH:MM');
      horai = cUtilFecha.addMinutes(horai, 30);

      horas.push( {"hora": horai.getHours(),  "minuto": horai.getMinutes() });
      cn++;
    }

    return horas;
  }
  //----------------------------------------------------------------------------  
  // desde horai, horaf, con intervalo: 1:00
  /*renderRows(days) {
    const { horaIni, horaFin, intervalo } = this.props;

    let horai = new Date(horaIni);
    let horaf = new Date(horaFin);

    const rows = [];

    let horasArr = [];
    let formattedDate = "";

    while (horai <= horaf) {

      for (const item of days) {

      }
      //------------------------------
      for (let i = 0; i < 7; i++) {
        let proximaHora = (horai + 1);
        let textoCelda = hora+":00 ";
        horai = proximaHora;
        
        
        horasArr.push(
          <th
            className={`col cell ${
              !dateFns.isSameMonth(day, monthStart)
                ? "disabled"
                : dateFns.isSameDay(day, selectedDate) ? "selected" : ""
            }`}
            key={day}
            onClick={() => this.onDateClick(dateFns.parse(cloneDay))}
          >
            <span className="number">{formattedDate}</span>
            <span className="bg">{formattedDate}</span>
          </th>
        );
        day = dateFns.addDays(day, 1);
      }
      rows.push(
        <tr className="row" key={day}>
          {horasArr}
        </tr>
      );
      horasArr = [];
    }
    return <div className="body">{rows}</div>;
  }
  */
  
  nextWeek = () => {  
    const auxd = cUtilFecha.nextWeek(this.props.fecha);
    this.setState({
      currentMonth: auxd,
    });
  };

  prevWeek = () => {
    const auxd = cUtilFecha.prevWeek(this.props.fecha);
    this.setState({
      currentMonth: auxd
    });
  };

  cellClick=(fecha, hora, minuto)=>{
    console.log("fecha" + fecha);
    console.log("hora" + hora);
    console.log("minuto" + minuto);
  }
  //---------------------------------------------------------
  render() {
    let days = this.generateDays();
    let hours = this.generateHours();

    return (
      <div id="calendar" style={{ width: "100%"}} className="cal-context">
        { //JSON.stringify(hours)
        }
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Hora</th>
              {
                days.map( (item, index)=>(
                  <th key={index}>{item.dia_literal} {item.dia}</th>
                ))
              }
              
              {/*<th>Martes</th>
              <th>Miércoles</th>
              <th>Jueves</th>
              <th>Viernes</th>
              <th>Sábado</th>
              <th>Domingo</th>*/}
            </tr>
          </thead>
          <tbody>
            {
              hours.map( (item, index)=>(
                <tr key={index}>
                  <td>
                    { item.hora + ":" + item.minuto }
                  </td>
                  <td onClick={()=> this.cellClick(days[0]["fecha"],item.hora, item.minuto)}></td>
                  <td onClick={()=> this.cellClick(days[1]["fecha"],item.hora, item.minuto)}></td>
                  <td onClick={()=> this.cellClick(days[2]["fecha"],item.hora, item.minuto)}></td>
                  <td onClick={()=> this.cellClick(days[3]["fecha"],item.hora, item.minuto)}></td>
                  <td onClick={()=> this.cellClick(days[4]["fecha"],item.hora, item.minuto)}></td>
                  <td onClick={()=> this.cellClick(days[5]["fecha"],item.hora, item.minuto)}></td>
                  <td onClick={()=> this.cellClick(days[6]["fecha"],item.hora, item.minuto)}></td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    );
  }
}

export default NuCalSemana;