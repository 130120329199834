import React from 'react';
  import axios2 from 'services/axios2';

  import {useParams, Link,hashHistory} from 'react-router-dom';
  import { ToastContainer, toast } from 'react-toastify';
  import NuDropdown from 'components/nubox/NuDropdown';
  import ImageUploadSingle from 'components/nubox/ImageUploadSingle';

  //import listac from '../assets/img/lista2.png';
  import {
    Table,
    Button,
    Container,
    Modal,
    ModalHeader,
    ModalBody,
    FormGroup,
    ModalFooter,
    ToastHeader,
  } from 'reactstrap';

  const formInitial = {
    idd:0,
    ope:'n',
    
    id_hora:'',
    hora:'',
    estado:'',     
  };
  //$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
  class ModalCita_hora extends React.Component {
    state = {
      modalInsertar:false,
      form: formInitial,
      
      grupos:[], /* combo grupos */
    };
    //------------------------------------------------------
    componentDidMount() {
      console.log('componentDidMount Modal');
      //--limpiamos el array de prueba
          
      //this.cargar_grupos();
          
      //---------
      this.limpiar();
    }
    //-------------------
    limpiar = () =>{
      this.setState({form: formInitial});
    }
    //----------------especial, actualiza valores del form -------------------------------
    handleChangeForm = (e) => {
      //requerimos la prop name='?' en cada control
      this.setState({
        form: {
          ...this.state.form,
          [e.target.name]: e.target.value,
        },
      });
    };
    //----cargamos combo
    cargar_grupos = async() => {
      let param = { }
      await axios2.post('cbo_grupos', param).then(res => {
          if(res.data.length>0){ 
              this.setState({grupos: res.data});
          }else{
            toast.error('No existen datos');
          }
      }).catch(error => {
          alert('---- Existio un error ---');
          console.log(error);
      });
    };
    //======================================================
    showModal = () => {
      //setModalOpen(true);
      this.setState({ modalInsertar: true});
    }
    closeModal = () => {
      //setModalOpen(true);
      this.setState({ modalInsertar: false});
    }
    //---------------
    nuevo =() =>{
      console.log('nuevo');
      //this.limpiar();
      
      this.setState({
        form: {
          ...formInitial,
          id: 0,
          ope:'n'
        },
        modalInsertar: true,
      });
    }
    //---------------
    editar =(idd)=>{
      console.log('editar');
      let param = { idd: idd, ope:'f'  }
      
      axios2.post( 'hora_store' , param).then(res => {
        console.log(res.data);

          if(res.data !== null){               
              //---asignamos los valores
              this.setState({
                form: {
                  ...res.data,
                  'idd': idd,
                  'ope': 'e',
                },
                modalInsertar: true, /* show modal */
              });

              //----
          }else{
            toast.error('No existen datos');
          }

      }).catch(error => {
          alert('---- Existio un error ---');
          console.log(error);
          
      });
    }
    //----------------------------
    eliminar =(idd)=>{
      console.log('eliminar');
      let param = { 
        idd: idd,
        ope:'x'
      }
      //console.log(param);
      axios2.post( 'hora_store', param).then(res => {
        console.log(res.data);
  
          if(res.data.exito===1){ 
              this.limpiar();
              this.closeModal();
              toast.success('Eliminado con exito');
              this.props.okModal(); //callback
          }else{
            toast.error('Error al eliminar registro', {
              position: toast.POSITION.TOP_RIGHT
            });
          }
  
      }).catch(error => {
          alert('---- Existio un error ---');
          console.log(error);
          
      });
    }
    //----------------------------
    validar = () =>{
      if( this.state.form.id_espe===0){
        toast.error( 'Especialidad no valido', {position: toast.POSITION.TOP_RIGHT});
        return true;
      }
    }
    /************************************************ */
    okImage = (filename) =>{    
      this.setState({ form:{...this.state.form, foto_path1: filename}}); 
    }
    cancelImage = () =>{
      //---ocultamos la ventana
      this.setState({ form:{...this.state.form, foto_path1: '' }}); 
    }
    //----------------------------
    formSave = () => {
      /*let error = this.validar();
      if(error===true){
        return;
      }*/
      
      let param = this.state.form;
      //console.log(param);
  
      axios2.post('hora_store', param).then(res => {
          let response = res.data;
        
          if(response.exito===1){ 
              
            toast.success( response.mensaje, {position: toast.POSITION.TOP_RIGHT});
            //---llamamos a la funcion externa
            this.props.okModal();
            this.closeModal();
          }else{
  
            toast.error( response.mensaje, {position: toast.POSITION.TOP_RIGHT});
          }
  
      }).catch(error => {
          alert('Bad Request');
          console.log(error);
          
      });
  
    }
    //---------------------------
    render() {
    
      return (
        <div>
          <ToastContainer />
          
          {/*--- modal---- */}
          <Modal isOpen={this.state.modalInsertar} toggle={this.closeModal} >
            <ModalHeader toggle={this.closeModal} 
              className= { (this.state.form.ope==='n'? 'header-verde':'header-naranja') + ' ' + (this.state.form.ope==='n'? "color:'#45b545'":"color:'#343b43'")  } 
            >
              <h4 className="modal-title">
              { this.state.form.ope==='n'
                ? "Nuevo"
                : "Editar"
              }
              </h4>
              { JSON.stringify(this.state.form ) }
            </ModalHeader>
  
            <ModalBody style={{background: "#F7F7F7"}}>
                                
        <form  method='POST'>
        
            <div className='top_title2'>
              <h4 className=''>Bloque 0</h4>
            </div>
            
                <div className='form-group row'>
                    <label htmlFor='id_citaho' className='col-lg-3 control-label'><b>Cod.</b></label>
                    <div className='col-lg-9'>
                        
                    <input
                      type='text'
                      name='id_hora'
                      value={this.state.form.id_hora}
                      onChange={this.handleChangeForm}
                      className='form-control'
                      readOnly
                    />
      
                    </div>
                </div>
                <div className='form-group row'>
                    <label htmlFor='hora' className='col-lg-3 control-label'><b>hora</b></label>
                    <div className='col-lg-9'>
                        
                    <input
                      type='text'
                      name='hora'
                      value={this.state.form.hora}
                      onChange={this.handleChangeForm}
                      className='form-control'
                    />
      
                    </div>
                </div>
                <div className='form-group row'>
                    <label htmlFor='estado' className='col-lg-3 control-label'><b>estado</b></label>
                    <div className='col-lg-9'>
                                             
                      <select className="form-control select" 
                              name='estado'
                              value={this.state.form.estado}
                              onChange={this.handleChangeForm}
                              >
                          <option value="0">Pendiente</option>
                          <option value="1">Activo</option>
                      </select>

                    </div>
                </div></form>
        
              
            </ModalBody>
            <ModalFooter>
              <Button color='primary' onClick={ this.formSave}>Guardar</Button>{' '}
              <Button color='secondary' onClick={this.closeModal}>Cancel</Button>
            </ModalFooter>
          </Modal>
        </div>
      );
    }
  }
  
  //------------
  export default ModalCita_hora;

  