import React from "react";
import {Link,hashHistory} from 'react-router-dom';
import axios2 from 'services/axios2';
import { withRouterJim } from "router/withRouterJim";
import AuthService from "services/auth.service";
import confirmService from 'components/confirmService'; 
import { ToastContainer, toast } from 'react-toastify';

import { NotificationContainer, NotificationManager } from 'react-notifications';
import {
  Table,
  Button,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  ModalFooter,
} from "reactstrap";

import PagoModal from "views/_box/PagoModal";

//type:'numeric'
const columnas=[
    {  field:'id', title: 'Cod. Cert.', type:'numeric'},
    {  field:'cliente', title: 'Cliente'},
    {  field:'nombre', title: 'Nombre'},
    {  field:'lote', title: 'Lote'},
    {  field:'elem', title: 'Elementos'},
]

const data = [
  { id_receta:0, fecha:'', id_paciente:0, nombre:'', foto_path1:'', detalle:''},
];



class RecetaList extends React.Component {
  
  modalRef = React.createRef();//create ref

  state = {
    data: data,
    id_paciente:'', /*id_paciente */
   
    form: {
      idd: "",
      ope: "",
      
    },
  };
  
  componentDidMount() {
    //--limpiamos el array de prueba
    this.setState({data:[]});

    const date = new Date();
    const defaultValue = date.toLocaleDateString('en-CA');
  
    //this.setState({fechai: defaultValue}); 

    let id = this.props.store.paciente.id_paciente;
    console.log("id_paciente:" + id);
    this.setState({id_paciente: id});

    this.fetchData(id);
  }

  
  //----cargamos los datos desde la base de datos
  fetchData = ( id ) => {
    let param = { 
      id_paciente: id,
    }
    
    axios2.post("receta_get_list", param).then(res => {

        if(res.data.data.length>0 ){             
            this.setState({
              data: res.data.data,
            });
        }else{
            NotificationManager.error("No existen registros");
        }

    }).catch(error => {
        NotificationManager.error("Bad Request");
        //this.setState({ errors: error })
        console.log(error);
    });
  };
 
  //------------------------------------------------------------------
  handleChange = (e) => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      },
    });
  };

  //============================================================
  eliminar_click = (item) =>{
    console.log("eliminar");
    console.log(item);
    //--------------------------------
    confirmService.show({
      message: 'Esta seguro de eliminar?',
      title: 'Confirmar',
      icon: 'fa fa-question-circle',
      classHeader: 'bg-default1 ',
      classButton: 'btn btn-danger',
      accept: () => {
        let idd = item.id_receta;
        this.eliminar(idd);
      },
      reject: () => {
          console.log('cancel');
      },
      acceptLabel: 'Aceptar',
      rejectLabel: 'Cancelar'
    })

  }
  //-------------------------
  eliminar =(idd)=>{
    console.log("eliminar");
    let param = { 
      idd: idd,
      ope:'x'
    }
    //console.log(param);
    axios2.post( "receta_store", param).then(res => {
        if(res.data.exito===1){ 
            //this.limpiar();
            toast.success("Eliminado con exito");
        }else{
          toast.error("Error al eliminar registro", {
            position: toast.POSITION.TOP_RIGHT
          });
        }
    }).catch(error => {
        alert("---- Existio un error ---");
        console.log(error);
        
    });
  }
  //============================================================
  //--------------------------------------------------------------------
  render() {
    
    return (
      <>
        <Container style={{ marginTop: "50px", minHeight: "700px" }}>

          <ToastContainer />
          <br />
          <div className="row">

            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">


              {/*  Authentication card end ---------------------------------------------  */}
              <div className="card">
                <div className="card-header">
                  <Link to="/receta/n/0" className="btn btn-info pull-right ">Nueva receta</Link>
                  
                  <h3>Recetas</h3>
                  
                </div>

                <div className="card-body">

                <div className="alert alert-primary" role="alert">
                  <b>Nota:</b> .
                </div>
                  <Table>
                    <thead>
                      
                      <tr style={{backgroundColor: "#e3e3e3"}}>
                        <th>Nro.Receta</th>
                        <th>Fecha</th>
                        <th>Paciente</th>
                        <th>Detalle</th>
                        
                        <th>Accion</th>
                      </tr>
                    </thead>

                    <tbody>
                      
                      {this.state.data.map((item,index) => (
                        <tr key={index}>
                          <td>{item.id_receta}</td>
                          <td>{item.fecha}</td>
                          <td>{item.nombre}</td>
                          <td>{item.detalle}</td>
                          
                          <td>
                            <Link className="btn btn-warning" to={`/receta/e/${item.id_receta}`}> <i className="fa fa-edit"></i></Link>
                            <Button color="danger" onClick={ () => this.eliminar_click(item) }><i className="fa fa-trash"></i></Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                 
                </div>

                <div className="card-footer">
                  <div className="text-center">

                    

                  </div>
                </div>
              </div>

           
            </div>{/*  end    */}

          </div>

          <PagoModal
            ref={this.modalRef}
            okModal={ this.okModal}
            closeModal={ this.closeModal}
            
          />

        </Container>

      </>
    );
  }
}

export default withRouterJim(RecetaList);