import React from "react";

/*const techCompanies = [
    { "Apple", "Facebook"},
];*/

// Reusable Dropdown
class NuDropdownSimple extends React.Component { 
    // selectValue => current selected value
    // options => choices in the dropdown
    // setFilteredData => function to change the selectValue
    handleChange = e => {
     this.props.onChangeOption(e.target.value);
    }; 
    //=======================================
    render() {
       const { selectValue, options } = this.props;
       
       return (
          <div className='ddown'>
             <select value={selectValue} onChange={this.handleChange} >

               {/*<option value={""} hidden>Seleccion</option>*/}
                {options.map(item => {
                return (
                    <option key={item} value={item}>
                    {item}
                    </option>
                );
                })}
             </select>
          </div>
        );
     }
};
export default NuDropdownSimple;