import React,{ useState } from "react"
import { Link } from "react-router-dom";

import './styles.css';

/*type CellProps = {
    value: any,
    column : Column,
    rowIndex : number,
    width : ?number,
    style: Object,
    rowHover: boolean,
    type: 'element' | 'group-header' | 'aggregate',
    content: any,
    originalIndex: number
  }
  
  type CellState = {
    hover : boolean
  }*/

// field: "id_cliente", text: "Cod.",  tipo: 'text', className: "name",  align: "left",sorting: true,
// renderCell
// <td key={index2}>{ item[col.field]}- {col.dataType} </td>
const NuCell =({itemRow, column, onClicButton1,onClicButton2,onClicButton3,onClicButton4, width, style, rowHover,type, content}) =>{
    //  const { role } = props

    let component;
    //switch(role) {
    switch( column.dataType ) {
      case 'text':
        component = itemRow[column.field];
        break
      case 'number':
        component = itemRow[column.field];
        break
      
      case 'custom':
        component = column.renderCell(itemRow, onClicButton1,onClicButton2,onClicButton3,onClicButton4);
        break
              
      case 'link':
        component = <Link to="" ></Link>
        break

      /* estos necesitan onChange y value
      case 'date':
        component = <input type="date" value />
        break

      case 'inputText':
        component = <input type="text" value />
        break*/
      case 'Image':
        component = <img src="" alt="" />
        break
      default:
        component = "Error(578) col:" + column.dataType
        break 
    }
  

    return(
        <td style={{textAlign: column.align}}>
            {component}    
        </td>
    );
}
export default NuCell;