import { RestaurantMenu } from "@material-ui/icons";
import React,{ useState } from "react"
import { json } from "react-router";
import './styles.css';

//sorting = true or false
//1= ASC
//2= DESC
const NuCol =({field, text, sorting, className, sort, onChangeSort}) =>{
        
    const onClickTh =()=>{
        if(sorting===false) return ;
        //si se cambia de columna se inicia en 1= sort_up sort_asc
        console.log("field:" + field);
        console.log("json (sort):" + JSON.stringify(sort));

        let newDir = '';
        if(field!==sort.field){ 
            newDir= 'asc';
            onChangeSort({field:field,dir:newDir});
        }
        else{
            switch (sort.dir) {
                case '':
                    newDir ='asc'; break;
                case 'asc':
                    newDir ='desc'; break;
                case 'desc':
                    newDir =''; break;
                default:
                    newDir= ''; break;
            }
            onChangeSort({field:field,dir:newDir});
        }
    } 

    return(
        <>
            <th key={ field } onClick={() => onClickTh()} className={className} >
            { text }
            {/*  verifica si esta configurado la columna para ordenar  */}
            {sorting && field===sort.field &&
                <span className= 'nu-sort-indicator-icon' >
                    
                {sort.dir==='asc' && <span className="nu-icon nu-icon-asc" unselectable="on" role="presentation"></span> }
                {sort.dir==='desc' && <span className="nu-icon nu-icon-desc" unselectable="on" role="presentation"></span> }
                
                </span>

            }
            </th>         
        </>
    );
}
export default NuCol;