import React from "react";
import './styles.css';
/*const techCompanies = [
    { value: 1, label: "Apple",  icon:'fa fa-home', color:'#ccc' },
    { value: 2, label: "Facebook", icon:'fa fa-circle', color:'#abc' },
];*/

// Reusable Dropdown
class NuListRadio extends React.Component { 
    // selectValue => current selected value
  
    state = {
      selectedOption: 0,/* lista de seleccioados */
    }
    
    // Return classes based on whether item is checked ----------------------
    isChecked = (item) =>{
      //return (this.state.selectedOption===String(item.value)) ? "checked-item" : "not-checked-item";
      return (this.props.value === String(item.value)) ? "checked-item" : "not-checked-item";
    }

    setHorizontal =(value)=>{
      return (value == true ) ? "d-inline-block" : "";
    }
    // Add/Remove checked item from list ------------------------------------
    handleCheck = (event) => {
      //---actualizamos 
      this.setState({
        selectedOption: event.target.value
      });
    };

    // Generate string of checked items --------------------------------------
    ToString = () => {
      const selectedOptions = this.state.selectedOption.length
      ? this.state.selectedOption.reduce((total, item) => {
          return total + ", " + item;
        })
      : "";
      return selectedOptions;
    }
    // ------------------------------------------------------------------------
    onClickItem = (e,item) => {
      e.target.name = this.props.name;
      e.target.value = item.value;
      this.props.onChange(e);
    }; 
    //=======================================
    render() {
       const { name, value, options, isHorizontal } = this.props;

       //const miClassName = `nu-sidenav_item ${ value===item.value? "menu-item--active":'' }`;
       
       return (
        <div className="side-menur">
          {/* JSON.stringify( this.state.selectedOption ) */}
      
        
          
              {options.map( (item, index) => {
                return (
                  
                      
                    <div className={ `bloque ` + this.setHorizontal( isHorizontal) } key={index} 

                    >
                        
                      { item.iconColor!=""? 
                        <>
                        <i className={ item.icon} style={{color: item.iconColor }}></i>&nbsp;&nbsp;
                        </> :""
                      }

                     

                      <label  className={ this.isChecked(item) }
                              style={{ cursor: "pointer",color: item?.color }}
                      >
                          <input  type="radio" 
                              id={item.value}
                              value={item.value}
                              checked={ String(value) === String(item.value) }
                              onChange={(e)=> this.onClickItem(e, item) } 
                          />&nbsp;&nbsp;
                      {item.label}</label>
                      
                    </div>
                 
                );
              })}
          
        </div>
        );
     }
};
export default NuListRadio;