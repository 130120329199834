import React from "react";

import axios2 from 'services/axios2';

import {useParams} from "react-router-dom";
import {Link,hashHistory} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import  NuDropdown  from "components/nubox/NuDropdown";
import { NuTextInput } from "components/nubox/NuTextInput";
import  NuCMes  from "components/nubox/NuCMes";



import Child from "views/cita_all/Child";
import CitaModal from "views/cita_all/CitaModal";
//import listac from '../assets/img/lista2.png';
import confirmService from 'components/confirmService';

import { NotificationContainer, NotificationManager } from 'react-notifications';
import {
  Table,
  Button,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  ModalFooter,
  ToastHeader,
} from "reactstrap";

import cUtilFecha from "helpers/cUtilFecha";
import PacienteModal from "../_box/PacienteModal";
import NuListSimple from "components/nubox/NuListSimple";
import NuListRadio from "components/nubox/NuListRadio";


//type:'numeric'
const columnas=[
    {  field:'id', title: 'Cod. Cert.', type:'numeric'},
    {  field:'cliente', title: 'Cliente'},
    {  field:'nombre', title: 'Nombre'},
    {  field:'lote', title: 'Lote'},
    {  field:'elem', title: 'Elementos'},
];
const cbo=[
  {  value:'1', label: 'label1'},
  {  value:'2', label: 'Clss'},
  {  value:'3', label: 'Nod'},
  {  value:'4', label: 'Lod'},
  {  value:'5', label: 'Elggg'},
]


const data = [
  { id_cita: 1, id_citaho:0, hora: "00:00", hora_ini: "00:30", historia:'V-415', paciente:"", motivo:"", atendido_sn:"", id_estadoc:""},
  { id_cita: 2, id_citaho:0, hora: "00:00", hora_ini: "00:30", historia:'V-415', paciente:"", motivo:"", atendido_sn:"", id_estadoc:""},
 
];

//$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
class CitasTabla extends React.Component {
  
  myRef = React.createRef();//create ref
  citaRef = React.createRef();//create ref
  pacienteRef = React.createRef();//create ref
  calendarRef = React.createRef();//create ref
  

  state = {
    data: data,/* lista de citas */
    dias: [ { dia : 1, titulo :"Lunes 1" }, ],
    
    form: {
      id_medico: 0,
      id_estado:0,
      fecha: "2023-01-01",
    },
    
    selectedId_cita:0,
  
    medicos:[],
    citas:[],
    selectedCita_hora: null,
    horas:[{id_citaho:11, hora:'9:30'  },],

    selected_estado:0,
    estados:[ { value:0, label: "No Confirmado", color:"#008ffb", icon:"fa fa-square", iconColor:"#008ffb" },
              { value:1, label: "Confirmado", color:"#feb019", icon:"fa fa-square", iconColor:"#feb019" },
              { value:2, label: "Hora Cancelada", color:"#ff4560", icon:"fa fa-square", iconColor:"#ff4560" },
              { value:3, label: "Atendido", color:"#00e396", icon:"fa fa-square", iconColor:"#00e396" },
            ],

  };
  
  //-----------------------------------------------
 
  //------------------------------------------------------
  componentDidMount() {
    //console.log("componentDidMount citas");
    //--limpiamos el array de prueba
    this.setState({data:[]});

    const fecha = cUtilFecha.fechaHoy(); 
    const defaultValue = new Date(fecha).toISOString().split('T')[0] // yyyy-mm-dd
    this.setState({ form:{...this.state.form, fecha: defaultValue }}); 

    this.cargar_medicos();   
    
    

  }

  //------------ cambiamos la fecha
  /*handleChangeFechai=(event)=> {    
    this.setState({fechai: event.target.value}); 
  }*/
  //----------------especial, actualiza valores del form -------------------------------
  handleChange = (e) => {
    //requerimos la prop name="?" en cada control
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      },
    });
  };
  handleChangeForm = (e) => {
    //requerimos la prop name="?" en cada control
    //console.log(e.target.value);
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      },
    });

  };
  
  //---------------- del autocomplete --------
  handleOnSelect = (item) => {
    // the item selected
    console.log("handleOnSelect");
    console.log(item)
  }
  //======================================================
  

  //--------
  /* lista de citas, por dia y horas */ 
  /*
  horario:[{ hora : "9:30", d1: {cita:10, paciente:'Maria Roncal'},sel:false},d2 :"",d3 :"",d4 :"",d5 :"",d6 :"",d7 :"",d8 :"",d9 :"",d10 :"", d11:"",d12:"", d13:"", d14:"", d15:"", d16:"", d17:"", d18:"", d19:"", d20:"" }, 
           { hora : "10:00", d1 :"",d2 :{cita:{ id_cita:10, paciente:'Juan manuel', atendido_sn:false },sel:false},d3 :"",d4 :"",d5 :"",d6 :"",d7 :"",d8 :"",d9 :"",d10 :"", d11:"",d12:"", d13:"", d14:"", d15:"", d16:"", d17:"", d18:"", d19:"", d20:""  }, 
           { hora : "10:30", d1 :"",d2 :{cita:{ id_cita:10, paciente:'Juan manuel', motivo:'',atendido_sn:false },sel:false},d3 :"",d4 :"",d5 :"",d6 :"",d7 :"",d8 :"",d9 :"",d10 :"", d11:"",d12:"", d13:"", d14:"", d15:"", d16:"", d17:"", d18:"", d19:"", d20:""  }, 
           { hora : "11:00", d1 :"",d2 :"",d3 :"",d4 :{cita:{ id_cita:10, paciente:'Juan manuel', motivo:'',atendido_sn:false },sel:false},d5 :"",d6 :"",d7 :"",d8 :"",d9 :"",d10 :"", d11:"",d12:"", d13:"", d14:"", d15:"", d16:"", d17:"", d18:"", d19:"", d20:""  }, 
           {id: 999,title: 'Repeating Event',start: new Date(y, m, d + 4, 16, 0),allDay: false},
          ],
  */
  cargar_citas = (fechai, fechaf, id_medico) => {
    //loading.value = true;
    let param={ "id_medico": id_medico,
                "fechai": fechai,
                "fechaf": fechaf,
              }
    console.log(param);
    
    return axios2.post('citatabla_get_citas_list',param)
      .then(res => {
        //loading.value = false
        //--- llega y lo asignamos -
        this.setState({ citas: res.data });
        console.log(res.data);
      })
      .catch(error => {
        console.log(error)
        //this.errored = true
      })
  }
  cargar_medicos = () => {
    let param = { 
    }
    
    axios2.post(process.env.REACT_APP_API_URL  + "cbo_medicos", param).then(res => {
        if(res.data.length>0){ 
               //---lega un array en este formato:
            //-- { value: 1, label: "Dr. Gerson Abner Villafuerte Oporto" }
            this.setState({medicos: res.data});
            //-- esrtablecemos la primera opcion como la elegida
            this.setState({
              form: {
                ...this.state.form,
                id_medico: res.data[0].value,
              },
            });
            //-----------------------
            this.cargar_citas_hoy();
        }else{
            NotificationManager.error("No existen medicos");
        }
    }).catch(error => {
        alert("---- Existio un error ---");
        console.log(error);
        
    });
  };

  cargar_citas_hoy=()=>{
    const fecha = new Date();// cUtilFecha.fechaHoy();
    //fecha.setMinutes(fecha.getMinutes() + fecha.getTimezoneOffset())

    const fechai = cUtilFecha.startOfWeek(fecha);
    const fechaf = cUtilFecha.endOfWeek(fecha);

    console.log("fechai:" + fechai.toDateString());
    console.log("fechaf:" + fechaf.toDateString());
    this.onChangeFechaNu(fechai, fechaf);
  }
 //----------------------------------------------------
  item1_click = (dia) =>{
    console.log("item1_click");
    console.log(dia.cita);
    
    if(dia.cita!==undefined){
      //---modo editar
      //console.log("fecha" +dia.cita.fecha);
      //-----------
      //dia.sel = true;
      //----procedemos a editar 
      //this.editar(dia.cita.id_cita);
      this.citaRef.current.editar(dia.cita.id_cita);
    }
  }
  //------------------------------------
  item1_dblclick=(id_citaho, dia)=>{
    //limpiar_form00();
    console.log(dia);
    //----------
    if(dia.cita!==undefined){
      return;
      // no se puede crear nuevo
    }
    
    let id_medico = this.state.form.id_medico;
    let fecha = dia.fecha;

    this.citaRef.current.nuevo(id_medico, fecha,  id_citaho);
  }
 
  nueva_cita = () => {
    return 0;
  };

  nuevo = (id_citaho) => {
    console.log("nuevo - myRef -");
    //console.log(this.myRef.current);

    //this.myRef.current.editar(1, 'e');
    //this.myRef.current.showModal();
    //this.myRef.current.showAlert();
    //this.citaRef.current.editar(1, 'e');
    
    let id_medico = this.state.form.id_medico;
    let fecha = this.state.form.fecha;
    console.log("->" + id_medico);
    console.log("->" + fecha);
    
    
    //this.citaRef.current.set_id_medico( id_medico );
    //this.citaRef.current.set_fecha( fecha );
    //this.citaRef.current.set_id_citaho( id_citaho );

    this.citaRef.current.nuevo(id_medico, fecha,  id_citaho);

    //this.modalOpen();
  };

  editar = (item) =>{
    console.log("editar");
    //    console.log(item);
    let idd = item.id_cita;
    this.citaRef.current.editar(idd, 'e');
  }
  eliminar = (item) =>{
    console.log("eliminar");
    console.log(item);
    //--------------------------------
    confirmService.show({
      message: 'Esta seguro de eliminar?',
      title: 'Confirmar',
      icon: 'fa fa-question-circle',
      classHeader: 'bg-default1 ',
      classButton: 'btn btn-danger',
      accept: () => {
        let idd = item.id_cita;
        this.citaRef.current.eliminar(idd);
      },
      reject: () => {
          console.log('cancel');
      },
      acceptLabel: 'Aceptar',
      rejectLabel: 'Cancelar'
    })

  }
  
  //---clic en el boton para buscar los elementos
   
  imprimir =(id_certi)=>{
    let fileURL = process.env.REACT_APP_API_URL  + "certificado_reciboPDF/"+ id_certi;
    let  windowFeatures = 'width=650,height= 700, left=100,top=100 ';
    window.open(fileURL,'',windowFeatures);
  }
  //---------------------------------------
  buscar_btn=()=>{
    console.log("buscar_btn");
    //refresh
    this.calendarRef.current.refresh();

  }
  //-------------------------------------
  citaOkModal =()=>{
    console.log("citaOkModal");
    //--- refrescamos el calendario semanal
    this.calendarRef.current.refresh();
  }
  citaCancelModal =()=>{
    console.log("citaCancelModal");
    
  }
  //-----------------
  onNuevoPaciente =()=>{
    console.log("onNuevoPaciente");
    this.pacienteRef.current.nuevo();
    
  }
  
  okModalPaciente =()=>{
    console.log("okModalPaciente");
    
  }
  //---------------------------------
  onChangeFechaNu=(fechai, fechaf)=>{
    //--llamamamos a axios
    const id_medico = this.state.form.id_medico;
    
    const fechai2 = cUtilFecha.toDateMy(fechai);
    const fechaf2 = cUtilFecha.toDateMy(fechaf);

    console.log("fechai2:" + fechai.toDateString());
    console.log("fechaf2:" + fechaf.toDateString());
    
    this.cargar_citas(fechai2, fechaf2, id_medico);
  }

  //----------------------------
  // obj trae los datos de Row de tabla cita
  //[{"id":99,"titulo":"WALKER ORELLANA","fecha":"2023-04-11","hora_ini":"09:30","estado":0},{"id":101,"titulo":"ANITA","fecha":"2023-04-09","hora_ini":"10:00","estado":0},{"id":102,"titulo":"MARGARITA GOMEZ","fecha":"2023-04-10","hora_ini":"11:30","estado":0},{"id":103,"titulo":"CLARA MONTES","fecha":"2023-04-11","hora_ini":"10:30","estado":0}]
  cellClick=( date1, obj )=>{
    console.log("fecha: " + date1.toDateString()); //'Mib Apr 25 2022
    console.log("hora: " + date1.toLocaleString()); //'25/04/2022 13:08:14

    let id_medico = this.state.form.id_medico;
    let fecha = cUtilFecha.toDateMy(date1); // Y-m-d
    let hora = cUtilFecha.toTimeHHMM(date1); //HH.MM
    if(obj==null){
      //Nuevo
      this.citaRef.current.nuevo(id_medico, fecha, hora);
    }else{
      //editar
      this.citaRef.current.editar(obj.id);
    }
  }
  //----------------------------
  render() {
    
    return (
      <div>

        <div className="content">
          <ToastContainer />

          { /* esto por que el menuTop es fixed */}
          { //JSON.stringify(this.state.citas ) 
          }
          <div className="layout-content">

            <section className="content-header">
              <h1><i className="fa fa-calendar"></i> Citas </h1>
            </section>

            <div className="row">
              <div className="col-3">
                <div className="box" style={{ width: "100%" }}>
                  <div className="box-header">
                    {/*<h4>Profesionales</h4>*/}

                  </div>

                  <div className="box-body">

                    <div className="">
                      <div className="col-md-12">
                        <label className=" col-form-label form-control-label">Médico</label>
                        <NuDropdown
                          options={this.state.medicos}
                          onChange={this.handleChangeForm}
                          value={this.state.form.id_medico}
                          name="id_medico"
                        />
                        <br />



                        <div className="row">
                          <div className="col-md-12">
                            <Button
                              onClick={ this.buscar_btn }
                              className="btn btn-md btn-success">
                              <i className="fa fa-search"></i> Buscar
                            </Button>


                          </div>
                        </div>

                      </div>
                      {/* totales */}

                    </div>



                  </div>
                </div>

                <div className="box" style={{ width: "100%" }}>
                  <div className="box-header">
                    <h4>Estados</h4>

                  </div>

                  <div className="box-body">

                    <div className="">
                      <div className="col-md-12">

                        <NuListSimple

                          options={this.state.estados}
                          name="id_estado"
                          value={this.state.id_estado}
                          onChange={this.handleChangeForm}
                        />

                      {/*
                        <table className="tabla_basic" >
                        { this.state.estados.map((item,index) =>(
                          <tr key={index}>
                            <td width={15} ><span style={{ backgroundColor: item.color, width:"15px", display: "flex"  }} >&nbsp; </span></td>
                            <td >{ item.label }</td>
                          </tr>
                        ))
                        }   
                        </table>
                        */}

                      </div>
                      {/* totales */}

                    </div>



                  </div>
                </div>

              </div>


              <div className="col-9">
                <div className="card">

                

                  <NuCMes
                    ref={this.calendarRef}
                    events={this.state.citas}
                    onChange={this.onChangeFechaNu}
                    cellClick={this.cellClick}
                    estados={ this.state.estados }
                  />

                </div >

              </div >
            </div >
          </div>
        </div >


        <CitaModal
          ref={this.citaRef}
          okModal={this.citaOkModal}
          cancelModal={this.citaCancelModal}
          onNuevoPaciente={this.onNuevoPaciente}
          value={this.state.selectedId_cita}
        />
        <PacienteModal
          ref={this.pacienteRef}

          okModal={this.okModalPaciente}
        />

        {/*--- modal---- */}

      </div>
    );
  }
}
  
  //------------para que soporte la funcionalidad de parametros
export function withRouter(Children){
  return(props)=>{

     const match  = {params: useParams()};
     return <Children {...props}  match = {match}/>
 }
}
export default withRouter(CitasTabla);