import * as React from "react";
import './styles.css';
import validacion from "helpers/validacion"
//import Errors from "../Errors";
//import Icon from "../Icon";
//--- pueden ser varias piezas 2,5,4
const PatternValidation = (valor, tipo) => {
  const regex = new RegExp('^[0-9]*([,][0-9]+)*$');    
  return regex.test(valor);
};
//-----------------------------------------------------------------------
const NuInput = ({
  
  className,
  containerStyle,
  errors,
  disabled,
  icon,
  inputStyle,
  name,
  onChange,
  placeholder,
  readOnly,
  required,
  type,
  value,
  wrapperStyle,
  valtipo='',
  classError='',
}) => {
  const inputRef = React.useRef(null);

  const [error,setError] = React.useState("");
  
  const handleClick = () => {
    if (inputRef && inputRef.current) inputRef.current.focus();
  };
  
  const handleChange = (e) => {
    //---- lo validamos
    //---si es correcto retorna cadena vacia
    //---sino, retorna el error
    let cad = validacion(e.target.value, valtipo );
    setError(cad);
    //---llamamos al obChange del padre
    onChange(e);
  };

  return (
    <div className={""} style={wrapperStyle}>
      <div onClick={handleClick} className="" style={containerStyle}>
        <input
          ref={inputRef}
          aria-label={name}
          data-testid={name}
          tabIndex={0}
          type={type}
          name={name}
          onChange={handleChange}
          placeholder={placeholder}
          value={value}
          style={inputStyle}
          disabled={disabled}
          readOnly={readOnly}
          className= {` ${className} ${error===""? '': classError}` }
          valtipo={valtipo}
        />
      </div>
      { !value && required && (
        <div data-testid="errors">Requerido!</div>
      )}
      { error!=='' &&
        <div className="text-danger">{ error }</div>  
      }
    </div>
  );
};


export default NuInput;
