import React, { Component, Fragment } from "react";
import {
  withStyles
} from '@material-ui/core';

const styles = (theme) => ({
  suggestions: {
    border: '1px solid #999',
    borderTopWidth: 0,
    listStyle: 'none',
    marginTop: 0,
    maxHeight: 143,
    overflowY: 'auto',
    paddingLeft: 0,
    width: 'calc(300px + 1rem)',
    "& li": {
      padding: '0.5rem'
    },
    "& li:hover": {
      backgroundColor: '#008f68',
      color: '#fae042',
      cursor: 'pointer',
      fontWeight: 700
    }
  },
  input: {
    border: '1px solid #999',
    padding: '0.5rem',
    width: 300
  },
  suggestionActive: {
    backgroundColor: '#008f68',
    color: '#fae042',
    cursor: 'pointer',
    fontWeight: 700
  }
});

class Autocomplete extends Component {

  constructor(props) {
    super(props);
    this.state = {
      suggestRowIdx: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: ""
    };
    this.itemRefs = React.createRef();
    this.itemRefs.current = [];
  }

  selectText = text => {
    this.setState({
      suggestRowIdx: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: text
    });
  };

  setSearchBarValue = e => {
    const { suggestions } = this.props;
    const userInput = e.currentTarget.value;

    if(userInput.trim().length === 0) {
      this.itemRefs.current = [];
    }

    const filteredSuggestions = suggestions.filter(
      suggestion =>
        suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );

    this.setState({
      suggestRowIdx: 0,
      filteredSuggestions,
      showSuggestions: true,
      userInput
    });
  };

  addToRefs = (el, index) => {
    if(el && !this.itemRefs.current.includes(el)){
      this.itemRefs.current[index] = el;
    }
  }
  /************************************************************ */
  onSelectKeyDown = e => {
    const { suggestRowIdx, filteredSuggestions } = this.state;

    let scrollIndex = suggestRowIdx - 1;

    //---enter
    if (e.keyCode === 13) {
      this.selectText(filteredSuggestions[suggestRowIdx]);
    }//si down
    else if (e.keyCode === 38 && suggestRowIdx > 0) {
      this.itemRefs.current[scrollIndex].scrollIntoView();
      this.setState({ suggestRowIdx: scrollIndex });
    }//up
    // User pressed the down arrow, increment the index
    else if (e.keyCode === 40 && suggestRowIdx + 1 !== filteredSuggestions.length) {
      scrollIndex = suggestRowIdx + 1;
      this.itemRefs.current[scrollIndex].scrollIntoView();
      this.setState({ suggestRowIdx: scrollIndex });
    }
  };

  renderSuggestions = () => {
    const { 
      filteredSuggestions,
      suggestRowIdx
    } = this.state;

    const { classes } = this.props;

    return (
      <ul className={classes.suggestions}>
        {filteredSuggestions.map((suggestion, index) => {
          return (
            <li
              className={index === suggestRowIdx ? classes.suggestionActive : null}
              key={suggestion}
              onClick={e => this.selectText(e.currentTarget.innerText)}
              ref={el => this.addToRefs(el, index)}
            >
              {suggestion}
            </li>
          );
        })}
      </ul>
    ); 
  }


  render() {
    const { 
      filteredSuggestions,
      showSuggestions,
      userInput
    } = this.state;

    const { classes } = this.props;

    const hasAvailSuggestions = showSuggestions && userInput && filteredSuggestions.length;
    return (
      <Fragment>
        <input
          type="text"
          onChange={this.setSearchBarValue}
          onKeyDown={this.onSelectKeyDown}
          value={userInput}
          className={classes.input}
        />
        {hasAvailSuggestions && this.renderSuggestions()}
      </Fragment>
    );
  }
}

export default withStyles(styles)(Autocomplete);