import React from 'react';
  import axios2 from 'services/axios2';

  import {useParams} from 'react-router-dom';
  import {Link,hashHistory} from 'react-router-dom';
  import { ToastContainer, toast } from 'react-toastify';
  import { NuDropdown } from 'components/nubox/NuDropdown';

  import confirmService from 'components/confirmService';
  import ModalCita_hora from './ModalCita_hora'

  import user from 'assets/img/user.jpg';

  import { NotificationContainer, NotificationManager } from 'react-notifications';
  import {
    Table,
    Button,
  } from 'reactstrap';

  const data = [
    //{ id_medico: 1,  nombre: 'ALEX LIZARRAGA',  especialidad: '00000023',tel:''}, 
  ];
  /*$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$*/
  class Cita_horaList extends React.Component {  
  
    modalRef = React.createRef();//create ref
    
    state = {
      modalInsertar: false,
      form: {
        name: '',
      },
      data: data,/* lista de datos */
      
    };
    //------------------------------------------------------
    componentDidMount() {
      //--limpiamos el array de datos
      this.setState({data:[]});

      this.cargar_data();
      
      //this.cargar_citas();
      console.log(this.modalRef);
    }
    //----------------especial, actualiza valores del form -------------------------------
    handleChangeForm = (e) => {
      //requerimos la prop name='?' en cada control
      this.setState({
        form: {
          ...this.state.form,
          [e.target.name]: e.target.value,
        },
      });
    };
    //====================================================== 
    cargar_data = () => {
      let param = { 
        param1: this.state.form.name
      }
      
      axios2.post( 'hora_get_list', param)
      .then(res => {
      
          if(res.data.length>0){ 
              this.setState({data: res.data});
          }else{
              NotificationManager.error('No existen datos');
          }
  
      }).catch(error => {
          alert('---- Existio un error ---');
          console.log(error);
          
      });
    };
    //======================================================     
    imprimir =(id_certi)=>{
      let fileURL = process.env.REACT_APP_API_URL  + 'certificado_reciboPDF/'+ id_certi;
      let  windowFeatures = 'width=650,height= 700, left=100,top=100 ';
      window.open(fileURL,'',windowFeatures);
    }
    /* === This is where the magic happens ==== */
    handleSubmit = event => {
      event.preventDefault();
      this.cargar_data();
    }
    //-----------------------------------------------------------------
    nuevo = () => {
      this.modalRef.current.nuevo();
    }
    //-----------------------------------------------------------------
    editar = (item) =>{
      console.log('editar');
      //    console.log(item);
      let idd = item.id_hora;
      this.modalRef.current.editar(idd);
    }
    //-----------------------------------------------------------------
    eliminar = (item) =>{
      console.log('eliminar');
      
      confirmService.show({
        message: 'Esta seguro de eliminar?',
        title: 'Confirmar',
        icon: 'fa fa-question-circle',
        classHeader: 'bg-default1 ',
        classButton: 'btn btn-danger',
        accept: () => {
          let idd = item.id_hora;
          this.modalRef.current.eliminar(idd);
        },
        reject: () => {
            console.log('cancel');
        },
        acceptLabel: 'Aceptar',
        rejectLabel: 'Cancelar'
      })
  
    }
    //-------------------------------------
    okModal =()=>{
      console.log('citaOkModal');
      this.cargar_data();
    }
    //-------------------------------------
    closeModal =()=>{
      console.log('citaCancelModal');
    }
    //-------------------------------------
    render() {
    
      return (
        <div>
          <ToastContainer />
          <div className='container'>
  
            {/*{/*--- -----------------------------*/}
            <div className='row_'>
              <div className='col-md-12'>
                <h3> Lista de cita_hora s</h3>
              </div>
            </div>
  
            <div className="row_ d-flex justify-content-center">
                <div className='box box-info'>
                  {/*-- box-header --*/}
                  <div className='box-body pad'>
  
                    <form onSubmit={this.handleSubmit}>
  
                      <div className='row'>
  
                        <div className='col-md-4'>
                          <div className='form-group'>
                            <label className='control-label'>Busqueda:</label>
                            <input 
                                type='text' 
                                name='name' 
                                onChange={this.handleChangeForm} 
                                className='form-control input-sm' />
                          </div>
                        </div>
  
                        <div className='col-md-2'>
                          <button type='submit' style={{ marginTop: '24px' }} className='btn btn-success '>
                            <i className='fa fa-binoculars'></i> Buscar</button>
  
                        </div>
  
                      </div>
                    </form>
  
                  </div>
                  {/* box-body */}
                </div>
            </div>
  
            <div className="row">
                <div className='top_title2'>
                  <div className='row'>
                    <div className='col-md-12'>
                      
                    
                      <div className=' pull-right'>

                        <Button onClick={() => this.nuevo()} 
                          className='create-modal btn btn-info ' >
                          <i className='fa fa-plus'></i> Nuevo 
                        </Button>
                      </div>
                      <h4>cita_hora</h4>
                    </div>
                  </div>
                </div>
            </div>
            
            {/*-- */}
            <div class="box">
              <div class="box-header">
                
              </div>
              <div className="box-body">
                <table className='table table-striped table-hover' width='100%' align='center'>
                  <thead>

                    <tr>
                    <th>Cod</th>
                    <th>hora</th>
                    <th>Estado</th>
                    <th>Accion</th>
                    </tr>

                  </thead>
                  <tbody>
                    {/* className='text-left' */}
                    
                    {this.state.data.map((item, index) => (
                      <tr key={index}> 
                        <td>{ item.id_hora}</td>
                        <td>{ item.hora}</td>
                        <td>{ item.estado}</td>

                        <td>
                          <div className='btn-group'>
                            <Button className='btn btn-warning btn-sm' title='Editar' 
                              onClick={() => this.editar(item)}>
                              <i className='fa fa-edit'></i>
                            </Button>
                            &nbsp;
                            <Button className='btn btn-danger btn-sm' title='Eliminar' 
                              onClick={() => this.eliminar(item)}  >
                              <i className='fa fa-trash'></i>
                            </Button>
                          </div>
                        </td>
                      </tr>
                    ))}



                  </tbody>
                </table>  
              </div>
            </div>
            {/*-- */}

            <ModalCita_hora
              ref={this.modalRef}
              okModal={this.okModal}
              closeModal={this.closeModal}
            />
  
          </div>
        </div>
        
      );
    }
  }
  //------------para que soporte la funcionalidad de parametros
  export function withRouter(Children){
    return(props)=>{
  
       const match  = {params: useParams()};
       return <Children {...props}  match = {match}/>
   }
  }
  export default withRouter(Cita_horaList);
  