import React, { useEffect, useState } from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import PropTypes from 'prop-types';
import './styles.css';

const miselect={
    /*fontSize: "14pt",*/
    /*lineHeight: "1.5",*/
    border: "1px solid #ced4da",
    borderRadius: ".25rem",
    display: "block",
    width: "100%",
    padding: ".275rem .75rem",
}

const NuDropdownIcon = ({ direction, ...props }) => {

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const [itemx, setItemx] = useState(0);

  useEffect(() => {
    //Runs only on the first render
    setItemx(getItemFromValue(props.value));
  }, [props.value]);
  //-------------agregamos el item con su informacion para propositos futuros
  const onChangeOption = (e) => {
    // 👇️ filter with 1 condition
    //console.log(e.target.value);
    //---buscamos en el array, para que lo retornemos el row 
    const filtered = props.options.filter(item => {
      return item.value == e.target.value;
    });
    //console.log(filtered[0]); //sino hay retorna undefined
    //---lo anexamos para ser retornado
    e.target.item = filtered[0];
    
    props.onChange(e);
  }
  //-----------------
  const getItemFromValue=(value)=>{
    const filtered = props.options.filter(item => {
      return item.value == value;
    });
    return filtered[0];
  }
  //------------------ calculamos su label teniendo su value
  
  //----------------------------------------------------------------
  return (
    <div className="">
      {/*<label>{props.labelName} :</label>*/}
      {  }
      <Dropdown isOpen={dropdownOpen} toggle={toggle} direction={direction} style={props.style} className={props.className}>
        <DropdownToggle caret>
          <span style={{backgroundColor: itemx?.color, marginRight:"8px" }} >&nbsp;&nbsp;&nbsp;&nbsp;</span>
          { itemx?.label } 
        </DropdownToggle>
        <DropdownMenu {...props}>
        {
          props.options.map(item => {
                return (
                    <DropdownItem key={item.value} name={props.name} value={item.value} onClick={(e)=> onChangeOption(e) } >
                      <span style={{backgroundColor: item.color, marginRight:"8px" }} >&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      {item.label} 
                    </DropdownItem>
                );
          })}
        </DropdownMenu>
      </Dropdown>
      {/*<select className="form-select" style={miselect} name={props.name} value={props.value} onChange={(e)=> onChangeOption(e) }  >
        <option key={0} value={"0"} defaultValue  >-- Elija una opción --</option>
        {
          props.options.map(item => (
            <option key={item.value} value={item.value} >{item.label}</option>
          ))
        }
      </select>
      */}
    </div>
  )
}

NuDropdownIcon.propTypes = {
  direction: PropTypes.string,
};

export default NuDropdownIcon;
