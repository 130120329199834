import React, { useEffect, useState } from 'react';

import './style.css'

import pieza1 from 'assets/imgd/pieza1.png';
import pieza_dental from 'assets/imgd/pieza_dental.svg'

// simple component that returns a list item with an image element inside of it
class PiezaDental extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
        index: 0,
        isActive: false,
        
    }
  }
  //---
  //----------------------------------
  componentDidMount(){

    //esperamos a que cargue el SVG
    setTimeout(() => {
        
      if(document.querySelector(".svgClassPeq").getSVGDocument()!==null){
        
        console.log("cargado correcto");
        //setIsActive(true);
        this.inyeccode(); 
        this.cambiar_color_partes("0",this.props.value,"#f20");
        this.setState({isActive: true});
      }
    }, 500);
    
  }
  
  //-----------------------------------------
  inyeccode=()=>{
    //--- inyeccion de codigo onclick
    //console.log("-----inyeccode-------");
        
    let elemSvg = document.querySelector(".svgClassPeq").getSVGDocument();
    elemSvg.getElementById("m0_0").addEventListener("click", () => this.mostrar_opt(0));
    elemSvg.getElementById("m0_1").addEventListener("click", () => this.mostrar_opt(1));
    elemSvg.getElementById("m0_2").addEventListener("click", () => this.mostrar_opt(2));
    elemSvg.getElementById("m0_3").addEventListener("click", () => this.mostrar_opt(3));
    elemSvg.getElementById("m0_4").addEventListener("click", () => this.mostrar_opt(4));
    
    //document.querySelector(".svgClass").getSVGDocument().getElementById("m0_0").addEventListener("click", () => mostrar_opt("10000"));
  }
  //--------------------------
  mostrar_opt=(pos)=>{
    console.log("mostrar_opt");
    //console.log("props.value ----->" + this.props.value);
    //console.log("props_val ----->" + pos);
    let arr = this.props.value.split('');
    //--este es el toogle
    let newVal= arr[pos]==1? 0:1;
    arr[pos] = newVal;
    //-- join une los elementos sin comas
    let new_cad = arr.join('');
    
    //---informamos afuera
    //console.log("new_cad: " + new_cad );
    this.props.onChange(new_cad);
  }
  //--ingresa una cadena y con ello lo pintamos
  //---pueden venir multiples piezas, partes---------------------------------------------------
  //cad 01101
  cambiar_color_partes=(pieza, cade, color)=>{
    
    console.log("cade:" + cade);
    // change to red

    //let _currentFill = "#f00"; // red
    let _currentFill = color;

    const myArray = pieza.split(",");
    if(myArray.length<0){
      return;
    }
    //--obtenemos la pieza
    let piezax = "m" + myArray[0];
    
    //----- la cadena desde afuera 01011, de pintado
    let arr = cade.split('');
    if(arr.length!=5){
        alert("Error 34");
        return;
    }

    let elemSvg = document.querySelector(".svgClassPeq").getSVGDocument();

    //---si es '0', ocultamos la muestra 
    if(cade=='00000' || cade==''){
      elemSvg.getElementById( piezax +"_0").setAttribute("style", "fill:silver");
      elemSvg.getElementById( piezax +"_1").setAttribute("style", "fill:silver");
      elemSvg.getElementById( piezax +"_2").setAttribute("style", "fill:silver");
      elemSvg.getElementById( piezax +"_3").setAttribute("style", "fill:silver");
      elemSvg.getElementById( piezax +"_4").setAttribute("style", "fill:silver");  
        return;
    }
    //-----------pintamos los dientes
    for (let i = 0; i < arr.length; i+=1) { 
      //console.log("En el índice '" + i + "' hay este valor: " + miArray[i]);
      //console.log(piezax +"_"+ i);
      if(arr[i]==='1'){
        elemSvg.getElementById( piezax +"_"+ i).setAttribute("style", "fill:"+_currentFill);
      }else{
        elemSvg.getElementById( piezax +"_"+ i).setAttribute("style", "fill:silver");
      }
    } 

  }
  demo=()=>{
    console.log("props.value->" + this.props.value );
  }
  //--------------------------------------------------------------
  //-----------------------------------------------------------------------------------------
  render() {
    const { isActive } = this.state
    
    //---solo si ya esta cargado el SVG
    if(isActive==true){
      this.cambiar_color_partes("0",this.props.value,"#f20");
    }
    
    return (
      <div className='dibujar' style={{ width:"100px", height:"100px" }}>
        { /*JSON.stringify( isActive) */}
        
        {/*<button onClick={ this.demo } >Ejec</button>*/}
        <object className="svgClassPeq" 
                width="100%" 
                height="auto" 
                type="image/svg+xml" 
                data={pieza_dental}
                style={{ fill: "red", stroke: "blue", strokeWidth: "2" }}>
        </object>
      </div>
    );
  }
}
export default PiezaDental;
