import React from 'react';
import { Link } from 'react-router-dom';

import GalleryItem from './GalleryItem';
import './styles.css'
import cUtilFecha from 'helpers/cUtilFecha';

const Gallery = (props) => {
  // mapping over the items of the data passed as props to the Gallery component
  // it returns a GalleryItem component for each item mapped
  /*let images = props.data.map(images =>
     <GalleryItem
       url={`${ props.pathURL}${images.filename}`}
       key={images.id_image}
     />
   );*/
   //let {fecha_reg, detalle, filename } = props;
    let formato_fecha=(fechamy)=>{
      //console.log("fechamy:" + fechamy);
      let fecha = cUtilFecha.strToDate(fechamy);
      if(fecha===undefined) return;
      //console.log(fecha);
      return fecha.toLocaleDateString();
      
    } 

   return (
     <div className="file_manager">
       {/* ternary operator that checks if the data object passed as props have items in it */}
       <h2>{ (props.data.length === 0 && props.match) ? 'Sorry no match found!' : props.results }</h2>
        <div className='row clearfix'>
          { props.data.map((item,index) =>(
            <div key={index} className="col-lg-4 col-md-4 col-sm-12">
              <div className='card'>
                <div className='file'>
                  <Link to={`/imagenzoom/${item.filename}`} >
                    
                    <div className="hover">
                        <button type="button" className="btn btn-icon btn-icon-mini btn-round btn-danger">
                            <i className="fa fa-trash"></i>
                        </button>
                    </div>
                    <div className="image">
                      <img  src={`${ props.pathURL}${item.filename}`}  alt="img" className="img-fluid" />
                    </div>
                    <div className="file-name">
                        <p className="m-b-5 text-muted">{item.filename}</p>
                        <small>{item.detalle}<span className="date text-muted">{ formato_fecha(item.fecha_reg)  }</span></small>
                    </div>
                  </Link>
                </div>
              
              </div>
            </div>
            ))
          }
       </div>
     </div>
   );

}

export default Gallery;