import React from "react";

import axios2 from 'services/axios2';

import {useParams} from "react-router-dom";
import {Link,hashHistory} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { NuDropdown } from "../../components/nubox/NuDropdown";

import confirmService from 'components/confirmService';
import ModalMedico from "views/medicos_crud/ModalMedico"



import user from 'assets/img/user.jpg';

import { NotificationContainer, NotificationManager } from 'react-notifications';
import {
  Table,
  Button,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  ModalFooter,
  ToastHeader,
} from "reactstrap";



const data = [
  { id_medico: 1,  nomape: "ALEX LIZARRAGA",  especialidad: "00000023",tel:''},
  { id_medico: 2,  nomape: "MARIANELA",  especialidad: "00000023",tel:''},
 
];

/*$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$*/
class MedicosCrud extends React.Component {  

  modalRef = React.createRef();//create ref
  
  state = {
    modalInsertar: false,
    form: {
      name: '',
    },
    data: data,/* lista de datos */
    
  };
  
  //------------------------------------------------------
  componentDidMount() {
    //--limpiamos el array de prueba
    this.setState({data:[]});

    const date = new Date();
    const defaultValue = date.toLocaleDateString('en-CA');
  
    //this.setState({fechai: defaultValue}); 
    this.setState({ form:{...this.state.form, fecha: defaultValue,}}); 

    this.cargar_medicos();
    
    //this.cargar_citas();
    //this.fetchData(this.state.fechai);
    console.log(this.modalRef);
  }

  //------------ cambiamos la fecha
  /*handleChange = event =>{
    this.setState({ name: event.target.value});
  }*/
  //----------------especial, actualiza valores del form -------------------------------
  handleChangeForm = (e) => {
    //requerimos la prop name="?" en cada control
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      },
    });
  };
  
  //====================================================== 
  //----cargamos los datos desde la base de datos
  /*{
      "id_medico": 1,
      "nomape": "Dr. Gerson Abner Villafuerte Oporto",
      "dir": "",
      "tel": "",
      "cel": "",
      "foto_path1": ""
      "especialidad": "Ortodoncia"
  },*/
  cargar_medicos = () => {
    let param = { 
      param1: this.state.form.name
    }
    
    axios2.post("medicocrud_lista", param)
    .then(res => {
        if(res.data.length>0){ 
            //NotificationManager.success("Correcto");
            this.setState({data: res.data});
            //console.log(this.state.medicos);
        }else{
          this.setState({data:[]});
          //NotificationManager.error("No existen datos");
        }

    }).catch(error => {
        alert("---- Existio un error ---");
        console.log(error);
        
    });
  };
      
  imprimir =(id_certi)=>{
    let fileURL = process.env.REACT_APP_API_URL  + "certificado_reciboPDF/"+ id_certi;
    let  windowFeatures = 'width=650,height= 700, left=100,top=100 ';
    window.open(fileURL,'',windowFeatures);
  }
  //----------------
  
  /* === This is where the magic happens ==== */
  handleSubmit = event => {
    console.log("handleSubmit");
    event.preventDefault();
    this.cargar_medicos();
  }
  /************************************************ */
  nuevo = () => {
    
    this.modalRef.current.nuevo();
    //this.setState({ modalInsertar: true});
  }
  /************************************************************** */
  editar = (item) =>{
    console.log("editar");
    //    console.log(item);
    let idd = item.id_medico; //id_medic
    this.modalRef.current.editar(idd);
  }
  eliminar = (item) =>{
    console.log("eliminar");
    console.log(item);
    //--------------------------------
    confirmService.show({
      message: 'Esta seguro de eliminar?', 
      title: 'Confirmar',
      icon: 'fa fa-question-circle',
      classHeader: 'bg-default1 ',
      classButton: 'btn btn-danger',
      accept: () => {
        let idd = item.id_medico;
        this.modalRef.current.eliminar(idd);
      },
      reject: () => {
          console.log('cancel');
      },
      acceptLabel: 'Aceptar',
      rejectLabel: 'Cancelar'
    })

  }
  //---------------------------------------------
  okModal =()=>{
    console.log("citaOkModal");
    this.cargar_medicos();
  }
  //---------------------------------------------
  closeModal =()=>{
    console.log("citaCancelModal");
  }
  //-------------------------------------
  render() {
    
    return (
      <div>
        <ToastContainer />
        <div className="content">

          {/*<!--- ------------------------------->*/}
          <div className="row">
            <div className="col-md-12">
              <h3> Lista de profesionales

                <div className="btn-group pull-right">

                  <Button onClick={()=> this.nuevo() } className="create-modal btn btn-success " >
                    <i className="fa fa-plus"></i> Nuevo profesional
                  </Button>
                </div>
              </h3>


              <div className="box box-info">
                {/*<!-- /.box-header -->*/}
                <div className="box-body pad">

                  <form onSubmit = { this.handleSubmit }>
                    
                    <div className="row">

                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="control-label">Busqueda:</label>
                          <input type="text" name = "name" onChange= {this.handleChangeForm} className="form-control input-sm" />
                        </div>
                      </div>

                      <div className="col-md-2">
                        <button type="submit" style={{ marginTop: "24px" }} className="btn btn-default ">
                          <i className="fa fa-binoculars"></i> Buscar
                        </button>
                      </div>

                      <div id="divres1"></div>
                    </div>
                  </form>

                </div>
                {/*<!-- /.box-body -->*/}
              </div>


              {/*<!-- box table -->*/}
              <div className="box">
                <div className="box-header">
                  <br/>
                    <div className="col-md-12">


                      <div className="table-responsive">
                        <table className="table tabla_azul0 table-striped table-hover ">
                          <thead>
                            <tr>

                              <th>Cod.Doc</th>
                              <th>Nombre</th>
                              <th>Especialidad</th>
                              <th>Telefono</th>
                              <th>Movil</th>
                              <th className="text-center" style={{ width:"150px" }}>
                                Acción
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                        
                            {this.state.data.map((item, index) => (
                              <tr key={index}>
                                <td>{ item.id_medico }</td>
                                <td>{ item.nomape }</td>
                                <td>{item.especialidad}</td>
                                <td>{item.tel}</td>
                                <td className='text-center'>{item.cel}</td>
                                <td>
                                  <div className="btn-group">
                                    <Button  className="btn btn-warning btn-sm" title="Editar" onClick={ ()=> this.editar(item) }>
                                      <i className="fa fa-edit"></i> Editar                                     </Button>
                                   
                                    <Link  to={`/citahoras/${item.id_medico}`} className="btn btn-default btn-sm" title="Editar" onClick={ ()=> this.editar(item) }>
                                      <i className="fa fa-calendar"></i> horario
                                    </Link>
                                    
                                    <Button className="btn btn-danger btn-sm" title="Eliminar" onClick={ ()=> this.eliminar(item) }   >
                                      <i className="fa fa-trash"></i> Elim
                                    </Button>
                                  </div>
                                </td>
                              </tr>
                            ))}
                             

                          </tbody>
                          
                          </table>
                        <div className="pull-left">

                        </div>
                      </div>

                    </div>


                </div>
              </div>
              {/*<!-- fin box table -->*/}

            </div>

          </div>
          {/*<!--- ----------------- form busqueda ------------------>*/}

          {/*<!-- -------------------------------------------------->*/}

        </div>


        
        <ModalMedico 
            ref={this.modalRef}
            okModal={ this.okModal}
            closeModal={ this.closeModal}
        />
        
      </div>
    );
  }
}
  
  //------------para que soporte la funcionalidad de parametros
export function withRouter(Children){
  return(props)=>{

     const match  = {params: useParams()};
     return <Children {...props}  match = {match}/>
 }
}
export default withRouter(MedicosCrud);