import React, { useRef, useState, useEffect, useContext } from "react";


import { useNavigate } from "react-router-dom";
import AuthService from "../../services/auth.service";
import logo0 from '../../assets/img/logo0.png';
import logo2 from '../../assets/img/logo2.png';

import NuAlert2 from '../../components/NuAlert2';
import NuLoading from '../../components/nubox/NuLoading';
import userEvent from "@testing-library/user-event";

 //--- CONTEXT API -----------
 import { useStore, useDispatch } from "store/StoreProvider";
 import { types } from "store/StoreReducer";

const Login = () => {
  //---------------- CONTEXT API ------------------
  const store = useStore();
  const dispatch = useDispatch();
  //--------------------------------------------
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [logo, setLogo] = useState("");
  
  const [cargando, setCargando] = useState(false);
  const nuRef = useRef(null);

  const navigate = useNavigate();

  //-- equivalente a componentDidMount
  //---si tiene token valido, tons lo redireccionamos a el /dashboard
  useEffect( ()=>{
    //---si es que hay token nos vamos al dashboard
    if(AuthService.getLocalAccessToken()!=null){
      navigate("/dashboard");
    }
    //---generamos el logo para el login
  })

  //------------------------------------------------------hacemo el login
  const handleLogin = async (e) => {
    e.preventDefault();
    setCargando(true);
    try {
      await AuthService.login(email, password).then(
        (response) => {
          console.log("AuthService" + response);
          setCargando(false);
          if(response.exito===0){
              
            nuRef.current.show({'type':'info',"text":response.men});
          }else{
            //--correcto el registro  
            //navigate("/dashboard");
            //---------- lo colocamos a el STORE ------------------
            dispatch({type: types.authLogin, payload: response.user}); 
            //----------  -----------------------------------------

            
          }
          
        },
        (error) => {
          console.log(error);
        }
      );
    } catch (err) {
      console.log(err);
    } 
  };

  const onClick=()=> {
    
    //let updatedValue = {"text":"juice",'type':'success'};
    /*setMessage({
      ...message,
      ...updatedValue
    });*/
    
    if (nuRef.current) {
      nuRef.current.show({'type':'warning',"text":"Este es mi primer texto"});
    }

    
    //setMessage({"text":"Mario",'type':'warning','show':true});
  }
  /*const onClose=()=> {
    setMessage({'show':false});
  }*/

  return (
    <div>

      <div id="login">
        <div className="auth-layout-wrap">
          <div className="auth-content">
            <div className="card o-hidden">
              <div className="row">
                <div className="col-md-12">
                  <div className="p-4">
                    
                    <div className="auth-logo text-center mb-30">
                      <img src={logo2} alt="" style={{ height: "130px", width: "auto" }} />
    

                    </div>

                    {/*<h4 className="mb-3 text-18">ACCESO</h4> */}
                    
                    <span>
                      <form onSubmit={handleLogin}>
                        <span>
                          <fieldset className="form-group text-12" id="__BVID__11">
                            <legend  className="bv-no-focus-ring col-form-label pt-0" id="__BVID__11__BV_label_">
                              Email</legend>
                            <div>
                              
                              <input type="text" placeholder="" className="form-control-rounded form-control is-valid"
                                value={email} onChange={(e)=> setEmail(e.target.value)}
                                required 
                                />
                              
                            </div>
                          </fieldset>
                        </span> 
                        <span>
                          <fieldset className="form-group text-12" id="__BVID__14">
                            <legend  className="bv-no-focus-ring col-form-label pt-0" id="__BVID__14__BV_label_">
                              Contraseña</legend>
                            <div>
                              
                              <input type="password" placeholder="" className="form-control-rounded form-control is-valid"
                                value={password} onChange={(e)=> setPassword(e.target.value)}
                                required
                                />
                                
                              {/*<!---->
                              <!---->
                              <!---->*/}
                            </div>
                          </fieldset>
                        </span> 
                        
                        <button type="submit" className="btn btn-rounded btn-block mt-2 btn-primary mt-2">Ingresar</button>
                        
                      </form>
                    </span>
                    {/*<div className="mt-3 text-center"><a href="/password/reset" className="text-muted"><u>Forgot Password ?</u></a>
                    </div>*/}

                    {/*<button className="btn btn-success m-1" onClick={() => onClick()} >Success</button>*/}
                    <NuLoading 
                      className="oso"
                      show={cargando}
                      text="Cargando..."
                    />
                    <br/>
                    <NuAlert2 ref={nuRef} />

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default Login;