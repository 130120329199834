import React from "react";

class Child extends React.Component {
    showAlert() {
        alert('Hello World');
    }

    render() {
        return (
            <h1>Hello</h1>
        );
    }
}
export default Child;