import React from "react";

import axios2 from 'services/axios2';

import {useParams} from "react-router-dom";
import {Link,hashHistory} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import NuDataTable from 'components/nubox/NuDataTable';

//import listac from '../assets/img/lista2.png';
import confirmService from 'components/confirmService';
import { withRouterJim } from "router/withRouterJim";
import cUtilFecha from "helpers/cUtilFecha";


//--- CONTEXT API -----------



const cbo=[
  {  value:'1', label: 'label1'},
  {  value:'2', label: 'Clss'},
  {  value:'3', label: 'Nod'},
  {  value:'4', label: 'Lod'},
  {  value:'5', label: 'Elggg'},
]

const data = [
  { id_cita: 1, id_citaho:0, hora: "00:00", hora_ini: "00:30", historia:'V-415', paciente:"", motivo:"", atendido_sn:"", id_estadoc:""},
  { id_cita: 2, id_citaho:0, hora: "00:00", hora_ini: "00:30", historia:'V-415', paciente:"", motivo:"", atendido_sn:"", id_estadoc:""},
 
];

//$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
class HistoriaList extends React.Component {
  //---- CONTEXT API ------
  
  myRef = React.createRef();//create ref
  citaRef = React.createRef();//create ref

  state = {
    id_store:0,
    //--------------- DATATABLE ----------------
    userData:[  {"id_cliente": 103122, "razon_social": "werwer", "dir_fiscal": ""  }, ],
    columns:[
      { field: "id_historia", text: "Cod",  dataType: 'text', className: "name",  align: "left",sorting: true,},
      { field: "fecha_reg", text: "Fecha",  dataType: 'custom', className: "name",  align: "left",sorting: true,
        renderCell: (itemRow, onClicButton1,onClicButton2) => {
          //itemRow.fecha_reg
          //let date = new Date("2017-01-26");
          let date = new Date(itemRow.fecha_reg);
          return cUtilFecha.toDateMy(date);

        }
      },
      { field: "motivo_consulta", text: 'Motivo consulta', dataType: 'text', className: "name", align: "left", sorting: true,    },
      { field: "accion", text: 'Accion', dataType: 'custom', className: "name", align: "center",sorting: false, 
      renderCell: (itemRow, onClicButton1,onClicButton2) => (
        <strong>
            <button className='btn btn-warning btn-sm' onClick={() => onClicButton1(itemRow) }>
                <i className='fa fa-edit'></i>
            </button>
            {/* {isShown && <ViewDownload />} */}
            &nbsp; 
            <button className='btn btn-danger btn-sm' onClick={() => onClicButton2(itemRow) }>
                <i className='fa fa-trash'></i>
            </button>
        </strong>
      ) },
    ],
    pageSettings: {current_page: 1, total: 12, per_page: 10, from: 1,to: 2,},  /*per_page= items_per_page */
    sort: {field: "", dir: "desc",  },
    filter: [{col: '',ope:'=', val: ''}],
    isLoading: true,
    form: {
      id_medico: 0,
      fecha: "",
    },
    medicos:[],
  };
  
  //-----------------------------------------------
 
  //------------------------------------------------------
  componentDidMount() {
    console.log("componentDidMount citas");
    //--limpiamos el array de prueba
    this.setState({data:[]});

    const date = new Date();
    const defaultValue = date.toLocaleDateString('en-CA');
  
    //this.setState({fechai: defaultValue}); 
    //this.setState({ form:{...this.state.form, fecha: defaultValue,}}); 
    let id = this.props.store.paciente.id_paciente;
    //console.log("id_paciente:" + id);
    this.setState({id_store: id}); 
    //----- filter 
    //let filter = [{col: 'id_paciente',ope:'=', val: id}];
    let filter=[];

    this.getList(
      this.state.pageSettings.current_page,
      this.state.pageSettings.per_page, 
      this.state.sort,
      filter,
    );
  }

  //----------------especial, actualiza valores del form -------------------------------
  handleChangeForm = (e) => {
    //requerimos la prop name="?" en cada control
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      },
    });
  }; 
  //======================================================
  //----cargamos los datos desde la base de datos
  /*id_cita, id_medico, fecha,id_citaho, hora_ini, hora_fin, paciente, medico,motivo, 
    cita.obs, atendido_sn, id_estadoc, paciente.cel, paciente.foto_path1 */
   //----------------------------------
  getList = (current_page, per_page,sort, filter) => {
    let param = { 
        current_page: current_page,
        per_page: per_page,
        sort: sort.field,
        direction: sort.dir,
        filters: JSON.stringify( filter),
    }
    
    this.setState({isLoading: true});
    //producto_crud?sort=nombre_p&direction=asc
    //producto_crud?page=2
    axios2.post("historiacrud_get_list2", param).then(res => {  
        this.setState({isLoading: false});
        
        this.setState({ userData: res.data.data,
                        pageSettings: { 
                          current_page: res.data.current_page,
                          total: res.data.total,
                          per_page: res.data.per_page,
                          from: res.data.from,
                          to: res.data.to,
                        }
                    });
        
    }).catch(error => {
        this.setState({userData:[]});
        this.setState({isLoading: false});
        alert("Error al cargar los datos");
        console.log(error);
    });
  };
 
  //----------------------------------
  setPage(page){
    console.log("setPage");
    console.log(page);
    //--------
    let pageSize = this.state.pageSettings.per_page;
    let sort = this.state.sort;
    this.getList(page, pageSize, sort);
  }
  setPageSize(pageSize){
    let page = this.state.pageSettings.page;
    let sort = this.state.sort;
    this.getList(page, pageSize, sort);
  }
  //----------------------------------
  setSort(sort){
    //this.setState({ sort: sort});
    
    let page = this.state.pageSettings.page;
    let pageSize = this.state.pageSettings.per_page;
    this.getList(page, pageSize, sort);

  }
 
  //------------------------------
  editRowClick(item){
    console.log("edit");
    console.log(item);
    /*const [ store, dispatch ] = this.context;
    const {user, paciente} = store;*/

    this.props.navigate('/historia/e/'+ item.id_historia);
  }
  deleteRowClick(item){
    console.log("delete");
    //console.log(item);
    //--------------------------------
    confirmService.show({
      message: 'Esta seguro de eliminar? ' + item.motivo_consulta,
      title: 'Confirmar',
      icon: 'fa fa-question-circle',
      classHeader: 'bg-default1 ',
      classButton: 'btn btn-danger',
      accept: () => {
        let idd = item.id_historia;
        this.save(idd, 'x')
      },
      reject: () => {
          console.log('cancel');
      },
      acceptLabel: 'Aceptar',
      rejectLabel: 'Cancelar'
    })
  }
  nuevo_click=() =>{
    console.log("nuevo")
  }
  //=================== clic en el boton para buscar los elementos
  save =(idd, ope) =>{
      
    let param = {...this.state.form,
                idd:idd,
                ope:ope};
    console.log(param);
    axios2.post("historiacrud_store", param)
    .then(res => {
        console.log(res.data);
        if(res.data.exito === 1){ 
            toast.success(res.data.mensaje);
            //--- tenemos que redireccionar
            //this.props.navigate('/historialist/'+ idd);
            let filter=[];

            this.getList(
              this.state.pageSettings.current_page,
              this.state.pageSettings.per_page, 
              this.state.sort,
              filter,
            );
            
        }else{
            toast.error(res.data.mensaje);
        }
  
    }).catch(error => {
        alert("---- Existio un error ---");
        console.log(error);
        
    });
    
  }
  
  //-------------------------------------
 
  render() {
    
    return (
      <div>

        <div className="content">
          <ToastContainer />
          { this.props.store.paciente.nombre }

          <section className="content-header">
            
            <Link to="/historia/n/0" className="btn btn-primary btn-xs pull-right">
                + Nuevo
            </Link>
            <h3>
              <i className="fa fa-user"></i> Exploración clínica
            </h3>
          </section>
          

          <div className="row">
            <div className="col-md-12">

              <div className="box box-primary collapsed-box1">
                <div className="box-header with-border">
                  
                </div>
                <div className="box-body">
                  <div className="">
                    
                    <NuDataTable
                      columns={this.state.columns}
                      dataSource={this.state.userData}
                      rowHeight={'auto'}
                      classNameTable="table table-hover"

                      pagination={true}
                      pageLengthOptions={[5, 10, 20, 50]}
                      pageSettings={this.state.pageSettings}

                      onChangePage={page => this.setPage(page)} /*ok */
                      onChangePageSize={pageSize => this.setPageSize(pageSize)} /*ok */
                      onChangeSort={sort => this.setSort(sort)}

                      onClicButton1={item => this.editRowClick(item)}
                      onClicButton2={item => this.deleteRowClick(item)}
                      onClicButton3={item => this.printRowClick(item)}
                      onClicButton4={item => this.showRowClick(item)}

                      showPrint={false}
                      showExportCsv={false}
                      showExportExcel={false}
                      isLoading={this.state.isLoading}
                    />

                  </div>
                </div>
              </div>



              {/* /.box -->*/}
            </div>
            {/*<!-- /.col -->*/}
          </div>





        </div>



      </div>
    );
  }
}
  
//------------para que soporte la funcionalidad de parametros
export default withRouterJim(HistoriaList);