import React from 'react'
import './styles.css';

import cUtilFecha from "helpers/cUtilFecha";
//npm install date-fns --save

class NuCalMes extends React.Component {
  state = {
    currentMonth: new Date(),
    selectedDate: new Date(),
  };

  nextMonth=()=>{
    //const fechaNew = cUtilFecha.addMonths(this.state.fecha, 1);
    //this.setState({fecha: fechaNew});
    this.props.onClickNext()
  }
  //----------------------------------
  prevMonth=()=>{
    //const fechaNew = cUtilFecha.addMonths(this.state.fecha, -1);
    //this.setState({fecha: fechaNew});
    this.props.onClickPrev()
  }
  //----------------------------------
  renderHeader() {
    const dateFormat = "MMMM YYYY";
 

    let fechax = this.state.fecha;

    /*let fecha1 = new Intl.DateTimeFormat("es", {
      month: "long",
      year: "long"
    }).format( fechax );*/

    //--- renderizamos los botones del header
    return (
      <div className="header row flex-middle">
        <div className="col col-start">
          <div className="icon" onClick={this.prevMonth}>
            chevron_left
          </div>
        </div>
        <div className="col col-center">
          <span>{  }</span>
        </div>
        <div className="col col-end" onClick={this.nextMonth}>
          <div className="icon">chevron_right</div>
        </div>
      </div>
    );
  }

  renderDays() {
    const dateFormat = "dddd";
    const days = [];

    let fechax = this.props.fecha;

    let startDate = cUtilFecha.startOfWeek( fechax, 0);
    //---renderizamos los dias de la semana
    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="col col-center cal-cell" key={i}>
          { new Intl.DateTimeFormat("es",{ weekday: "long" }).format( cUtilFecha.addDays(startDate, i) )}
        </div>
      );
    }

    return <div className="days row">{days}</div>;
  }

  renderCells() {
    //const { currentMonth, selectedDate } = this.state;
    const { selectedDate } = this.state;
    const fechax = this.props.fecha;

    const monthStart = cUtilFecha.startOfMonth(fechax); //inicio de mes 1/10/2022
    const monthEnd = cUtilFecha.endOfMonth(monthStart); //fecha de fin de mees 31/3/2022
    const startDate = cUtilFecha.startOfWeek(monthStart); //fecha inicio de semana
    const endDate = cUtilFecha.endOfWeek(monthEnd);

    const dateFormat = "D";
    const rows = [];

    let days = [];
    let day = startDate;
    let formattedDate = "";

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = cUtilFecha.format(day, dateFormat);
        const cloneDay = day;
        days.push(
          <div
            className={`col cell ${
              cUtilFecha.isSameMonth(day, monthStart)
                ? "disabled"
                : cUtilFecha.isSameDay(day, selectedDate) ? "selected" : ""
            }`}
            key={day}
            onClick={() => this.onDateClick( cloneDay )}
          >
            <span className="number">{formattedDate}</span>
            <span className="bg">{formattedDate}</span>
          </div>
        );
        day = cUtilFecha.addDays(day, 1);
      }
      rows.push(
        <div className="row" key={day}>
          {days}
        </div>
      );
      days = [];
    }
    return <div className="body">{rows}</div>;
  }

  onDateClick = day => {
    this.setState({
      selectedDate: day
    });
  };

  nextMonth = () => {
    this.setState({
      currentMonth: cUtilFecha.addMonths(this.state.currentMonth, 1)
    });
  };

  prevMonth = () => {
    this.setState({
      currentMonth: cUtilFecha.subMonths(this.state.currentMonth, 1)
    });
  };

  render() {
    return (
      <div className="calendar">
        {this.renderDays()}
        {this.renderCells()}
      </div>
    );
  }
}

export default NuCalMes;