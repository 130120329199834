import React from 'react';
import ReactDOM from 'react-dom';
//import './index.css';
import App from './App';
//import reportWebVitals from './reportWebVitals';


//--- bootstrap full 
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
//---------------

import 'font-awesome/css/font-awesome.min.css';
import 'react-notifications/lib/notifications.css';

import 'react-calendar/dist/Calendar.css';

import './assets/_preclinic/assets/css/style.css';
import './css/bootstrapnubox.css';
import './css/lte2_ex.css';
import './assets/css/tabla_basic.css';
import './assets/css/tabla_fixed.css';
import './css/tabla_plomo.css';

//-- toast
import "react-toastify/dist/ReactToastify.css";

// browser router
import {BrowserRouter} from 'react-router-dom';

//----uso de REDUX---
//import { Provider } from 'react-redux';
//import store from './store';

//---- uso de CONTEXT API 
import StoreProvider from './store/StoreProvider'; 

ReactDOM.render(
  <StoreProvider >
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </StoreProvider>
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
