import React from "react";

import axios2 from 'services/axios2';
import { withRouterJim } from "router/withRouterJim";

import {useParams} from "react-router-dom";
import {Link,hashHistory} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import NuDropdown from "components/nubox/NuDropdown";
import DatePicker from "components/DatePicker"

import Child from "views/cita_all/Child";
import CitaModal from "views/cita_all/CitaModal";
//import listac from '../assets/img/lista2.png';
import confirmService from 'components/confirmService';

import { NotificationContainer, NotificationManager } from 'react-notifications';
import {
  Table,
  Button,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  ModalFooter,
  ToastHeader,
} from "reactstrap";

import cUtilFecha from "helpers/cUtilFecha";
import NuCalendar from "components/nubox/NuCalendar";


const cbo=[
  {  value:'1', label: 'label1'},
];
const data = [
  { id_cita: 1, id_citaho:0, hora: "00:00", hora_ini: "00:30", historia:'V-415', paciente:"", motivo:"", atendido_sn:"", id_estadoc:""},
  { id_cita: 2, id_citaho:0, hora: "00:00", hora_ini: "00:30", historia:'V-415', paciente:"", motivo:"", atendido_sn:"", id_estadoc:""},
 
];

//$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
class Agenda extends React.Component {
  
  myRef = React.createRef();//create ref
  citaRef = React.createRef();//create ref

  state = {
    data: data,/* lista de citas */
    fechai:'',

    form: {
      id_medico: 0,
      fecha: "",
    },
    medicos:[],
  };
  
  //-----------------------------------------------
 
  //------------------------------------------------------
  componentDidMount() {
    console.log("componentDidMount citas");
    //--limpiamos el array de prueba
    this.setState({data:[]});

    //const date = new Date();
    //const defaultValue = date.toLocaleDateString('en-CA');
  
    //this.setState({ form:{...this.state.form, fecha: defaultValue,}}); 
    let fechaActual = cUtilFecha.fechaActualStr();
    this.setState({ form:{...this.state.form, fecha: fechaActual }}); 

    this.cargar_medicos();
    //this.cargar_horas();
    //this.cargar_citas();
    //this.fetchData(this.state.fechai);
    
    
  }

  //----------------especial, actualiza valores del form -------------------------------
  handleChangeForm = (e) => {
    //requerimos la prop name="?" en cada control
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      },
    });
  }; 

  handleChangeDate = (e) => {
    //requerimos la prop name="?" en cada control
    console.log("name->"+e.target.name);
    console.log("value->"+ e.target.value);
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      },
    });
  }; 
  
  handleCellChangeCheck=(index, columnId, value)=>{
    
    const newData = [...this.state.data];
    newData[index][columnId] = value;
    
    console.log(newData);
    //setData({  data: newData    });
    this.setState({ datalist: newData });
  }
  handleChange = (e) => {
    //requerimos la prop name="?" en cada control
    this.setState({
        [e.target.name]: e.target.value,
    });
  }; 
  //======================================================
  //----cargamos los datos desde la base de datos
  /*id_cita, id_medico, fecha,id_citaho, hora_ini, hora_fin, paciente, medico,motivo, 
    cita.obs, atendido_sn, id_estadoc, paciente.cel, paciente.foto_path1 */
  cargar_medicos = () => {
    let param = { 
    }
    
    axios2.post(process.env.REACT_APP_API_URL  + "cbo_medicos", param).then(res => {
        if(res.data.length>0){ 
            //NotificationManager.success("Correcto");
            //---lega un array en este formato:
            //-- { value: 1, label: "Dr. Gerson Abner Villafuerte Oporto" }
            this.setState({medicos: res.data});

            //-- esrtablecemos la primera opcion como la elegida
            this.setState({
              form: {
                ...this.state.form,
                id_medico: res.data[0].value,
              },
            });
            //console.log("cargar_medicos");
            //console.log(this.state.form);

        }else{
            NotificationManager.error("No existen medicos");
        }

    }).catch(error => {
        alert("---- Existio un error ---");
        console.log(error);
        
    });
  };
 
  //----cargamos los datos desde la base de datos
  fetchData = (fechai, id_medico) => {
    let param = { 
      id_espe:1,
      id_medico: id_medico,
      fechai: fechai,
    }
    //console.log(param);

    axios2.post(process.env.REACT_APP_API_URL  + "citahoy_get_citas", param).then(res => {
        if(res.data.length>0){ 
            
            this.setState({ data: res.data});
            
        }else{
            this.setState({ data: []});
            toast.error("No existen registros", {position: toast.POSITION.TOP_LEFT});
        }

    }).catch(error => {
        //if(error.response.status && error.response.status===400)
        alert("Bad Request");
        console.log(error);
        
    });
  };
 
  
  //---clic en el boton para buscar los elementos
  buscar_click=(e)=>{
    //console.log(this.state.fechai);
    if(this.state.fechai==""){
      toast.error("Error Notification !", {
        position: toast.POSITION.TOP_LEFT
      });
      return;
      
    }

    this.fetchData(this.state.form.fecha, this.state.form.id_medico);
    
  }
  
  //-------------------------------------
 
  render() {
    
    return (
      <div>

        <div className="content">
          <ToastContainer />

          <section className="content-header">
            <h2>
              <i className="fa fa-calendar"></i> Agenda
            </h2>
          </section>
          { JSON.stringify( this.state.form) }
          <div className="row">
            <div className="col-md-3">
              {/* --- Profile Image --- */}
              <div className="box box-primary">
                <div className="box-body box-profile">
                  <form id="FormAgendaFiltro" autocomplete="off" novalidate="novalidate">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group grupo">
                          <br />
                          <DatePicker 
                            name="fecha" 
                            value={this.state.form.fecha} 
                            onChange={this.handleChangeDate} 
                          />

                        </div>
                      </div>
                    </div>
                    <br />

                    <div className="row pb-2">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="control-label">Médico</label>
                          <NuDropdown 
                            options={this.state.medicos} 
                            onChange={this.handleChangeForm}
                            value={this.state.form.id_medico} 
                            name="id_medico" />
                        </div>
                      </div>
                    </div>

                    <div className="row pb-2">
                      <div className="col-md-12">
                        <label className="control-label">Estado</label>
                        <div className="form-group">
                          <select name="estado" className="form-control input-sm" style={{ width: "100%" }} tabindex="-1"
                            aria-hidden="true">
                            <option value="">Todos</option>
                            <option value="1">Atendido</option>
                            <option value="2">Anulado</option>
                            <option value="3">Pendiente</option>
                            <option value="4">Atendiendose</option>
                            <option value="5">Citado</option>
                            <option value="6">Confirmado por Telefono</option>
                            <option value="7">En sala de espera</option>
                            <option value="8">No asiste</option>
                            <option value="9">No confirmado</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <button type="button" className="btn btn-md btn-block btn-primary" onClick={ this.buscar_click}>
                          <i className="fa fa-search"></i> Buscar
                        </button>
                      </div>
                    </div>
                  </form>
                  {/* --- <a href="#" className="btn btn-primary btn-block"><b>Follow</b></a> --- */}
                </div>
                {/* --- /.box-body --- */}
              </div>
              {/* --- /.box --- */}

            </div>
            {/* ------------------------------------------------------------------ */}
            <div className="col-md-9">
              <div className="box">
                <div className="box-header">

                </div>
                {/* /.box-header */}
                <div className="box-body">

                  <div className="row">
                    <div className="col-md-12">
                      <div className="botones">
                        <div className="btn-group">
                          <button className="btn btn-primary" data-calendar-nav="prev"><i
                              className="fa fa-fw fa-chevron-left"></i></button>
                          <button className="btn btn-default" data-calendar-nav="today">Hoy</button>
                          <button className="btn btn-primary" data-calendar-nav="next"><i
                              className="fa fa-fw fa-chevron-right"></i></button>
                        </div>
                        <h4>Martes 28 Febrero 2023</h4>
                        <br/>
                        <div className="form-group" style={{ display: "flex" }}>
                          
                          <div className="radio-inline">
                            <label className="checkbox">
                              <input type="radio" name="vista" value="month" data-calendar-view="month" /> Mes
                            </label>
                          </div>
                          <div className="radio-inline">
                            <label className="checkbox">
                              <input type="radio" name="vista" value="week" data-calendar-view="week" /> Semana
                            </label>
                          </div>
                          <div className="radio-inline">
                            <label className="checkbox">
                              <input type="radio" name="vista" value="day" data-calendar-view="day" checked="" /> Día
                            </label>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>

                  <hr />
                  

                  <div className="row">
                    <div className="col-md-12">

                      <NuCalendar 
                        name="fechai"
                        value={ this.state.fechai } 
                        onChange={(e) => this.handleChange(e)} 
                      />
                      


                      <div className="table-responsive">

                        <table id="tabla01" className="table table-striped table-bordered table-condensed table-hover"
                          width="100%" cellSpacing="0" cellPadding="0">
                          <thead>
                            <tr className="btn-primary">
                              <th className="text-center">Acción</th>
                              <th className="text-center">Paciente</th>
                              <th className="text-center">Telefono</th>
                              <th className="text-center">Hora</th>
                              <th className="text-center">Motivo</th>
                              <th className="text-center">Atendido</th>
                              <th className="text-center">Foto</th>

                            </tr>
                          </thead>
                          <tbody>
                            {/*id_cita, id_medico, id_paciente, fecha,id_citaho, hora_ini, hora_fin, paciente, medico,motivo,
                            cita.obs, atendido_sn, id_estadoc,
                            paciente.cel, paciente.foto_path1 */}
                            {this.state.data.map((item, index) => (
                            <tr>
                              <td>
                                <Link to={ "historia_clinica/" + item.id_paciente } 
                                  className="btn btn-warning float-right"
                                  style={{ background: 'chocolate'}}>
                                <i className="fa fa-fw fa-list-alt"></i> Historia
                                </Link>
                              </td>
                              <td>{ item.paciente }</td>
                              <td>{ item.cel }</td>
                              <td>{ item.hora_ini }</td>
                              <td>{item.motivo}</td>
                              <td className="text-center">

                              </td>
                              <td>
                                <img src="http://localhost:8080/clinica_dental/public/afiles/1665435807.jpg"
                                  className="avatar" alt="" />
                              </td>
                            </tr>
                            ))}
                          </tbody>
                        </table>

                      </div>
                    </div>
                  </div>
                </div>
                {/* /.box-body */}
              </div>
              {/* /.box --- */}
            </div>
            {/*{/* --- /.col --- */}
          </div>

        </div>

          <Child ref={this.myRef} />
          <CitaModal 
            ref={this.citaRef} 
            okModal={this.citaOkModal} 
            cancelModal={this.citaCancelModal}
            value={this.state.form.id_cita} 
          />

        {/*--- modal---- */}

      </div>
    );
  }
}
  
//------------para que soporte la funcionalidad de parametros

export default withRouterJim(Agenda);