import React from 'react'
const miselect={
    fontSize: "14pt",
    /*lineHeight: "1.5",*/
    border: "1px solid #ced4da",
    borderRadius: ".25rem",
    display: "block",
    width: "100%",
    padding: ".275rem .75rem",
}
export const DropdownComponent = props => (

    <div className="">
        {/*<label>{props.labelName} :</label>*/}
        <select className="form-control" style={miselect} name="{props.name}" value={props.value}  onChange={props.onChange}  >
            <option key={0} value={"0"} defaultValue  > </option>
            {
                props.options.map(option => (
                    <option key={option.value} value={option.value} >{option.label}</option>
                ))
            }
        </select>
    </div>
);