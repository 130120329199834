import React from 'react'
import './styles.css';


import cUtilFecha from "helpers/cUtilFecha";
//npm install date-fns --save
/*
// event source that contains custom events on the scope 
$scope.events = [
  {title: 'All Day Event',start: new Date(y, m, 1)},
  {title: 'Long Event',start: new Date(y, m, d - 5),end: new Date(y, m, d - 2)},
  {id: 999,title: 'Repeating Event',start: new Date(y, m, d - 3, 16, 0),allDay: false},
  {id: 999,title: 'Repeating Event',start: new Date(y, m, d + 4, 16, 0),allDay: false},
  {title: 'Birthday Party',start: new Date(y, m, d + 1, 19, 0),end: new Date(y, m, d + 1, 22, 30),allDay: false},
  {title: 'Click for Google',start: new Date(y, m, 28),end: new Date(y, m, 29),url: 'http://google.com/'}
];
 */
class NuCMes extends React.Component {
  state = {
    selectedDate: new Date(), /* fecha seleccionada al hacer click */
    startDate: new Date(),
    
  };

  componentDidMount(){
    //--inicializar valores
    console.log("componentDidMount");
    
    //const fechaNew = new Date();
    const start = cUtilFecha.startOfWeek( this.state.startDate); //inicio de semana
    this.setState({ startDate: start});
    //this.estaSemana();
  }
  //------------------------------------------------------
  renderHeader(title) {
    const dateFormat = "MMMM YYYY";

    return (
      <div className="ca-header ca-toolbar " >
        <div>
          <div className="btn-group">
            <button className="btn btn-primary" data-calendar-nav="prev" onClick={this.prevWeek}><i className="fa fa-fw fa-chevron-left"></i></button>
            <button className="btn btn-primary" data-calendar-nav="next" onClick={this.nextWeek}><i className="fa fa-fw fa-chevron-right"></i></button>
          </div>
        </div>

        <div>
          <h2 className="ca-toolbar-title">{title}</h2>
        </div>

        <div className="form-group pull-left">
          <button className="btn btn-primary" onClick={this.estaSemana}> Esta semana </button>
        </div>
      </div>
    );
  }

  get_color_estado=(estado)=>{
    let sol="";
    const filteredObj = this.props.estados.filter((obj) => {
      return obj.value === estado;
    });
    //console.log("filteredObj");
    //console.log(filteredObj);
    return filteredObj.color;
  }
  get_class=(estado)=>{
    let sol="b-default";
    
    switch (estado){
      case 0:  sol="b-primary"; break;  //azul
      case 1:  sol="b-warning"; break;  //naranja
      case 2:  sol="b-danger"; break;  //rojo
      case 3:  sol="b-success"; break;  //verde
      default: sol="b-default";
    }
    
    return sol;
  }
  /* ---- revisa todos los eventos 
    [{"id":99,"titulo":"WALKER ORELLANA","fecha":"2023-04-11",
    "hora_ini":"09:30", "estado":0}]
  */
  //---- si es celda vacia, clicNuevo, sino ClicEditar
  renderCell( fecha, hora) {
    //console.log("fecha:"+fecha, " hora:"+hora);
    const filteredObj = this.props.events.filter((obj) => {
      return obj.fecha === cUtilFecha.toDateMy(fecha) && obj.hora_ini === hora;
    });
    //console.log("filteredObj:" + filteredObj.length);
    
    //--como ya tenemos filtrado
    if(filteredObj.length === 0)
    {
      return (
        <div className='nucellm' onClick={()=> this.cellClick(fecha, hora, null)}> 
        </div>
      );
    }else{
      // retornamos la lista de objeto citas
      // 
      return filteredObj.map((obj, index) => (
        <button key={index} 
                className={ `cal-day-box-event1 b-button  ` + this.get_class(obj.estado)}
                onClick={()=> this.cellClick(fecha, hora,  obj)}
          >
          <div className="cal-content">
            <span className="cal-title">{ obj.titulo }</span>
          </div>
        </button>
      ));
    }

    
  }
  //----------------------------------------------------------------------------
  generateDays() {
    console.log("generateDays");
    const dateFormat = "dddd";
    const days = [];

    
    const startDate = this.state.startDate; //ej es 11-04-2023
    const start = cUtilFecha.startOfWeek(startDate); //inicio de semana
    //--- nosotros tenemos que empezar en el primer dia de la semana
    
    for (let i = 0; i < 7; i++) {
      let fecha = cUtilFecha.addDays(start, i);
      let dia = fecha.getDate(); //dia 1,2,...
      let dia_literal = cUtilFecha.dia_literal_peq(fecha);// Lun
      //let dia_literal = cUtilFecha.dia_literal(fecha);// Lun
      days.push({"dia_literal": dia_literal, "dia": dia, "fecha": fecha } );
      //console.log (fecha);
    }
    return days;
  }
  //-----------------------------------------------
  generateHours() {
    console.log("generateHours");
    const dateFormat = "dddd";
    const horas = [];
    //const { horaIni, horaFin, intervalo } = this.props;
    //--new Date(year,month,day,hours,minutes,seconds)
    let horai = new Date(2023,3,14,8,0,0);
    let horaf = new Date(2023,3,14,17,0,0);

    //----le sumaremos 30 minutos 
    let  cn=0;
    let horaStr="";
    while (cn< 18) {
      //let hora_new = moment(horai).add(30, 'm').format('HH:MM');
      horai = cUtilFecha.addMinutes(horai, 30);

      //horaStr= cUtilFecha.toTimeHHMM(horai);
      horaStr = cUtilFecha.toTimeHHMM(horai);
      //horas.push( {"hora": horai.getHours(),  "minuto": horai.getMinutes() });
      horas.push( {"hora": horaStr});
      cn++;
    }
    
    return horas;
  }
  refresh=()=>{
    const fechai = cUtilFecha.startOfWeek( this.state.startDate); //inicio de semana
    const fechaf = cUtilFecha.endOfWeek(this.state.startDate);
    
    this.props.onChange(fechai, fechaf);
  }
  //----------------------------------------------------------------------------  
  nextWeek = () => {  
    //console.log("nextWeek");
    const auxd = cUtilFecha.nextWeek(this.state.startDate);//solo suma 7 dias
    const fechai = cUtilFecha.startOfWeek(auxd); //inicio de semana
    const fechaf = cUtilFecha.endOfWeek(auxd);
    //console.log("nextWeek:"+ auxd.toDateString());
    this.setState({
      startDate: fechai,
    });
    //---------------
    this.props.onChange(fechai, fechaf);
  };

  prevWeek = () => {
    //console.log("prevWeek");
    const auxd = cUtilFecha.prevWeek(this.state.startDate);//solo resta 7 dias
    const fechai = cUtilFecha.startOfWeek(auxd); //inicio de semana
    const fechaf = cUtilFecha.endOfWeek(auxd);
    //console.log("prevWeek:"+ auxd.toDateString());

    this.setState({
      startDate: fechai
    });
    //---------------
    this.props.onChange(fechai, fechaf);
  };

  //[{"id":99,"titulo":"WALKER ORELLANA","fecha":"2023-04-11","hora_ini":"09:30","estado":0}]
  estaSemana=()=>{
    //console.log("estaSemana");
    let fechaNew = new Date();
    //---ajuste por el cambio de horario
    //fechaNew.setMinutes(fechaNew.getMinutes() + fechaNew.getTimezoneOffset())
    //----calculamos start date, enddate
    //const fechai = cUtilFecha.startOfWeekEx(fechaNew,1);
    const fechai = cUtilFecha.startOfWeek(fechaNew);
    const fechaf = cUtilFecha.endOfWeek(fechaNew);
    //console.log("fechai->" + fechai.toDateString());
    this.setState({ startDate: fechai});
    //---------------
    this.props.onChange(fechai, fechaf);
  }
  //------------- tipo DATE
  estaFecha=(fechaNew)=>{
    //let fechaNew = new Date();
    console.log(fechaNew.toDateString());
    //---ajuste por el cambio de horario
    //fechaNew.setMinutes(fechaNew.getMinutes() + fechaNew.getTimezoneOffset())
    //----calculamos start date, enddate
    //const fechai = cUtilFecha.startOfWeekEx(fechaNew,1);
    const fechai = cUtilFecha.startOfWeek(fechaNew);
    const fechaf = cUtilFecha.endOfWeek(fechaNew);
    //console.log("fechai->" + fechai.toDateString());
    this.setState({ startDate: fechai});
    this.props.onChange(fechai, fechaf);
  }
  //---------------- la hora esta en HH:MM
  cellClick=( fecha, hora, obj )=>{
    console.log("cellClick");
    //console.log("fecha: " + fecha);
    //console.log("hora: " + hora);
    
    const cad = cUtilFecha.toDateMy(fecha);
    //var mydate = new Date('2014-04-03');
    //console.log(cad + " " + hora +":00");

    const mydate = new Date(cad + " " + hora +":00");
    //mydate.setMinutes(mydate.getMinutes() + mydate.getTimezoneOffset())
    //console.log("hora:"+ mydate.getHours());
    //console.log("min:"+ mydate.getMinutes());

    //console.log(mydate);

    this.setState({
      selectedDate: mydate
    });
    
    this.props.cellClick(mydate, obj);
  }
  //----------------------------
  onDateClick = day => {
    console.log("onDateClick");
    this.setState({
      selectedDate: day
    });
  };

  formatTitle=()=>{
    const startD = this.state.startDate;

    const dia_ini = startD.getDate();
    const fecha_fin = cUtilFecha.endOfWeek(startD);
    const dia_fin = fecha_fin.getDate();
    const mes_int = startD.getMonth()+1; //por javascript
    const mes = cUtilFecha.get_nombre_mes(mes_int,2);
    const anyo = startD.getFullYear();
    return `${dia_ini} - ${dia_fin} ${mes} ${anyo}`;
  }
  //---------------------------------------------------------
  render() {
    let days = this.generateDays();
    let hours = this.generateHours();

    const { selectedDate, startDate } = this.state;
    const { events  } = this.props;
    const title = this.formatTitle();
    return (
      <>
      { this.renderHeader(title) }
        
      <div className="tabla_fixed" style={{ width: "100%", backgroundColor: "gray" }}>
        { //JSON.stringify(this.state) 
         }
        { /*JSON.stringify(cUtilFecha.toTimeHHMM(selectedDate))*/ }

        <table className="tabla_boot table-striped1 tabla_fixed_borde table-condensed table-hover-cell">
          <thead>
            <tr>
              <th className='cell_azul'>Hora</th>
              {
                days.map( (item, index)=>(
                  <th className='cell_azul' 
                      key={index}
                      style={{ width: "110px"}}
                      >
                      {item.dia_literal}&nbsp;{item.dia}
                  </th>
                ))
              }
              
              {/*<th>Martes</th>
              <th>Miércoles</th>
              <th>Jueves</th>
              <th>Viernes</th>
              <th>Sábado</th>
              <th>Domingo</th>*/}
            </tr>
          </thead>
          <tbody>
            {/* render las filas con horas */}
            {
              hours.map( (item, index)=>(
                <tr key={index}>
                  <td>
                    { item.hora }
                  </td>
                  {/* render los dias de la semana*/}
                  {
                    days.map( (item2, index2)=>(
                      <td key={index2} 
                          className={`  ${
                            cUtilFecha.isSameDay(item2.fecha, selectedDate)
                            ?
                              item.hora== cUtilFecha.toTimeHHMM(selectedDate)? "selectedxxx" : ""
                            : ""
                            }

                            ${ item2.dia_literal==="Dom"? "cel-plomo":"" }
                            ${ item2.dia_literal==="Sáb"? "cel-plomo":"" }
                            `
                          }
                          
                          >
                          
                          {this.renderCell(item2.fecha, item.hora)}
  
                        
                      </td>    
                    ))
                  }
              
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
      </>
    );
  }
}

export default NuCMes;