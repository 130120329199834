import React, { useReducer } from "react";
import axios from 'axios';
import axios2 from 'services/axios2';

import {useParams} from "react-router-dom";
import {Link,hashHistory} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { NuDropdown } from "../../components/nubox/NuDropdown";

import ImageUpload from "components/nubox/ImageUpload";
import confirmService from 'components/confirmService';


import { withRouterJim } from "router/withRouterJim";

//--- CONTEXT API -----------
import { useStore, useDispatch, StoreContext } from "store/StoreProvider";
import {types, storeReducer} from "store/StoreReducer"

import { NotificationContainer, NotificationManager } from 'react-notifications';
import {
  Table,
  Button,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  ModalFooter,
  ToastHeader,
} from "reactstrap";

import userimg from 'assets/img/user.jpg';

//type:'numeric'

const cbo=[
  {  value:'1', label: 'label1'},
  {  value:'2', label: 'Clss'},
  {  value:'3', label: 'Nod'},
  {  value:'4', label: 'Lod'},
  {  value:'5', label: 'Elggg'},
]

const data = [
  { id_paciente: 1,  nombre: "ALEX LIZARRAGA",  nro_doc: "00000023",  fecha_nac: "2022-01-13",  foto_path1: "1665435807.jpg",  dir: "Cerro Bola #319",  cel: "0123456789"},
  { id_paciente: 2,  nombre: "MARIANELA",  nro_doc: "00000023",  fecha_nac: "2022-01-13",  foto_path1: "1665435807.jpg",  dir: "Cerro Bola #319",  cel: "0123456789"},
 
];

//$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
const initialForm={
  idd:0,
  ope:'n',  
  id_paciente: 0,
  nombre:'',
  nro_doc:'',
  fecha_nac:null,
  foto_path1:'',
  cel:'',
  dir:'',
  profesion:'',
  sexo:'M',
  estado:1,
}

class Paciente_show extends React.Component {
  state = {

    modalImagen: false,
    form: initialForm,
    data: data,/* lista de citas */
    
  };
  //---- context API
  static contextType  = StoreContext;

  

  //------------------------------------------------------
  componentDidMount() {
    //--limpiamos el array de prueba
    this.setState({data:[]});

    const date = new Date();
    const defaultValue = date.toLocaleDateString('en-CA');
  
    //this.setState({fechai: defaultValue}); 
    //this.setState({ form:{...this.state.form, fecha: defaultValue,}}); 

    // Get the userId param from the URL.
    let idd = this.props.params.idd;
    let ope = this.props.params.ope;
    
    console.log("idd-->" + idd);
    console.log("ope-->" + ope);
    this.setState({ form:{...this.state.form, idd: idd, ope:ope}}); 
    if(ope==='n'){
      //this.limpiar();
    }else{
      this.cargar_paciente(idd, ope);
    }
    
    //this.cargar_citas();
    //this.fetchData(this.state.fechai);
  }
  limpiar =()=>{
    this.setState({form: initialForm});
  }
  //------------ cambiamos la fecha
  /*handleChange = event =>{
    this.setState({ name: event.target.value});
  }*/
  //----------------especial, actualiza valores del form -------------------------------
  handleChangeForm = (e) => {
    //requerimos la prop name="?" en cada control
    
    //--- esto lo hacemos porque en vez de utilizar valores true, false
    //--- tenemos que utilizar 1, 0
    if(e.target.type==="checkbox"){
      //console.log("cheched:"+ e.target.checked);
      let res = e.target.checked? 1:0;
      //console.log("value:"+ res);
      
      this.setState({
        form:{
          ...this.state.form,
          [e.target.name]: res,
        }
      })
    }else{
      this.setState({
        form: {
          ...this.state.form,
          [e.target.name]: e.target.value,
        },
      });
    }
  };
  
  isEmptyObject= (obj) => {
        for (var property in obj) {
            if (obj.hasOwnProperty(property)) {
                return false;
            }
        }
     
        return true;
  }

  // Toggle for Modal
  toggle = () => {
    this.setState({ modalImagen:!this.state.modalImagen });
  }
  showModalImagen = (event) =>{
    event.preventDefault();
    console.log("show modal imagen");
    console.log(this.state.modalImagen);
    this.setState({ modalImagen: true });
    console.log(this.state.modalImagen);
  }
  deleteImagen=()=>{
    this.setState({ form:{...this.state.form, foto_path1: ""}}); 
  }

  okUploadedModal = (filename) =>{
    console.log("ok upload" + filename);
    //---ocultamos la ventana
    this.setState({ modalImagen: false });
    this.setState({ form:{...this.state.form, foto_path1: filename}}); 
  }
  closeUploadedModal = () =>{
    console.log("cancel");
    //---ocultamos la ventana
    this.setState({ modalImagen: false });
  }
  //====================================================== 
  //----cargamos los datos desde la base de datos
  /*{
    "id_paciente": 1,
    "nombre": "ALEX LIZARRAGA",
    "nro_doc": "00000023",
    "fecha_nac": "2022-01-13",
    "foto_path1": "1665435807.jpg",
    "dir": "Cerro Bola #319",
    "cel": "0123456789"
  },*/
  cargar_paciente = (idd, ope) => {
    
    //context API
    console.log("context API");
    //const miContext = this.context;
    //console.log(miContext);
    const [ store, dispatch ] = this.context;
    const {user, paciente} = store;

    

    let param = { 
      idd: idd,
      ope:'f'
    }
    console.log(param);
    axios2.post(process.env.REACT_APP_API_URL  + "pacientecrud_store", param)
    .then(res => {
      
        if(! this.isEmptyObject(res.data)){ 
            
            this.setState({form: res.data});
            //añadimos el id y ope
            this.setState({
              form: {  ...this.state.form, 
                idd: idd,  
                ope: ope,
              },
            });
            //----------- ahora colocamos en el estado global datos del paciente
            console.log("dispatch");
            dispatch({type: types.setPaciente, payload: res.data});
            //miContext
            //---------------
        }else{
          toast.error("Registro no encontrado", {
            position: toast.POSITION.TOP_RIGHT
          });
        }

    }).catch(error => {
        alert("---- Existio un error ---");
        console.log(error);
        
    });
  };
      
  imprimir =(id_certi)=>{
    let fileURL = process.env.REACT_APP_API_URL  + "certificado_reciboPDF/"+ id_certi;
    let  windowFeatures = 'width=650,height= 700, left=100,top=100 ';
    window.open(fileURL,'',windowFeatures);
  }
  //----------------
  
  /* === This is where the magic happens ==== */
  handleSubmit = event => {
    event.preventDefault();
    //this.cargar_pacientes();
  }

  AsignarFoto = (filename) =>{
    /*this.setState({
      form: {
        ...this.state.form,
        "fotopath1": filename,
      },
    });*/
  }
  
  /*handleChange = event =>{
    this.setState({ name: event.target.value});
  }*/
  //-----------------------------------------------
  aceptar_click = () => {
    console.log("aceptar");
    
    confirmService.show({
      message: '¿Está seguro de que desea continuar?',
      title: 'Confirmación',
      icon: 'fa fa-question-circle',
      classHeader: 'bg-default1 ',
      classButton: 'btn btn-danger',
      accept: () => {
          
          this.save();
      },
      reject: () => {
          console.log('cancel');
      },
      acceptLabel: 'Aceptar',
      rejectLabel: 'Cancelar'
    })
 

    
    //save();
}
save =() =>{
  const [ store, dispatch ] = this.context;

    let param = this.state.form;
    console.log(param);
    
    axios2.post(process.env.REACT_APP_API_URL  + "pacientecrud_store", param)
    .then(res => {
        if(res.data.exito === 1){ 
            toast.success(res.data.mensaje);
            console.log("res.data")
            console.log(res.data.data);
            //---ahora actualizamos el context API
            const paciente= {
              "id_paciente": res.data.data.id_paciente,
              "nombre": res.data.data.nombre,
              "foto_path1": res.data.data.foto_path1,
              "fecha_nac": res.data.data.fecha_nac,
            }
            dispatch({type: types.setPaciente, payload: paciente });
        }else{
            toast.error(res.data.mensaje);
        }

    }).catch(error => {
        alert("---- Existio un error ---");
        console.log(error);
        
    });
  
}
  //-------------------------------------
  render() {
    //------------ context API --------------
    const [ store, dispatch ] = this.context;
    const {user, paciente} = store;

    const form = this.state.form;
    //console.log( process.env.REACT_APP_AFILES + this.state.form.foto_path1 );
    const foto_url = process.env.REACT_APP_AFILES + this.state.form.foto_path1;
    console.log (foto_url);
    return (
      <div>
        <ToastContainer />
        
        

        <div className="content">

          {/* ---   --- */}
          { 
            //JSON.stringify(this.state.form )
          }
          {/* ---   --- */}


          <section className="content-header">
              { form.ope==='n'? <h3>Nuevo Paciente</h3>:''}
              { form.ope==='e'? <h3>Editar Paciente</h3>:''}
            
          </section>


          <section className="content">
            <form >
              
              

              <div className="row">
                <div className="col-md-3">

                  {/*<!-- Profile Image -->*/}
                  <div className="box box-primary">
                    <div className="box-body box-profile" style={{ textAlign: "center" }}>
                      <img 
                          className="profile-user-img img-responsive img-circle" 
                          src={  form.foto_path1===''? userimg:foto_url }
                          alt="User profile" 
                          style={{ maxWidth: "100px"}} />
                        <p className="text-muted text-center"></p>

                        <div className="input-group ">
                          <input 
                            type="text" 
                            name="foto_path1" 
                            value={this.state.form.foto_path1} 
                            onChange={this.handleChangeForm}  
                            readOnly="" 
                            className="form-control input-sm" />

                            <span className="input-group-btn">
                              <button 
                                type="button" 
                                className="btn btn-default btn-flat"
                                onClick={ this.showModalImagen}>...</button>
                              <button 
                                type="button" 
                                className="btn btn-default btn-flat" 
                                onClick={ this.deleteImagen}
                                >
                                <i className="fa fa-trash"></i>
                              </button>
                            </span>
                            
                            <hr/>

                            


                        </div>

                        <ul className="list-group list-group-unbordered">
                          <li className="list-group-item">

                            <div className="form-group">
                              <div className="input-group">

                                <span className="text-danger">Máximo tañano 2 MB </span><br/>
                              </div>

                            </div>
                            
                          </li>




                        </ul>


                    </div>
                    {/*<!-- /.box-body -->*/}
                  </div>
                  {/*<!-- /.box -->*/}


                  
                </div>

                {/*<!-- /.col -->*/}
                <div className="col-md-9">

                  <div className="box box-primary collapsed-box">
                    <div className="box-header with-border">

                      <div className="row">
                        <div className="col-md-3">
                          <div className="form-group">

                            <label className="col-form-label">Cod paciente:</label>
                            <input  
                              name="id_paciente" 
                              value={this.state.form.id_paciente} 
                              onChange={this.handleChangeForm} 
                              type="text" 
                              className="form-control" readOnly="readOnly" 
                              />
                          </div>
                        </div>
                      </div>

                      {/* ---   --- */}
                      <div className="row">
                        <div className="col-md-8">
                          <div className="form-group has-success">
                            <label className="col-form-label">Nombres y apellidos: *</label>
                            <input  name="nombre" 
                                value={this.state.form.nombre} 
                                onChange={this.handleChangeForm}
                                type="text" 
                                className="form-control" 
                                style= {{  }} 
                                data-valtipo="NB" data-valreq="true"/>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                          </div>
                        </div>
                      </div>

                      {/* ---   --- */}
                      <div className="row">

                        <div className="col-md-4">
                          <div className="form-group ">
                            <label className="col-form-label">Nro doc.: *</label>
                            <input  
                              name="nro_doc" 
                              value={this.state.form.nro_doc} 
                              onChange={this.handleChangeForm}
                              type="text" className="form-control" />
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="col-form-label">Fecha de nacimiento: *</label>
                            <input  name="fecha_nac" 
                              value={this.state.form.fecha_nac} 
                              onChange={this.handleChangeForm}
                              type="date" 
                              className="form-control"
                              data-valtipo="FM" data-valreq="true"/>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="col-form-label">Celular: *</label>
                            <input  name="cel" 
                              value={this.state.form.cel} 
                              onChange={this.handleChangeForm}
                              type="text" className="form-control" 
                              data-valtipo="LN" data-valreq="true" />
                          </div>
                        </div>

                      </div>
                      {/* ---   --- */}
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group ">
                            <label className="col-form-label">Direccion: *</label>
                            <input  
                              name="dir" 
                              value={this.state.form.dir} 
                              onChange={this.handleChangeForm}
                              type="text" 
                              className="form-control" placeholder=" "/>
                          </div>
                        </div>
                      </div>
                    
                      {/* ---   --- */}
                      <div className="row">

                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="col-form-label">Profesión:</label>
                            <input  
                              name="profesion" 
                              value={this.state.form.profesion} 
                              onChange={this.handleChangeForm}
                              type="text" 
                              className="form-control" />
                          </div>
                        </div>
                        <div className="col-md-5">
                          <div className="form-group">
                            <label className="col-form-label">Sexo:</label>
                            <br/>
                            <label htmlFor="male">Masculino</label> 
                            <input
                              type="radio"
                              id="male"
                              name="sexo"
                              value="M"
                              checked={this.state.form.sexo === "M"}
                              onChange={this.handleChangeForm}
                            />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            
                            <label htmlFor="female">Femenino</label>
                            <input
                              type="radio"
                              id="female"
                              name="sexo"
                              value="F"
                              checked={ this.state.form.sexo === "F"}
                              onChange={(e) => this.handleChangeForm(e)}
                            />
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="form-group has-success">
                            <label className="control-label">Estado *</label>
                            <select name="estado" 
                                    value={this.state.form.estado} 
                                    onChange={this.handleChangeForm} 
                                    className="form-select input-sm" >
                              <option value="1" >Activo</option>
                              <option value="0" >Inactivo</option>

                            </select>
                          </div>
                        </div>

                      </div>
                      {/* ---   --- */}

                      <hr />
                        {/* ---   --- */}
                    
                       
                     
                        {/* ---   --- */}
                     
                        {/* ---   --- */}
                    </div>
                  </div>

                  <div className="box-footer">
                    <div className="form-group">
                      <div className="text-center">
                        <Button onClick={ this.aceptar_click } className="btn btn-success">
                          <i className="fa fa-floppy-o"></i>&nbsp;
                          Guardar Datos
                        </Button>
                        &nbsp;
                        <Link to="/pacientes" className="btn btn-default">Volver</Link>
                      </div>
                    </div>
                  </div>
                  {/* ---   --- */}
                  <div id="divres"></div>
                  {/* ---   --- */}
                </div>
              </div>
              
            </form>
          </section>
              
        </div>



        <Modal isOpen={this.state.modalImagen}
          toggle={ this.toggle }
          >
          <ModalHeader style={{display: 'block'}}>
            Subir archivo png/jpg
            <span style={{float: 'right'}} onClick={()=>this.toogle()}>x</span>
          </ModalHeader>
          <ModalBody>
              <ImageUpload 
                okModal={ this.okUploadedModal}
                closeModal={ this.closeUploadedModal}
                fileName="fileName"
              />
          </ModalBody>
        </Modal>

      </div>
    
    );
  }
}
  
//------------para que soporte la funcionalidad de parametros
export default withRouterJim(Paciente_show);