import React, { useReducer } from "react";
import axios from 'axios';
import axios2 from 'services/axios2';

import {useParams} from "react-router-dom";
import {Link,hashHistory} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import NuInputEdit from "components/nubox/NuInputEdit";

import { withRouterJim } from "router/withRouterJim";
import confirmService from 'components/confirmService';
import Odontograma from 'components/Odontograma';

import TratamientoDetModal from 'views/_box/TratamientoDetModal'
import user from 'assets/img/user.jpg';
import user1 from 'assets/img2/user1.png';


import { NotificationContainer, NotificationManager } from 'react-notifications';
import {
  Table,
  Button,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  ModalFooter,
  ToastHeader,
} from "reactstrap";



const cbo=[
  {  value:'1', label: 'label1'},
  {  value:'2', label: 'Clss'},
  {  value:'3', label: 'Nod'},
  {  value:'4', label: 'Lod'},
  {  value:'5', label: 'Elggg'},
]

const form_reset={
  idd:0,
  ope:'n', 
  id_tra:0,
  nombre:'',
  mstotal:0,
  mdesc:0,
  mtotal:0,
  estado:0 	   
}


//$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
class Tratamiento extends React.Component {
  
  modalRef = React.createRef();//create ref

  state = {
    modalImagen: false,
    id_paciente: 0,
    id_tra:0,

    form: form_reset,
    data:[], /* tratamientos_det */

  };
  

  

  //------------------------------------------------------
  componentDidMount() {
    //--limpiamos el array de prueba
    this.setState({data:[]});

    let id_ = this.props.store.paciente.id_paciente;
    // Get the userId param from the URL.
    let id_tra = this.props.params.idd;
    let ope = this.props.params.ope;  
    //    console.log("id_tra:"+ id_tra);
  
    
      if(ope==='e' || ope==='x'){
        this.cargar_tratamiento(id_tra);
        //this.cargar_odontograma(id_tratamiento);
      }

      if(ope==='n'){
        this.limpiar();
      }

      this.setState({ form:{
          ...this.state.form, 
          idd: id_tra, 
          ope: ope,
        },
        id_paciente: id_,
        id_tra: id_tra,
      }); 
  

  }

  //------------ 
  limpiar(){
    this.setState({form: form_reset, data: []});
  }
  //----------------especial, actualiza valores del form -------------------------------
  handleChangeForm = (e) => {
    //requerimos la prop name="?" en cada control
    
    //--- esto lo hacemos porque en vez de utilizar valores true, false
    //--- tenemos que utilizar 1, 0
    if(e.target.type==="checkbox"){
      let res = e.target.checked? 1:0;
            
      this.setState({
        form:{
          ...this.state.form,
          [e.target.name]: res,
        }
      })
    }else{
      this.setState({
        form: {
          ...this.state.form,
          [e.target.name]: e.target.value,
        },
      });
    }
  };
  
  //---------------------------------
  handleChangeSwitch = (e, id_tradet) => {
    //requerimos la prop name="?" en cada control
    //--- esto lo hacemos porque en vez de utilizar valores true, false
    //--- tenemos que utilizar 1, 0
    let res = e.target.checked? 1:0;

    var contador = 0;
    var arreglo = this.state.data;
    //----buscamos la fila, con el id
    arreglo.map((registro) => {
      if (registro.id_tradet == id_tradet) {
        arreglo[contador].realizado_sn = res;
      }
      contador ++;
    });
    this.setState({ data: arreglo, modalActualizar: false });
    //------------------guardamos en la base de datod
    this.saveSwitch(id_tradet, res);
  };
  //---------- cuando hace enter ------------------------
  handleOKInput = (e) => {
    //---- actualizamos el campo detalle
    this.actualizarDetalle(e.target.value);
  };
  //---------------------------------------
  actualizarDetalle =(nombre) =>{
    let param =  {
      id_tra: this.state.id_tra,
      nombre: nombre
    };
    
    axios2.post("tratamiento_update_detalle", param)
    .then(res => {
    
        if(res.data.exito === 1){ 
            toast.success(res.data.mensaje);
        }else{
            toast.error(res.data.mensaje);
        }
  
    }).catch(error => {
        alert("---- Existio un error ---");
        console.log(error);
    });
    
  }
  //====================================================== 
  //----cargamos los datos desde la base de datos
  cargar_tratamiento = (id_tra) => {
    
    //context API
    console.log("---cargar tratamiento----");
    
    let param = { 
      id_tra: id_tra
    }
    
    axios2.post( "tratamiento_lista", param).then(res => {
      const response = res.data;
      console.log(res.data);

      if(response.exito==1){ 
        this.setState({
          form: response.form,
          data: response.data,
        });

      }else{
          NotificationManager.error("No existen registros");
          this.limpiar();
      }

    }).catch(error => {
        alert("---- Existio un error, ---");
        console.log(error);
        
    });
  };
  //-----------------------------------------------------------
  imprimir =()=>{
    /*let fileURL = process.env.REACT_APP_API_URL  + "certificado_reciboPDF/"+ id_certi;
    let  windowFeatures = 'width=650,height= 700, left=100,top=100 ';
    window.open(fileURL,'',windowFeatures);*/
    let printContents = document.getElementById('printablediv').innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents; 
  
  }
  //----------------
  liquidar_deuda=()=>{

  }
  
 

  //-----------------------------------------------
  aceptar_click = async () => {
    console.log("aceptar");
    
    confirmService.show({
      message: 'Esta seguro de guardar?',
      title: 'Confirmation',
      icon: 'fa fa-question-circle',
      classHeader: 'bg-default1 ',
      classButton: 'btn btn-danger',
      accept: () => {
          
          this.save();
      },
      reject: () => {
          console.log('cancel');
      },
      acceptLabel: 'Aceptar',
      rejectLabel: 'Cancelar'
    })
 
}
saveSwitch =(id_tradet, realizado_sn) =>{
  let param =  {
    id_tradet:id_tradet,
    realizado_sn:realizado_sn
  };
  
  axios2.post("tradet_update_realizado", param)
  .then(res => {
  
      if(res.data.exito === 1){ 
          toast.success(res.data.mensaje);
      }else{
          toast.error(res.data.mensaje);
      }

  }).catch(error => {
      alert("---- Existio un error ---");
      console.log(error);
  });
  
}
//----------------------- MODAL *---------------
okModal =()=>{
  console.log("okModal");
  //---------
  let id_tra = this.state.id_tra;
  this.cargar_tratamiento(id_tra);
}
closeModal =()=>{
  console.log("closeModal");
}
//---------------------
agregar_row=()=>{
  //let id_medico = this.state.form.id_medico;
  
  
  if(this.modalRef.current!=null){
    this.modalRef.current.nuevo(); 
  }
}
//----------------
editar_row=( id_tradet) =>{
  if(this.modalRef.current!=null){
    this.modalRef.current.editar(id_tradet); 
  }
}
//---------------------
eliminar_row=( id_tradet) =>{
  if(this.modalRef.current!=null){
    this.modalRef.current.eliminar(id_tradet); 
  }
}
//-------------------ODONTOGRAMA----------------
onClickPieza =(pieza)=>{
  console.log(pieza);

  if(this.modalRef.current!=null){

    
    this.modalRef.current.nuevo(); 
    this.modalRef.current.setPieza(pieza);

  }
}

  //-------------------------------------
  render() {
    //------------ context API --------------
   
    //console.log( process.env.REACT_APP_AFILES + this.state.form.foto_path1 );
    const {form} = this.state;

    return (
      <div>
        <ToastContainer />

        { 
          //JSON.stringify(this.state.id_tra) 
        }

        <div className="content1">

          {/* ---   --- */}
          {/* ---   --- */}
          {/*JSON.stringify(this.props.store.paciente)*/}


          <section className="content-header">
            
            <Link to={"/tratamientolist/"+ this.state.id_paciente } className='btn btn-success'>
              <i className='fa fa-plus' aria-hidden='true'></i> Volver
            </Link>
            
            <div className="btn-group pull-right">

              <Button onClick={ this.imprimir } className="create-modal btn btn-info ">
                <i className="fa fa-print"></i> Imprimir
              </Button>
              <Button className="create-modal btn btn-danger" 
                onClick={ this.liquidar_deuda }>
                <i className="fa fa-fw fa-close"></i> Liquidar deuda
              </Button>
            </div>
          </section>




        </div>

        <section className="content" id='printablediv'>
          <h3>
            Tratamiento
            { ` (Nro. ${this.state.id_tra})`}
          </h3>


          <div className="row">
            <div className="col-md-7">

              <div className="box box-warning direct-chat direct-chat-warning">
                <div className="box-header with-border">
                  <h3 className="box-title " >
                    <h4 style={{display:"flex"}}>
                    Nombre: &nbsp;
                    <NuInputEdit 
                            type="text"                           
                            className="form-control" 
                            name="nombre" 
                            value={this.state.form.nombre}
                            onChange={this.handleChangeForm }
                            onOkInput={this.handleOKInput }
                            valtipo='string2'
                    />
                    </h4>
                  </h3>
                  
                  <div className="box-tools pull-right">

                    <button type="button" className="btn btn-box-tool" data-widget="collapse">
                      <i className="fa fa-minus"></i>
                    </button>

                  </div>
                </div>

                <div className="box-body">

                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-lg-12 ">

                      <div id="grafic_window" className="dash-widget1 p-1 m-0">
                        {/*    */}
                        
                        <Odontograma 
                          data={this.state.data}
                          onClickPieza={ this.onClickPieza }
                        />
                        {/*    */}
                      </div>

                    </div>
                  </div>

                </div>

                <div className="box-footer">
                
                </div>

              </div>

            </div>
          
            {/*  ------------------------------------------   */}
            <div className="col-md-5">

              <div className="box box-warning direct-chat direct-chat-warning">
                <div className="box-header with-border">
                  <h3 className="box-title">Presupuesto</h3>
                  <div className="box-tools pull-right">

                    <button type="button" className="btn btn-box-tool" data-widget="collapse">
                      <i className="fa fa-minus"></i>
                    </button>
                    <button type="button" className="btn btn-warning " onClick={ this.agregar_row }>
                      <i className="fa fa-edit"></i>Nuevo
                    </button>

                  </div>
                </div>

                <div className="box-body">
                  {/*    */}
                  <div className="table-responsive">
                    <table id="tabla01" className=" etigridb2" cellSpacing="0" style={{width:"100%"}}>
                      <thead>
                        <tr>
                          <th style={{ width: "15px", padding: "6px"}}></th>

                          <th>PRESTACION</th>
                          <th>PIEZAS</th>
                          <th>CANT</th>
                          <th>PRECIO</th>
                          <th>REALIZADO</th>
                          <th>TOTAL</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                      { this.state.data.map((item) => (
                        <tr key={item.id_tradet}>
                          <td style={{ backgroundColor: item.color }}></td>
                          <td>{item.nombre_p}</td>
                          <td style={{ color:"red", textAlign: "center" }}>{item.pieza}</td>
                          <td style={{ textAlign:"right" }}>{item.cant}</td>
                          <td style={{ textAlign:"right" }}>{item.precio}</td>
                          
                          <td >
                            <div className="form-check form-switch" style={{display: "flex", justifyContent: "center"}}>
                              <input className="form-check-input" type="checkbox" role="switch" 
                                  name="realizado_sn"
                                  value = {item.realizado_sn }
                                  checked={item.realizado_sn ===1 }
                                  onChange={(e)=>this.handleChangeSwitch(e,item.id_tradet)}  
                              />
                            </div>
                          </td>
                          <td style={{ textAlign:"right" }}>{item.total}</td>
                          <td>
                            <Button className="btn-sm btn-warning" onClick={ ()=>this.editar_row(item.id_tradet) }><i className="fa fa-pencil"></i></Button>
                            <Button className="btn-sm btn-danger" onClick={ ()=>this.eliminar_row(item.id_tradet) }><i className="fa fa-trash"></i></Button>
                          </td>
                        </tr>
                      ))}
                      </tbody>

                    </table>
                  </div>
                  {/*    */}

                </div>

                <div className="box-footer" >
                 
                </div>


              </div>

              <div className="" style={{float: "right"}}>
                <table id="tabla01" className=" tabla_plomo " cellSpacing="0">
                  <thead>

                  </thead>
                  <tbody>

                    <tr>
                      <td>Total presupuesto</td>
                      <td style={{ textAlign: "right" }}><b>{this.state.form.mtotal}</b></td>
                    </tr>
                    <tr>
                      <td>Abonado</td>
                      <td style={{ textAlign: "right" }}>{this.state.form.pagado}</td>
                    </tr>
                    <tr>
                      <td>Saldo</td>
                      <td style={{ textAlign: "right", color: "red" }}>{this.state.form.saldo}</td>
                    </tr>

                  </tbody>

                </table>
              </div>

            </div>
            {/*  --------------------------------------------   */}
           
          </div>

          <div className="row">
            
          </div>

      

        </section>

        <TratamientoDetModal 
            ref={this.modalRef}
            id_tra={ this.state.id_tra }
            okModal={ this.okModal}
            closeModal={ this.closeModal}
        />
      </div>

    );
  }
}
  
//------------para que soporte la funcionalidad de parametros
export default withRouterJim( Tratamiento);