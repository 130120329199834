import React from "react";
import "./styles.css";

export default function LoadingSpinner({size, color='#ccc'}) {
  //let miBorder = `4px solid #f3f3f3`;
  //border-top: 4px solid #383636;
  let miBorderTop = `4px solid ${color}`;
  return (
    <div className="spinner-container">
      <div className="loading-spinner" style={{ width: size, height: size, borderTop: miBorderTop }}>
      </div>
    </div>
  );
}