import React, { useReducer } from "react";
import axios from 'axios';
import axios2 from 'services/axios2';

import {useParams} from "react-router-dom";
import {Link,hashHistory} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import AuthService from "services/auth.service";

import { withRouterJim } from "router/withRouterJim";
import confirmService from 'components/confirmService';
import  NuDropdown from "components/nubox/NuDropdown";

import TratamientoDetModal from 'views/_box/TratamientoDetModal'
import user from 'assets/img/user.jpg';
import user1 from 'assets/img2/user1.png';


import { NotificationContainer, NotificationManager } from 'react-notifications';
import {
  Table,
  Button,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  ModalFooter,
  ToastHeader,
} from "reactstrap";
import { RestaurantMenu } from "@material-ui/icons";


const cbo=[
  {  value:'1', label: 'label1'},
  {  value:'2', label: 'Clss'},
  {  value:'3', label: 'Nod'},
  {  value:'4', label: 'Lod'},
  {  value:'5', label: 'Elggg'},
]

const form_reset={
  idd:0,
  ope:'n', 
  id_receta:0,
  id_paciente:0,
  id_medico:0,
  fecha:'',
  paciente_nom:'',
  medico_nom:'',
}

const row={
  cant:0,
  medicamento:'', 
  dosis:1,
  unidad:'COM',
  duracion:0,
}

//$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
class Receta extends React.Component {
  
  modalRef = React.createRef();//create ref

  state = {
    modalImagen: false,
    id_paciente: 0,
    id_receta:0,
    
    form: form_reset,
    data:[], /* tratamientos_det */

    arr_unidad:[],
    arr_duracion:[
      {  value:'1', label: 'c/8 H'},
      {  value:'2', label: 'c/12 H'},
      {  value:'3', label: 'c/24 H'},
    ]
  };
  
  //------------------------------------------------------
  componentDidMount() {
    //--limpiamos el array de prueba
    this.setState({data:[]});
    this.cargar_arr_unidad();

    const user = AuthService.getCurrentUser();

    let id_paciente = this.props.store.paciente.id_paciente;
    // Get the userId param from the URL.
    let idd = this.props.params.idd;
    let ope = this.props.params.ope;  
    console.log("idd: "+ idd + " ope:" + ope);
    if(ope==='e' ){
      this.cargar_receta(idd);
      
    }

    if(ope==='n'){
      this.limpiar();
    }

    /*this.setState({ form:{
        ...this.state.form, 
      },
      id_paciente: id_paciente,
      id_receta: idd,
    }); */
    

  }
  //---------------------------------------
  cargar_arr_unidad = () => {
    let param = { 
    }
    
    axios2.post("cbo_unidad", param)
    .then(res => {
        this.setState({arr_unidad: res.data});
    }).catch(error => {
        alert("---- Existio un error ---");
        console.log(error);
    });
  };
  //---------------------------------------
  limpiar(){
    this.setState({form: form_reset, data: []});
  }
  //----------------especial, actualiza valores del form -------------------------------
  handleChangeForm = (e) => {
    //requerimos la prop name="?" en cada control
    
    //--- esto lo hacemos porque en vez de utilizar valores true, false
    //--- tenemos que utilizar 1, 0
    if(e.target.type==="checkbox"){
      let res = e.target.checked? 1:0;
            
      this.setState({
        form:{
          ...this.state.form,
          [e.target.name]: res,
        }
      })
    }else{
      this.setState({
        form: {
          ...this.state.form,
          [e.target.name]: e.target.value,
        },
      });
    }
  };
  //***********DATATABLE**** */
  handleCellChange=(index, columnId, value)=>{
    const newData = [...this.state.data];
    newData[index][columnId] = value;
    
    //console.log(newData);
    //setData({  data: newData    });
    this.setState({ data: newData });
  }
  //---------------------------------
  //====================================================== 
  //----cargamos los datos desde la base de datos
  cargar_receta = (id_receta) => {
    
    //context API
    console.log("---cargar receta----");
    
    let param = { 
      id_receta
    }
    console.log(param);
    axios2.post( "receta_buscar", param).then(res => {
      const response = res.data;
      console.log(res.data);

      if(response.exito==1){ 
        this.setState({
          form: {
            ...response.form, 
            idd: id_receta, 
            ope: 'e',
          },
          data: response.data,
        });

      }else{
          NotificationManager.error("No existen registros");
          this.limpiar();
      }

    }).catch(error => {
        alert("---- Existio un error, ---");
        console.log(error);
        
    });
  };
  //------------------------------------------------------
  imprimir =()=>{
    const id = this.state.form.id_receta;
    let fileURL = process.env.REACT_APP_API_URL  + "receta_printPDF/"+ id;
    let  windowFeatures = 'width=650,height= 700, left=100,top=100 ';
    window.open(fileURL,'',windowFeatures);
  }
  //------------------------------------------------------
  //-----------------------------------------------
  aceptar_click = async () => {
    console.log("aceptar");
    
    confirmService.show({
      message: 'Esta seguro de guardar?',
      title: 'Confirmation',
      icon: 'fa fa-question-circle',
      classHeader: 'bg-default1 ',
      classButton: 'btn btn-danger',
      accept: () => {
          
          this.save();
      },
      reject: () => {
          console.log('cancel');
      },
      acceptLabel: 'Aceptar',
      rejectLabel: 'Cancelar'
    })
 
}

//------------------------------------
addNewRow = (event) => {
  event.preventDefault();
  var valorNuevo= {...row};
  valorNuevo.id_muestra= this.state.data.length+1; //el nuevo id

  this.setState((prevState) => ({
      data: [...prevState.data, valorNuevo],
  }));
}
//---------------------
deteteRow = (index) => {
  this.setState({
      data: this.state.data.filter((s, sindex) => index !== sindex),
  });
}
//-----------------------------------
save =() =>{
  //-------verificamos que su rol sea el de medico
  

  const user = AuthService.getCurrentUser();
  const id_medico = this.props.store.user.id_medico;
  const id_paciente = this.props.store.paciente.id_paciente;
  //console.log(this.props.store);

  if(id_medico==null){
    //---- tiene que ser medico
    toast.error("No tiene rol de medico para guardar");
    return;
  }

  let param = {
                idd: this.state.form.idd,
                ope: this.state.form.ope,
                id_paciente: id_paciente,
                id_medico: id_medico,
                t_tabla: JSON.stringify( this.state.data),
              };
  console.log(param);
  
  axios2.post("receta_store", param)
  .then(res => {
      console.log(res.data);
      if(res.data.exito === 1){ 
          toast.success(res.data.mensaje);
          //----lo imprimimos
          this.imprimir();
          
      }else{
          toast.error(res.data.mensaje);
      }

  }).catch(error => {
      alert("---- Existio un error ---");
      console.log(error);
      
  });
  
}
  //-------------------------------------
  render() {
    //------------ context API --------------
   
    //console.log( process.env.REACT_APP_AFILES + this.state.form.foto_path1 );
    const {form} = this.state;
    const paciente_nom = this.props.store.paciente.nombre;
    const medico_nom = this.props.store.user.nomape;

    return (
      <div>
        <ToastContainer />

        { 
          //JSON.stringify(this.state.form) 
          //JSON.stringify(this.state.data) 
        }

        <div className="content1">

          {/* ---   --- */}
          {/* ---   --- */}
          {/*JSON.stringify(this.props.store.paciente)*/}


          <section className="content-header">
            <h3>
              Receta
              { ` (Nro. ${this.state.form.id_receta})`}
            </h3>

            <Link to={"/recetalist" } className='btn btn-success'>
              <i className='fa fa-arrow-left' aria-hidden='true'></i> Volver
            </Link>
            
            <div className="btn-group pull-right">
              <Button onClick={ this.aceptar_click } className="btn btn-primary">
                        <i className="fa fa-floppy-o"></i>&nbsp;
                        Guardar
              </Button> 
              <Button onClick={ this.imprimir } className="create-modal btn btn-info ">
                <i className="fa fa-print"></i> Imprimir
              </Button>
            </div>
          </section>




        </div>

        <section className="content">


          <div className="row">
            <div className="col-md-12">

              <div className="box box-info ">
                <div className="box-header with-border">
                  <h3 className="box-title " >
                    Datos
                  </h3>
                  
                  <div className="box-tools pull-right">

                    <button type="button" className="btn btn-box-tool" data-widget="collapse">
                      <i className="fa fa-minus"></i>
                    </button>

                  </div>
                </div>

                <div className="box-body">

                  <div className="row">
                    <div className="form-group col-md-6">
                      <label >Paciente:</label>&nbsp;
                      <label >{ paciente_nom }</label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-6">
                      <label >Medico:</label>&nbsp;
                      <label >{ medico_nom }</label>
                    </div>

                  </div>

               
                  <div style={{  padding:"10px 0px 10px 0px"}} >
                    <button type="button" className="btn btn-warning pull-right " 
                      onClick={(e) => { this.addNewRow(e) }}>
                      <i className="fa fa-plus"></i> Agregar fila
                    </button>
                    <h4></h4>
                  </div>

                  <div className="clearfix"></div>

                  {/*    */}
                  <div className="table-responsive">
                    <table id="tabla01" className=" tabla_plomo" cellSpacing="0" style={{width:"100%"}}>
                      <thead>
                        <tr>
                          <th>Cant</th>
                          <th>Medicamento</th>
                          <th>Dosis</th>
                          <th>Presenciacion</th>
                          <th>Duración</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                      { this.state.data.map((item, index) => (
                        <tr key={index}>
                          <td style={{width:"120px"}}>
                              <input  type="number" 
                                      className='form-control' 
                                      value={item.cant} 
                                      onChange={(e) => this.handleCellChange(index,"cant", e.target.value)} 
                              />
                          </td>
                          <td >
                            <input  type="text" 
                                    style={{ textTransform: "uppercase" }}
                                    className='form-control' 
                                    value={item.medicamento} 
                                    onChange={(e) => this.handleCellChange(index,"medicamento", e.target.value)} 
                              />
                          </td>
                          <td style={{width:"120px"}}>
                            <input  type="number" 
                                      className='form-control' 
                                      value={item.dosis} 
                                      onChange={(e) => this.handleCellChange(index,"dosis", e.target.value)} 
                              />
                          </td>
                          <td style={{width:"150px"}}>
                            <NuDropdown
                              className="form-control1"
                              options={this.state.arr_unidad}
                              name="unidad"
                              value={item.unidad}
                              onChange={(e) => this.handleCellChange(index,"unidad", e.target.value)} 
                            />
                          </td>
                          <td style={{width:"150px"}}>
                            <NuDropdown
                              className="form-control1"
                              options={this.state.arr_duracion}
                              name="duracion"
                              value={item.duracion}
                              onChange={(e) => this.handleCellChange(index,"duracion", e.target.value)} 
                            />
                          </td>
                          
                          <td>
                            <Button className="btn btn-danger" onClick={ ()=>this.deteteRow(index) }><i className="fa fa-trash"></i></Button>
                          </td>
                        </tr>
                      ))}
                      </tbody>

                    </table>
                  </div>
                  {/*    */}

                </div>

                <div className="box-footer" >
                  <div className="text-center">
                     
                  </div>
                </div>


              </div>

              

            </div>
            {/*  --------------------------------------------   */}
           
          </div>

        

      

        </section>

        
      </div>

    );
  }
}
  
//------------para que soporte la funcionalidad de parametros
export default withRouterJim( Receta );