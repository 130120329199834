import React from 'react';



import { Link } from "react-router-dom";
//import '../App.css';
import logo from '../assets/logo.png';

import m_pacientes from '../assets/img2/m_pacientes.png';
import m_citas from '../assets/img2/m_citas.png';
import m_doctores from '../assets/img2/m_doctores.png';
import m_usuario from '../assets/img2/m_usuario.png';
import m_globalx from '../assets/img2/m_globalx.png';
import m_changep from '../assets/img2/m_changep.png';
import m_producto from '../assets/img2/m_producto.png';
import m_horario from '../assets/img2/m_horario.png';

const Panel = () => {
    return (
        <div >
            <div className='container py-5'>
                <div className="row">
                    <div className="col-md-3 col-sm-3 col-lg-3 col-xl-3  pb-3">
                        <div className='button-panel bg-white rounded shadow-sm'> 
                            <Link to="/pacientes"   className="" tabIndex="0">
                                <span className="">
                                    <img src={m_pacientes} alt="Listado pacientes" style={{ width: "64px" }} />
                                </span>
                                <h3 className="mb-0 titulo_panel">Pacientes</h3>
                                <span matripple="" className="mat-ripple mat-button-ripple"></span>
                                <span className="mat-button-focus-overlay"></span>
                            </Link>
                        </div>
                    </div>

                    <div className="col-md-3 col-sm-3 col-lg-3 col-xl-3  pb-3">
                        <div className='button-panel bg-white rounded shadow-sm'> 
                            <Link to="/citas"   className="" tabIndex="0">
                                <span className="">
                                    <img src={m_citas} alt="Listado pacientes" style={{ width: "64px" }} />
                                </span>
                                <h3 className="mb-0 titulo_panel">Citas</h3>
                                    <span matripple="" className="mat-ripple mat-button-ripple"></span>
                                    <span className="mat-button-focus-overlay"></span>
                            </Link>
                        </div>
                    </div>

                    <div className="col-md-3 col-sm-3 col-lg-3 col-xl-3  pb-3">
                        <div className='button-panel bg-white rounded shadow-sm'> 
                            <Link to="/medicos"   className="" tabIndex="0">
                                <span className="">
                                    <img src={m_doctores} alt="Listado pacientes" style={{ width: "64px" }} />
                                </span>
                                <h3 className="mb-0 titulo_panel">Profesionales</h3>
                                <span matripple="" className="mat-ripple mat-button-ripple"></span>
                                <span className="mat-button-focus-overlay"></span>
                            </Link>
                        </div>
                    </div>

                    <div className="col-md-3 col-sm-3 col-lg-3 col-xl-3 pb-3">
                        <div className='button-panel bg-white rounded shadow-sm'> 
                            <Link to="/usuarios"   className="" tabIndex="0">
                                <span className="">
                                    <img src={m_usuario} alt="Listado pacientes" style={{ width: "64px" }} />
                                </span>
                                <h3 className="mb-0 titulo_panel">Usuarios</h3>
                                    <span matripple="" className="mat-ripple mat-button-ripple"></span>
                                    <span className="mat-button-focus-overlay"></span>
                            </Link>
                        </div>
                    </div>

                    <div className="col-md-3 col-sm-3 col-lg-3 col-xl-3  pb-3">
                        <div className='button-panel bg-white rounded shadow-sm'> 
                            <Link to="/globalx"   className="" tabIndex="0">
                                <span className="">
                                    <img src={m_globalx} alt="Configuración" style={{ width: "64px" }} />
                                </span>
                                <h3 className="mb-0 titulo_panel ">Config</h3>
                                <span matripple="" className="mat-ripple mat-button-ripple"></span>
                                <span className="mat-button-focus-overlay"></span>
                            </Link>
                        </div>
                    </div>

                    <div className="col-md-3 col-sm-3 col-lg-3 col-xl-3  pb-3">
                        <div className='button-panel bg-white rounded shadow-sm'> 
                            <Link to="/cambiarclaveA"   className="" tabIndex="0">
                                <span className="">
                                    <img src={m_changep} alt="Cambiar clave" style={{ width: "64px" }} />
                                </span>
                                <h3 className="mb-0 titulo_panel ">Cambiar clave</h3>
                                <span matripple="" className="mat-ripple mat-button-ripple"></span>
                                <span className="mat-button-focus-overlay"></span>
                            </Link>
                        </div>
                    </div>

                    <div className="col-md-3 col-sm-3 col-lg-3 col-xl-3  pb-3">
                        <div className='button-panel bg-white rounded shadow-sm'> 
                            <Link to="/productolist"   className="" tabIndex="0">
                                <span className="">
                                    <img src={m_producto} alt="Producto" style={{ width: "64px" }} />
                                </span>
                                <h3 className="mb-0 titulo_panel ">Servicios</h3>
                                <span matripple="" className="mat-ripple mat-button-ripple"></span>
                                <span className="mat-button-focus-overlay"></span>
                            </Link>
                        </div>
                    </div>

                    <div className="col-md-3 col-sm-3 col-lg-3 col-xl-3  pb-3">
                        <div className='button-panel bg-white rounded shadow-sm'> 
                            <Link to="/cita_horalist"   className="" tabIndex="0">
                                <span className="">
                                    <img src={m_horario} alt="Horario" style={{ width: "64px" }} />
                                </span>
                                <h3 className="mb-0 titulo_panel ">Horario</h3>
                                <span matripple="" className="mat-ripple mat-button-ripple"></span>
                                <span className="mat-button-focus-overlay"></span>
                            </Link>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    );
}

export default Panel;